import { CustomSelectOption } from '../Form/CustomSelect';

interface FilterRecordProps {
  type: 'text' | 'select' | 'date';
  label: string;
  value: string;
  selection?: CustomSelectOption[];
  onChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
}

interface Props {
  filters: FilterRecordProps[];
  onFilter?: () => void;
  onClear?: () => void;
  label?: string;
}

export const DynamicRecordFilter: React.FC<Props> = ({
  filters,
  onClear,
  onFilter,
  label,
}) => {
  return (
    <>
      <div className="w-full relative">
        <div className="flex flex-col md:flex-row md:flex-wrap gap-4">
          <label className="block lg:text-sm md:text-sm text-xs font-medium text-green-700 w-full md:w-auto md:col-span-3">
            {label}
          </label>
          <div className="flex relative flex-col space-y-4 md:space-y-0 sm:flex-row md:flex-row md:flex-wrap lg:space-y-0 lg:space-x-4 md:space-x-2 space-x-0 w-full">
            {filters.map((filter) => {
              if (filter.type === 'text') {
                return (
                  <input
                    key={filter.label}
                    type="text"
                    value={filter.value}
                    onChange={filter.onChange}
                    className=" relative block w-full md:flex-1 rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 md:text-xs lg:text-sm text-xs"
                    placeholder={filter.label}
                  />
                );
              }
              if (filter.type === 'select') {
                return (
                  <select
                    key={filter.label}
                    value={filter.value}
                    onChange={filter.onChange}
                    className="relative block w-full md:flex-1 rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 md:text-xs lg:text-sm text-xs"
                  >
                    <option value="" className="text-red-300">
                      {filter.label}
                    </option>
                    {filter.selection?.map((option) => (
                      <option
                        className="font-sans"
                        key={option.value}
                        value={option.value}
                        selected={option.selected}
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                );
              }
              if (filter.type === 'date') {
                return (
                  <input
                    key={filter.label}
                    type="date"
                    value={filter.value}
                    onChange={filter.onChange}
                    className="relative block w-full md:flex-1 rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 md:text-xs lg:text-sm text-xs"
                  />
                );
              }
            })}
            <button
              onClick={onFilter}
              className="bg-primary-500 rounded-md border border-gray-300 py-2 px-4 md:text-xs lg:text-sm text-xs font-medium text-white shadow-sm hover:bg-primary-700 flex-shrink-0"
            >
              Filter
            </button>
            <button
              onClick={onClear}
              className="rounded-md border border-gray-300 bg-white py-2 px-4 md:text-xs lg:text-sm text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 flex-shrink-0"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
