import {
  ArrowLeftOnRectangleIcon,
  UserCircleIcon,
} from '@heroicons/react/20/solid';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import 'react-toastify/dist/ReactToastify.css';

import { Header } from './Header/Header';
import { SideBar } from './SideBar/SideBar';
import { SideBarResponsive } from './SideBar/SideBarResponsive';
import { MyProfileDrawer } from './User/MyProfileDrawer';

import {
  BellIcon,
  EmailIcon,
  SidebarHamburgerIcon,
  TuosLogoFull,
} from '@components/Icons';
import { UserMenus } from '@components/UserMenus';

interface IProps {
  children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const [showSideBarResponsive, setShowSideBarResponsive] = useState(false);
  const [sideBarBot, setSideBar] = useState(false);
  const auth = useAuth();
  const [showUserProfile, setShowUserProfile] = useState(false);

  const handleSideBarButton = () => {
    setShowSideBar(!showSideBar);
    setSideBar(true);
  };

  const handleUserAvatarClick = () => {
    setShowUserProfile(true);
  };

  const setSideBarClass = sideBarBot ? '' : 'rotate-0';

  return (
    <>
      {/* Desktop */}
      <div className="lg:block md:hidden hidden lg:h-full lg:w-full">
        <div className="grid h-full gap-0 grid-cols-6 ">
          {showSideBar && <SideBar />}

          <div
            className={`h-full ${showSideBar ? 'col-span-5' : 'col-span-6'}`}
          >
            <div>
              <Header>
                <div className="w-full flex h-full">
                  <div className="flex bg-[#53CC96] w-8 pl-6">
                    <button
                      className={`${setSideBarClass}`}
                      onClick={handleSideBarButton}
                    >
                      <SidebarHamburgerIcon height="24" width="24" />
                    </button>
                  </div>

                  <div className="flex items-center justify-end h-full pr-[2.5rem] w-full">
                    <div className=" flex text-primary-700">
                      <button className="text-md items-center rounded-full  mr-4 ">
                        <BellIcon height="20" width="20" />
                      </button>
                      <button className="text-md items-center rounded-full  mr-4 ">
                        <EmailIcon height="20" width="20" />
                      </button>
                    </div>
                    {auth.isAuthenticated && (
                      <>
                        <button
                          onClick={() => {
                            auth.signoutRedirect();
                          }}
                        >
                          <ArrowLeftOnRectangleIcon className="h-5 w-5 text-primary-700" />
                        </button>

                        <button
                          onClick={handleUserAvatarClick}
                          className="pl-4"
                        >
                          <UserCircleIcon className="h-8 w-8 text-primary-700" />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </Header>
            </div>
            <div className="mx-4">{children}</div>
          </div>
        </div>
      </div>

      {/* Mobile & Desktop */}
      <div className="lg:hidden md:block block">
        {/* Header */}
        <div className="flex items-center flex-row justify-between bg-[#53CC96] py-5 px-5">
          <button
            onClick={() => setShowSideBarResponsive(!showSideBarResponsive)}
          >
            <SidebarHamburgerIcon height="24" width="24" />
          </button>

          <TuosLogoFull width="104" height="30" />

          <UserMenus />
        </div>
        <div className="flex relative">
          <div className="absolute h-screen bg-[#E7EEEB] z-10">
            {showSideBarResponsive && (
              <>
                <SideBarResponsive
                  onClose={() => setShowSideBarResponsive(false)}
                />
              </>
            )}
          </div>
          <div className="w-full">{children}</div>
        </div>
      </div>
      {showUserProfile && (
        <MyProfileDrawer setShowUserProfile={setShowUserProfile} />
      )}
    </>
  );
};
