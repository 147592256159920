import { Outlet } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { Box } from '@components/Box';
import { BoxBodyContainer } from '@components/Box/BoxBodyContainer';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { routes } from '@config/routes';

export const Contact: React.FC = () => {
  return (
    <>
      <Box>
        <BoxBodyContainer>
          <GridHeaderMenuTitle>
            <div className="lg:text-2xl md:text-xl text-md lg:font-black md:font-black font-bold">
              {' '}
              Contacts
            </div>
          </GridHeaderMenuTitle>

          <BreadCrumbs
            links={[
              { link: 'Revenue and Collections', to: `` },
              { link: 'Contact', to: `${routes.CONTACTS}` },
            ]}
          />
        </BoxBodyContainer>
        <BoxBodyContainer>
          <Outlet />
        </BoxBodyContainer>
      </Box>
    </>
  );
};
