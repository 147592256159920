import { Link } from 'react-router-dom';

import { HomeIcon } from '@components/Icons';

interface BreadCrumbLink {
  link: string;
  to: string;
}

interface Props {
  links: BreadCrumbLink[];
}

export const BreadCrumbs: React.FC<Props> = ({ links }) => {
  return (
    <nav
      className="flex justify-end pt-[8px] lg:pb-0 md:pb-0 pb-1"
      aria-label="Breadcrumb"
    >
      <ol role="list" className="px-4 flex space-x-2">
        <li key={`homelink`} className="flex">
          <div className="flex items-center">
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <HomeIcon height="4" width="4" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>

        {links.map((link, index) => {
          return (
            <>
              <li key={`${index}arrow`} className="flex ">
                <div className="flex items-center">{' > '}</div>
              </li>

              <li key={`${index}link`} className="flex">
                <div className="flex items-center">
                  <Link
                    className="w-full text-[#000000]  block transition hover:text-green-600 lg:text-sm md:text-sm text-xs"
                    to={link.to}
                  >
                    {link.link}
                  </Link>
                </div>
              </li>
            </>
          );
        })}
      </ol>
    </nav>
  );
};
