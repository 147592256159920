import { useEffect, useState } from 'react';

import {
  DetailTableHeaderLeft,
  DetailTableHeaderCenter,
  DetailTableHeaderRight,
  DetailTableDataLeft,
  DetailTableDataCenter,
  DetailTableDataRight,
} from '@components/Details';
import { TuosLogo } from '@components/Icons';
import { Space } from '@components/Space';
import { Table, TableContainer } from '@components/TableContainers';
import TextWrapper from '@components/TextWrapper';
import {
  billingAddressDefaultValue,
  BillingAddressResultViewModel,
} from '@models/BillingAddress';
import {
  customerDefaultValue,
  CustomerResultViewModel,
} from '@models/Customer';
import { invoiceDefaultValue, InvoiceResultViewModel } from '@models/Invoice';
import { InvoiceDetailViewModel } from '@models/InvoiceDetail';
import { InvoiceDetailSalesTaxViewModel } from '@models/InvoiceDetailSalesTax';
import { BillingAddressService } from '@services/BillingAddress';
import { CustomerService } from '@services/Customer';
import { InvoiceService } from '@services/Invoice';
import { formatAsCurrency } from '@utils/numberFormat';
import { toTitleCase } from '@utils/titleCase';

const formatDate = (dateSting: string): string => {
  const date = new Date(dateSting);
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

interface Props {
  invoicesId?: number;
}

export const InvoicePDFExport: React.FC<Props> = ({ invoicesId }) => {
  const [data, setData] = useState(invoiceDefaultValue);
  const [customer, setCustomer] = useState(customerDefaultValue);
  const [customerAddress, setCustomerAddress] = useState(
    billingAddressDefaultValue
  );

  const [invoiceDetails, setInvoiceDetails] = useState<
    InvoiceDetailViewModel[]
  >([]);

  const loadInvoiceDetails = () => {
    InvoiceService.getInvoiceDetails(Number(invoicesId)).then((result) => {
      setInvoiceDetails(result.data);
    });
  };

  const loadData = () => {
    InvoiceService.getById(Number(invoicesId))
      .then((response) => response.data as Promise<InvoiceResultViewModel>)
      .then((result) => {
        if (result.isSuccess) {
          setData(result.data);

          if (result.data.billingAddressID) {
            BillingAddressService.getById(result.data.billingAddressID)
              .then(
                (addressResponse) =>
                  addressResponse.data as Promise<BillingAddressResultViewModel>
              )
              .then((addressResult) => setCustomerAddress(addressResult.data));
          }

          if (result.data.customerID) {
            CustomerService.getById(result.data.customerID)
              .then(
                (customerResponse) =>
                  customerResponse.data as Promise<CustomerResultViewModel>
              )
              .then((customerResult) => {
                setCustomer(customerResult.data);
              });
          }

          console.log('Success: Fetched the record');
        } else {
          console.log(
            `Error: Failed to get the record. ${result.errorMessage}`
          );
        }
      });
  };

  useEffect(() => {
    if (invoicesId) {
      loadData();
      loadInvoiceDetails();
    }
  }, [invoicesId]);

  const getSalesTaxesBySalesTaxID = () => {
    const salesTaxes = invoiceDetails.map((o) =>
      o.invoiceDetailSalesTaxes
        .filter((o) => o.salesTaxID != 0)
        .map((salesTaxes) => ({
          ...salesTaxes,
          salesTaxKey: `${salesTaxes.salesTaxAbbreviation} (${salesTaxes.rate}%)`,
        }))
    );

    const groupedSalesTaxes = groupBy(
      salesTaxes.flat(),
      'salesTaxKey'
    ) as InvoiceDetailSalesTaxViewModel[][];

    return groupedSalesTaxes;
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  return (
    <>
      <div className="hidden">
        <div id="pdf-content" className="bg-white shadow-md border-2">
          <div className="p-4 flex justify-between ">
            <div className="flex items-center">
              <TuosLogo height="75" width="50" />
            </div>
            <div className="text-md font-semibold text-end text-gray-500">
              INVOICE
              <div className="text-black text-base">
                Tuos Development <br />
                <span className="text-xs">Canada</span>
              </div>
            </div>
          </div>

          <div className="border-t-2 border-gray-700  py-2 px-4">
            <div className="flex grid-cols-2 justify-between">
              <div className="col-span-1">
                <div className="text-sm text-gray-500 font-medium">Bill to</div>
                <div className="text-base font-medium uppercase">
                  {customer.companyName}
                </div>
                <div className="text-sm pb-2">
                  <span className="uppercase">
                    {customer.primaryContact?.firstname}{' '}
                    {customer.primaryContact?.lastname}
                  </span>
                  <br />
                  <span className="text-sm">
                    {toTitleCase(`${customerAddress.address1}`)}
                    <br />
                    {toTitleCase(
                      `${customerAddress.address2}${','} ${
                        customerAddress.city
                      }`
                    )}
                    <br />
                    {toTitleCase(
                      `${customerAddress.state}${','} ${
                        customerAddress.country
                      }${','} ${customerAddress.postalCode}`
                    )}
                    <br />
                  </span>
                  <br />
                  {customer.primaryContact?.phone}
                  <br />
                  {customer.primaryContact?.email}
                </div>
              </div>
              <div className="col-span-1 space-y-0.5">
                <div className="font-medium text-sm w-full flex items-center justify-between space-x-4 pl-2 pr-1 mr-1">
                  <div>Invoice Number:</div>
                  <div className="font-normal text-sm text-end">
                    {data.invoiceNumber}
                  </div>
                </div>

                <div className="mr-1 font-semibold text-sm w-full flex items-center justify-between space-x-4 pl-2 pr-1">
                  <div>Invoice Date:</div>
                  <div className="font-normal text-sm text-end">
                    {formatDate(data.date)}
                  </div>
                </div>

                <div className="mr-1 font-semibold text-sm w-full flex items-center justify-between space-x-4 pl-2 pr-1">
                  <div>Valid until:</div>
                  <div className="font-normal text-sm text-end">
                    {formatDate(data.dueDate)}
                  </div>
                </div>

                <div className="mr-1 font-semibold text-base w-full flex items-center justify-between space-x-4 pl-2 pr-1 border-gray-600 pt-1 pb-2 border-b-4">
                  <div>Grand Total(CAD):</div>
                  <div className="font-normal text-sm text-end">
                    {formatAsCurrency(
                      invoiceDetails.reduce(
                        (acc, cur) => acc + cur.subTotal,
                        0
                      ) +
                        invoiceDetails
                          .map((o) =>
                            o.invoiceDetailSalesTaxes.reduce(
                              (acc, cur) => acc + cur.salesTaxAmount,
                              0
                            )
                          )
                          .reduce((acc, cur) => acc + cur, 0)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="max-w-5x1 mx-auto">
              <TableContainer>
                <Table>
                  <thead className="bg-primary-100">
                    <tr>
                      <DetailTableHeaderLeft>Item</DetailTableHeaderLeft>
                      <DetailTableHeaderCenter>
                        Quantity
                      </DetailTableHeaderCenter>
                      <DetailTableHeaderRight>Price</DetailTableHeaderRight>
                      <DetailTableHeaderRight>Amount</DetailTableHeaderRight>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceDetails.map((row, index) => (
                      <tr
                        key={index}
                        className={`${
                          index % 2 === 0 ? ' bg-white' : 'bg-green-50'
                        }`}
                      >
                        <DetailTableDataLeft>
                          <div className="font-medium text-xs uppercase">
                            <TextWrapper text={row.product?.name || ''} />
                          </div>
                        </DetailTableDataLeft>
                        <DetailTableDataCenter>
                          <span className="font-medium text-xs">
                            {row.quantity}
                          </span>
                        </DetailTableDataCenter>
                        <DetailTableDataRight>
                          <span className="font-medium text-xs">
                            {formatAsCurrency(row.price)}
                          </span>
                        </DetailTableDataRight>
                        <DetailTableDataRight>
                          <span className="font-medium text-xs">
                            {formatAsCurrency(row.subTotal)}
                          </span>
                        </DetailTableDataRight>
                      </tr>
                    ))}

                    <Space />

                    <tr>
                      <td colSpan={6}>
                        <div className="space-y-1">
                          <div className="flex justify-end mr-6 text-xs">
                            <div className="font-bold mr-4">SubTotal:</div>

                            <div className="w-24 text-right">
                              {formatAsCurrency(
                                invoiceDetails.reduce(
                                  (acc, cur) => acc + cur.subTotal,
                                  0
                                )
                              )}
                            </div>
                          </div>

                          {Object.entries(getSalesTaxesBySalesTaxID()).map(
                            ([taxTitle, details]) => (
                              <div
                                className="flex justify-end mr-6 text-xs"
                                key={taxTitle}
                              >
                                <div className="font-medium mr-4">
                                  {taxTitle}
                                </div>

                                <div className="w-24 text-right">
                                  {formatAsCurrency(
                                    details.reduce(
                                      (acc, cur) => acc + cur.salesTaxAmount,
                                      0
                                    )
                                  )}
                                </div>
                              </div>
                            )
                          )}

                          <div className="flex justify-end mr-6 pt-2 pl-96 text-xs">
                            <div className="w-full flex justify-end border-t border-gray-600 py-2 border-b-4">
                              <div className="font-bold mr-4">Total Tax:</div>
                              <div className="w-24 text-right">
                                {formatAsCurrency(
                                  invoiceDetails
                                    .map((o) =>
                                      o.invoiceDetailSalesTaxes.reduce(
                                        (acc, cur) => acc + cur.salesTaxAmount,
                                        0
                                      )
                                    )
                                    .reduce((acc, cur) => acc + cur, 0)
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end mr-6 text-sm py-4">
                            <div className="font-bold mr-4">Grand Total:</div>
                            <div className="w-24 text-right">
                              {formatAsCurrency(
                                invoiceDetails.reduce(
                                  (acc, cur) => acc + cur.subTotal,
                                  0
                                ) +
                                  invoiceDetails
                                    .map((o) =>
                                      o.invoiceDetailSalesTaxes.reduce(
                                        (acc, cur) => acc + cur.salesTaxAmount,
                                        0
                                      )
                                    )
                                    .reduce((acc, cur) => acc + cur, 0)
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
