import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { DropDownContainer } from './DropDownContainer';
import { DropDownItems } from './DropDownItems';
import { DropDownMenuContainer } from './DropDownMenuContainer';
import { SideBarContainer } from './SideBarContainer';
import { SideBarMenuContainer } from './SideBarMenuContainer';

import {
  AccountingIcon,
  DashboardIcon,
  DropDownIcon,
  InventoryIcon,
  PurchaseIcon,
  ReportsIcon,
  RevenueIcon,
  SettingsIcon,
  TuosLogoFull,
} from '@components/Icons';
import { routes } from '@config/routes';

export const SideBar = () => {
  const [activeMenu, setActiveMenu] = useState(
    localStorage.getItem('activeMenu') || null
  );
  const [activeSubMenu, setActiveSubMenu] = useState<string | boolean>(false);
  const [showInventory, setShowInventory] = useState(false);
  const [showRevenue, setRevenue] = useState(false);
  const [showPurchase, setPurchase] = useState(false);
  const [showAccounting, setAccounting] = useState(false);
  const [showReport, setReport] = useState(false);
  const [showSettings, setSettings] = useState(false);
  const [showProfit, setProfit] = useState(false);

  const location = useLocation();

  const { id } = useParams();

  const setActiveSubMenuByRoute = (route: any) => {
    if (route === routes.HOME) {
      setActiveMenu('dashboard');
      setActiveSubMenu(false);
    } else if (
      route === routes.RNC_ESTIMATES ||
      route === `${routes.RNC_ESTIMATES}/create` ||
      route === `${routes.RNC_ESTIMATES}/${id}` ||
      route === `${routes.RNC_ESTIMATES}/${id}/edit` ||
      route === `${routes.RNC_ESTIMATES}/${id}/pdf`
    ) {
      setActiveMenu('revenue');
      setActiveSubMenu('estimate');
    } else if (route === routes.RNC_RECURRING_INVOICES) {
      setActiveMenu('revenue');
      setActiveSubMenu('recurring');
    } else if (
      route === routes.RNC_INVOICES ||
      route === `${routes.RNC_INVOICES}/create` ||
      route === `${routes.RNC_INVOICES}/${id}` ||
      route === `${routes.RNC_INVOICES}/${id}/edit` ||
      route === `${routes.RNC_INVOICES}/${id}/pdf`
    ) {
      setActiveMenu('revenue');
      setActiveSubMenu('invoice');
    } else if (route === routes.COLLECTION_SETUP) {
      setActiveMenu('revenue');
      setActiveSubMenu('collectionSetup');
    } else if (route === routes.CUSTOMER_STATEMENT) {
      setActiveMenu('revenue');
      setActiveSubMenu('customerStatements');
    } else if (
      route === routes.RNC_CUSTOMERS ||
      route === `${routes.RNC_CUSTOMERS}/create` ||
      route === `${routes.RNC_CUSTOMERS}/${id}` ||
      route === `${routes.RNC_CUSTOMERS}/${id}/edit`
    ) {
      setActiveMenu('revenue');
      setActiveSubMenu('customer');
    } else if (route === routes.CONTACTS) {
      setActiveMenu('revenue');
      setActiveSubMenu('contacts');
    } else if (route === routes.CURRENCY) {
      setActiveMenu('revenue');
      setActiveSubMenu('currency');
    } else if (route === routes.CATEGORIES) {
      setActiveMenu('inventories');
      setActiveSubMenu('categories');
    } else if (route === routes.INVENTORY_PRODUCTS) {
      setActiveMenu('inventories');
      setActiveSubMenu('products');
    } else if (route === routes.BILLS) {
      setActiveMenu('purchase and payments');
      setActiveSubMenu('bills');
    } else if (route === routes.PAYMENTS) {
      setActiveMenu('purchase and payments');
      setActiveSubMenu('paymentSetup');
    } else if (route === routes.VENODRS) {
      setActiveMenu('purchase and payments');
      setActiveSubMenu('vendors');
    } else if (route === routes.PURCHASE_PRODUCTS) {
      setActiveMenu('purchase and payments');
      setActiveSubMenu('productandServices');
    } else if (route === routes.TRANSACTIONS) {
      setActiveMenu('accounting');
      setActiveSubMenu('transaction');
    } else if (route === routes.RECON) {
      setActiveMenu('accounting');
      setActiveSubMenu('recon');
    } else if (route === routes.CHARTOFACCOUNTS) {
      setActiveMenu('accounting');
      setActiveSubMenu('charts');
    } else if (route === routes.BANK_CONNECTIONS) {
      setActiveMenu('accounting');
      setActiveSubMenu('bankConnection');
    } else if (route === routes.COMPARATIVE) {
      setActiveMenu('reports');
      // setActiveSubMenuMenu('comparative');
      setActiveSubMenu(false);
    } else if (route === routes.QUARTER) {
      setActiveMenu('reports');
      // setActiveSubMenuMenu('quarter');
    } else if (route === routes.YEAR_TO_DATE) {
      setActiveMenu('reports');
      //setActiveSubMenuMenu('yearToyear');
    } else if (route === routes.MONTHLY) {
      setActiveMenu('reports');
      //setActiveSubMenuMenu('monthly');
    } else if (route === routes.SALES_TAX) {
      setActiveMenu('settings');
      setActiveSubMenu('sales tax');
    } else {
    }
  };

  useEffect(() => {
    setActiveMenu(localStorage.getItem('activeMenu') || null);

    if (activeMenu !== localStorage.getItem('activeMenu')) {
      setActiveSubMenu(localStorage.getItem('activeSubMenu') || false);
    }

    setActiveSubMenuByRoute(location.pathname);
  }, [location.pathname]);

  const handleDropDownClick = (menu: any) => {
    if (menu === 'revenue') {
      setRevenue((prev) => {
        const newValue = !prev;
        localStorage.setItem('showRevenue', String(newValue));
        return newValue;
      });
    } else if (menu === 'inventories') {
      setShowInventory((prev) => {
        const newValue = !prev;
        localStorage.setItem('showInventory', String(newValue));
        return newValue;
      });
    } else if (menu === 'purchase') {
      setPurchase((prev) => {
        const newValue = !prev;
        localStorage.setItem('showPurchase', String(newValue));
        return newValue;
      });
    } else if (menu === 'accounting') {
      setAccounting((prev) => {
        const newValue = !prev;
        localStorage.setItem('showAccounting', String(newValue));
        return newValue;
      });
    } else if (menu === 'reports') {
      setReport((prev) => {
        const newValue = !prev;
        localStorage.setItem('showReport', String(newValue));

        setProfit((prevProfit) => {
          const newProfitValue = !prevProfit;
          localStorage.setItem('showProfit', String(newProfitValue));
          return newProfitValue;
        });

        return newValue;
      });
    } else if (menu === 'settings') {
      setSettings((prev) => {
        const newValue = !prev;
        localStorage.setItem('showSettings', String(newValue));
        return newValue;
      });
    }
  };

  const handleMenuClick = (menu: any) => {
    setActiveMenu(menu);
    localStorage.setItem('activeMenu', menu);
  };

  const handleReportMenu = (menu: any) => {
    setActiveMenu(menu);
    setActiveSubMenu(false);
    setProfit(true);
    localStorage.setItem('activeMenu', menu);
  };

  const handleSubMenuClick = (menu: any, mainMenu: any) => {
    setActiveSubMenu(menu);
    setActiveMenu(mainMenu);
  };

  const activeSubMenuClass = (menu: any) =>
    activeSubMenu === menu
      ? 'flex py-2 text-xs text-white  pr-4 pl-8 w-full space-x-2 rounded-lg bg-[#56C392]'
      : 'flex py-2 w-full text-xs pr-4 pl-8 text-primary-500 hover:text-white space-x-2 rounded-lg hover:bg-[#56C392]';

  const activeMenuClass = (menu: any) =>
    activeMenu === menu
      ? 'shadow-md text-black  fill-[#F9FEF3] py-0.5 font-bold text-[14px] pl-8 pr-4 w-full space-y-3 rounded-xl '
      : 'hover:shadow-md text-[#2B461B] fill-[#27401A] pr-4 pl-8 py-0.5 hover:bg-green-50 w-full font-md text-[14px] space-y-3 rounded-xl';

  return (
    <>
      <SideBarContainer>
        <div className="flex justify-center items-center gap-x-2 bg-[#61DBA4] h-[69px] mb-[64px]">
          <TuosLogoFull width="104" height="30" />
        </div>

        <SideBarMenuContainer>
          <Link
            onClick={() => handleMenuClick('dashboard')}
            to={`${routes.HOME}`}
          >
            <div className={`${activeMenuClass('dashboard')}`}>
              <div className="flex items-center py-3 space-x-4">
                <DashboardIcon height="24" width="24" />
                <h1 className="flex items-center">Dashboard</h1>
              </div>
            </div>
          </Link>
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div className="w-full" onClick={() => handleMenuClick('revenue')}>
            <div className={`${activeMenuClass('revenue')}`}>
              <div
                className="flex justify-between items-center space-x-4"
                onClick={() => handleDropDownClick('revenue')}
              >
                <div className="flex space-x-4 items-center h-[48px]">
                  <RevenueIcon height="24" width="24" />
                  <h1 className="flex items-center">Revenue and Collections</h1>
                </div>
                <div className="">
                  <DropDownIcon height="12" width="8" />
                </div>
              </div>
            </div>
          </div>
          {showRevenue && (
            <DropDownContainer>
              <DropDownItems
                to={`${routes.RNC_ESTIMATES}`}
                onClick={() => handleSubMenuClick('estimate', 'revenue')}
                className={`${activeSubMenuClass('estimate')}`}
              >
                Estimates and Quotes
              </DropDownItems>

              <DropDownItems
                to={`${routes.RNC_RECURRING_INVOICES}`}
                onClick={() => handleSubMenuClick('recurring', 'revenue')}
                className={`${activeSubMenuClass('recurring')}`}
              >
                Recurring Invoice
              </DropDownItems>

              <DropDownItems
                to={`${routes.RNC_INVOICES}`}
                onClick={() => handleSubMenuClick('invoice', 'revenue')}
                className={`${activeSubMenuClass('invoice')}`}
              >
                Invoice
              </DropDownItems>

              <DropDownItems
                to={`${routes.RNC_CUSTOMERS}`}
                onClick={() => handleSubMenuClick('customer', 'revenue')}
                className={`${activeSubMenuClass('customer')}`}
              >
                Customers
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div
            className="w-full"
            onClick={() => handleMenuClick('inventories')}
          >
            <div className={`${activeMenuClass('inventories')}`}>
              <div
                className="flex justify-between items-center space-x-2"
                onClick={() => handleDropDownClick('inventories')}
              >
                <div className="flex space-x-4 items-center">
                  <InventoryIcon height="24" width="24" />
                  <h1 className="flex items-center ml-[16px] h-[48px]">
                    Inventory
                  </h1>
                </div>
                <span className="">
                  <DropDownIcon height="12" width="8" />
                </span>
              </div>
            </div>
          </div>
          {showInventory && (
            <DropDownContainer>
              <DropDownItems
                to={`${routes.CATEGORIES}`}
                onClick={() => handleSubMenuClick('categories', 'inventories')}
                className={`${activeSubMenuClass('categories')}`}
              >
                Category
              </DropDownItems>

              <DropDownItems
                to={`${routes.INVENTORY_PRODUCTS}`}
                onClick={() => handleSubMenuClick('products', 'inventories')}
                className={`${activeSubMenuClass('products')}`}
              >
                Products and Services
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div
            className="w-full"
            onClick={() => handleMenuClick('purchase and payments')}
          >
            <div className={`${activeMenuClass('purchase and payments')}`}>
              <div
                className="flex justify-between items-center space-x-2"
                onClick={() => handleDropDownClick('purchase')}
              >
                <div className="flex space-x-4 items-center">
                  <PurchaseIcon height="24" width="24" />
                  <h1 className="flex items-center h-[48px]">
                    Purchase and Payments
                  </h1>
                </div>
                <span className="">
                  <DropDownIcon height="12" width="8" />
                </span>
              </div>
            </div>
          </div>
          {showPurchase && <DropDownContainer></DropDownContainer>}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div className="w-full" onClick={() => handleMenuClick('accounting')}>
            <div className={`${activeMenuClass('accounting')}`}>
              <div
                className="flex justify-between items-center space-x-2"
                onClick={() => handleDropDownClick('accounting')}
              >
                <div className="flex space-x-4 items-center">
                  <AccountingIcon height="24" width="24" />
                  <h1 className="flex items-center ml-[16px] h-[48px]">
                    Accounting
                  </h1>
                </div>
                <span className="">
                  <DropDownIcon height="12" width="8" />
                </span>
              </div>
            </div>
          </div>
          {showAccounting && (
            <DropDownContainer>
              <DropDownItems
                to={`${routes.CHARTOFACCOUNTS}`}
                onClick={() => handleSubMenuClick('charts', 'accounting')}
                className={`${activeSubMenuClass('charts')}`}
              >
                Chart of Accounts
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div className="w-full" onClick={() => handleReportMenu('reports')}>
            <div className={`${activeMenuClass('reports')}`}>
              <div
                className="flex justify-between items-center space-x-2"
                onClick={() => handleDropDownClick('reports')}
              >
                <div className="flex space-x-4 items-center">
                  <ReportsIcon height="24" width="24" />
                  <h1 className="flex items-center h-[48px] ml-[16]">
                    Reports
                  </h1>
                </div>
                <span className="">
                  <DropDownIcon height="12" width="8" />
                </span>
              </div>
            </div>
          </div>
          {showReport && <DropDownContainer></DropDownContainer>}
          {showProfit && <DropDownMenuContainer></DropDownMenuContainer>}
        </SideBarMenuContainer>

        <SideBarMenuContainer>
          <div className="w-full" onClick={() => handleMenuClick('settings')}>
            <div className={`${activeMenuClass('settings')}`}>
              <div
                className="flex justify-between items-center space-x-2"
                onClick={() => handleDropDownClick('settings')}
              >
                <div className="flex space-x-4 items-center">
                  <SettingsIcon height="24" width="24" />
                  <h1 className="flex items-center h-[48px] ml-[16px]">
                    Setup
                  </h1>
                </div>
                <span className="">
                  <DropDownIcon height="12" width="8" />
                </span>
              </div>
            </div>
          </div>
          {showSettings && (
            <DropDownContainer>
              <DropDownItems
                to={`${routes.SALES_TAX}`}
                onClick={() => handleSubMenuClick('sales tax', 'settings')}
                className={`${activeSubMenuClass('sales tax')}`}
              >
                Sales Tax
              </DropDownItems>
            </DropDownContainer>
          )}
        </SideBarMenuContainer>
      </SideBarContainer>
    </>
  );
};
