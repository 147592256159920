// eslint-disable-next-line import/order
import 'react-toastify/dist/ReactToastify.css';
import {
  ColumnDirective,
  ColumnsDirective,
  DataStateChangeEventArgs,
  EditSettingsModel,
  Grid,
  GridComponent,
  Inject,
  Page,
  Toolbar,
  DataResult,
} from '@syncfusion/ej2-react-grids';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { EstimateAndQuoteSend } from './EstimateAndQuoteSend';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import { FormButtonsContainer } from '@components/Container';
import { CancelButton } from '@components/Form';
import { CustomSelectOption } from '@components/Form/CustomSelect';
import { OkButton } from '@components/Form/OkButton';
import { DynamicRecordFilter, TableActions } from '@components/Grid';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { PageContainer } from '@components/PageContainer';
import { routes } from '@config/routes';
import { ResultViewModel } from '@models/common/ResultViewModel';
import { estimateStatusSelectOptions } from '@models/EstimateAndQuote';
import { EstimateAndQuoteService } from '@services/EstimateAndQuote';

export const EstimateAndQuoteList: React.FC = () => {
  const navigate = useNavigate();
  const [dataUrlQueryFilter, setDataUrlQueryFilter] = useState<string>('');
  const [showDelete, setShowDelete] = useState(false);
  const [selectedEstimateId, setSelectedEstimateId] = useState<number>(0);
  const [selectedEstimateDisplayString, setEstimateDisplayString] =
    useState<any>(0);
  const [customerFilter, setCustomerFilter] = useState<string>('');
  const [customerFilterSelectOptions, setCustomerFilterSelectOptions] =
    useState<CustomSelectOption[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [dateFromFilter, setDateFromFilter] = useState<string>('');
  const [dateToFilter, setDateToFilter] = useState<string>('');
  const [estimateNumberFilter, setEstimateNumberFilter] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCustomerFilterLoading, setIsCustomerFilterLoading] =
    useState<boolean>(true);
  const [showSend, setShowSend] = useState(false);

  let grid: Grid | null;
  let data;

  const editOptions: EditSettingsModel = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Dialog',
  };

  const loadCustomerFilterSelectOptions = () => {
    EstimateAndQuoteService.getCustomerFilterSelectOptions().then((result) => {
      const customerSelectOptions = result.data.map((row) => ({
        value: row.value,
        text: row.text,
      }));
      setCustomerFilterSelectOptions(customerSelectOptions);

      setIsCustomerFilterLoading(false);
    });
  };

  useEffect(() => {
    loadCustomerFilterSelectOptions();
  }, []);

  useEffect(() => {
    if (!isCustomerFilterLoading) {
      setIsLoading(false);
    }
  }, [isCustomerFilterLoading]);

  useEffect(() => {
    grid?.refresh();
  }, [dataUrlQueryFilter]);

  useEffect(() => {
    let tempDataUrlQueryFilter = '';
    if (customerFilter) {
      tempDataUrlQueryFilter += `&customerFilters=${customerFilter}`;
    }
    if (statusFilter) {
      tempDataUrlQueryFilter += `&statusFilters=${statusFilter}`;
    }
    if (dateFromFilter) {
      tempDataUrlQueryFilter += `&dateFromFilters=${dateFromFilter}`;
    }
    if (dateToFilter) {
      tempDataUrlQueryFilter += `&dateToFilters=${dateToFilter}`;
    }
    if (estimateNumberFilter) {
      tempDataUrlQueryFilter += `&estimateNumberFilters=${estimateNumberFilter}`;
    }
    setDataUrlQueryFilter(tempDataUrlQueryFilter);
  }, [
    customerFilter,
    statusFilter,
    dateFromFilter,
    dateToFilter,
    estimateNumberFilter,
  ]);

  function renderComplete() {
    if (
      grid &&
      grid.dataSource instanceof Array &&
      !(grid.dataSource as object[]).length
    ) {
      const state = { skip: 0, take: 10 };
      dataStateChange(state);
    }
  }

  function dataStateChange(state: DataStateChangeEventArgs) {
    execute(state).then((gridData) => {
      if (grid) {
        grid.dataSource = gridData;
      }
    });
  }

  function execute(state: DataStateChangeEventArgs): Promise<DataResult> {
    return getData(state);
  }

  function getData(state: DataStateChangeEventArgs): Promise<DataResult> {
    const page =
      !state || !state.skip || !state.take ? 1 : state.skip / state?.take + 1;

    return EstimateAndQuoteService.getList(
      page,
      state.take,
      undefined,
      undefined,
      dataUrlQueryFilter
    ).then((data) => {
      return { result: data.data, count: data.total };
    });
  }

  const handleSend = async (rowId: number, rowEstimateNumber: string) => {
    try {
      setSelectedEstimateId(rowId);
      setEstimateDisplayString(rowEstimateNumber);
      setShowSend(true);
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const deleteRecord = (id: number) => {
    EstimateAndQuoteService.delete(id).then((response) => {
      if (response.status == 204) {
        toast.success('Successfully deleted the record', {
          position: toast.POSITION.TOP_LEFT,
          theme: 'colored',
          autoClose: 500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Error: Failed to delete the record. ${result.errorMessage}`;
          toast.error(`${message}`, {
            position: toast.POSITION.TOP_LEFT,
            theme: 'colored',
            autoClose: 500,
          });
        });
      }
    });
  };

  const handleDelete = async (rowId: number, rowEstimateNumber: string) => {
    try {
      setSelectedEstimateId(rowId);
      setEstimateDisplayString(rowEstimateNumber);
      setShowDelete(true);
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleApprove = (rowId: number, rowEstimateNumber: string) => {
    EstimateAndQuoteService.approve(rowId).then((response) => {
      if (response.status == 200) {
        const message = `${rowEstimateNumber} has been approved.`;
        console.log(`${message}`);
        alert(`${message}`);
        window.location.reload();
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `${rowEstimateNumber} encountered an error. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };

  const handleView = (rowId: number) => {
    console.log('View', rowId);
    navigate(`${routes.RNC_ESTIMATES}/${rowId}`);
  };

  const handleEdit = (rowId: number) => {
    navigate(`${routes.RNC_ESTIMATES}/${rowId}/edit`);
  };

  const handlePrint = (rowId: number) => {
    console.log('Print', rowId);
    toast.success('Print selected Estimate', {
      position: toast.POSITION.TOP_LEFT,
      theme: 'colored',
      autoClose: 500,
    });
  };

  const handleExportToPdf = (rowId: number) => {
    navigate(`${routes.RNC_ESTIMATES}/${rowId}/pdf`);
  };

  const handleDuplicate = (rowId: number) => {
    EstimateAndQuoteService.duplicate(rowId).then((response) => {
      if (response.status == 200) {
        const message = `Successfuly duplicated.`;
        toast.success(`${message}`, {
          position: toast.POSITION.TOP_LEFT,
          theme: 'colored',
          autoClose: 500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Estimate duplication encountered an error. ${result.errorMessage}`;
          toast.error(`${message}`, {
            position: toast.POSITION.TOP_LEFT,
            theme: 'colored',
            autoClose: 500,
          });
        });
      }
    });
  };

  const actionButtons = (props: any) => {
    const menuOnTop = props.index >= 1;
    return (
      <TableActions
        inlineActions={[
          {
            title: 'Send',
            onClick: () => handleSend(props.id, props.estimateNumber),
          },
        ]}
        dropdownActions={[
          {
            title: 'Edit',
            onClick: () => handleEdit(props.id),
          },
          {
            title: 'View',
            onClick: () => handleView(props.id),
          },
          {
            title: 'Duplicate',
            onClick: () => handleDuplicate(props.id),
          },
          {
            title: 'Print',
            onClick: () => handlePrint(props.id),
          },
          {
            showTopSeparator: true,
            title: 'Send',
            onClick: () => handleSend(props.id, props.estimateNumber),
          },
          {
            title: 'Approve',
            onClick: () => handleApprove(props.id, props.estimateNumber),
          },
          {
            title: 'Export to PDF',
            onClick: () => handleExportToPdf(props.id),
          },
          {
            showTopSeparator: true,
            title: 'Delete',
            onClick: () => handleDelete(props.id, props.estimateNumber),
          },
        ]}
        menuOnTop={menuOnTop}
      />
    );
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <ToastContainer />
        <DynamicRecordFilter
          label="Filters"
          filters={[
            {
              label: 'Select Customer',
              type: 'select',
              selection: customerFilterSelectOptions,
              value: customerFilter,
              onChange: (e) => {
                setCustomerFilter(e.target.value);
              },
            },
            {
              label: 'Select Status',
              type: 'select',
              selection: estimateStatusSelectOptions,
              value: statusFilter,
              onChange: (e) => {
                setStatusFilter(e.target.value);
              },
            },
            {
              label: 'Date From',
              type: 'date',
              value: dateFromFilter,
              onChange: (e) => {
                setDateFromFilter(e.target.value);
              },
            },
            {
              label: 'Date To',
              type: 'date',
              value: dateToFilter,
              onChange: (e) => {
                setDateToFilter(e.target.value);
              },
            },
            {
              label: 'Estimate Number',
              type: 'text',
              value: estimateNumberFilter,
              onChange: (e) => {
                setEstimateNumberFilter(e.target.value);
              },
            },
          ]}
          onFilter={() => {
            setDataUrlQueryFilter(
              `&customerFilters=${customerFilter}&statusFilters=${statusFilter}&dateFromFilters=${dateFromFilter}&dateToFilters=${dateToFilter}&estimateNumberFilters=${estimateNumberFilter}`
            );
          }}
          onClear={() => {
            setDataUrlQueryFilter('');
            setCustomerFilter('');
            setStatusFilter('');
            setDateFromFilter('');
            setDateToFilter('');
            setEstimateNumberFilter('');
          }}
        />

        <PageContainer>
          <GridComponent
            dataBound={renderComplete}
            dataSource={data}
            ref={(g) => (grid = g)}
            allowPaging={true}
            pageSettings={{ pageSize: 10 }}
            editSettings={editOptions}
            dataStateChange={dataStateChange}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="statusDisplayString"
                headerText="Status"
              />
              <ColumnDirective field="estimateNumber" headerText="Number" />
              <ColumnDirective
                field="customerCompanyName"
                headerText="Customer"
              />
              <ColumnDirective
                field="total"
                headerText="Amount"
                template={(props: any) => {
                  return (
                    <span>
                      {Number(props.total).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  );
                }}
              />
              <ColumnDirective
                headerText="Actions"
                width={140}
                template={actionButtons}
              />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar]} />
          </GridComponent>

          {showDelete && (
            <>
              <Modal show={true}>
                <CloseButton onClick={() => setShowDelete(false)} />
                <Box size="medium">
                  <div className="px-4 pb-2">
                    <span>
                      Are you sure to delete Estimate:{' '}
                      {selectedEstimateDisplayString}
                    </span>
                  </div>
                  <FormButtonsContainer>
                    <div className="space-x-2">
                      <CancelButton onClick={() => setShowDelete(false)} />
                      <OkButton
                        onClick={() => deleteRecord(selectedEstimateId)}
                      >
                        Delete
                      </OkButton>
                    </div>
                  </FormButtonsContainer>
                </Box>
              </Modal>
            </>
          )}

          {showSend && (
            <>
              <Modal show={true}>
                <EstimateAndQuoteSend
                  onDownload={() => handleExportToPdf(selectedEstimateId)}
                  estimateId={selectedEstimateId}
                  onClose={() => setShowSend(false)}
                  estimateName={selectedEstimateDisplayString}
                />
              </Modal>
            </>
          )}
        </PageContainer>
        <ToastContainer />
      </>
    );
  }
};
