import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';

import { BreadCrumbs } from '../Layout/Header/BreadCrumbs';
import { HeaderContainer } from '../Layout/HeaderContainer';

import { BreadcrumbsContainer } from '@components/BreadcrumbsContainer';
import MyAreaChart from '@components/Charts';
import CustomPieChart from '@components/PieChart';
import MyBarChart from '@components/TableContainers/Bar';
import { routes } from '@config/routes';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Tooltip,
  Legend
);

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

export const DashBoard = () => {
  const sampleData = [
    { name: '2012', uv: 4000, pv: 2400 },
    { name: '2013', uv: 3000, pv: 1398 },
    { name: '2014', uv: 2000, pv: 9800 },
    { name: '2015', uv: 2780, pv: 3908 },
    { name: '2016', uv: 1890, pv: 4800 },
    { name: '2017', uv: 2390, pv: 3800 },
    { name: '2018', uv: 3490, pv: 4300 },
  ];

  const baseBreadCrumbs = [{ link: '', to: '' }];

  const isOnDashboard = location.pathname === routes.HOME;

  const breadCrumbLinks = isOnDashboard ? baseBreadCrumbs : baseBreadCrumbs;

  return (
    <>
      <div className="p-4 space-y-4">
        <BreadcrumbsContainer>
          <div className="text-sm font-semibold text-gray-500">Dashboard</div>
          <div>
            <BreadCrumbs links={breadCrumbLinks} />
          </div>
        </BreadcrumbsContainer>
        <HeaderContainer>
          <div className="w-[83%] mx-auto p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {/* Card 1 */}
              <div className="col-span-1">
                <div className="w-full  mx-auto">
                  <div className="h-[41px] bg-[#53CC96] flex items-center justify-between px-2">
                    <p className="text-white text-sm font-bold">Lorem Ipsum</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="white"
                      className="bi bi-cart-check-fill text-white"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                    </svg>
                  </div>
                  <div className="h-[93px] bg-white text-xs flex items-center justify-center p-2 border-b border-b-[#D9D9D9]">
                    <button className="h-[15px] bg-blue-500 text-white text-[10px] rounded-md px-1 mr-2">
                      + 100%
                    </button>
                    <p className="text-[10px]">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                  </div>
                  <div className="grid grid-cols-2 bg-white text-[10px] p-2">
                    <div className="flex justify-start pl-4 font-semibold">
                      <p>12345</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        width="14"
                        height="14"
                        fill="blue"
                        className="ml-2 mt-[3px]"
                      >
                        <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                      </svg>
                    </div>
                    <div className="flex justify-end pr-4 font-light">
                      <p>Last: 12345</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 2 */}
              <div className="col-span-1">
                <div className="w-full  mx-auto">
                  <div className="h-[41px] bg-[#53CC96] flex items-center justify-between px-2">
                    <p className="text-white text-sm font-bold">Lorem Ipsum</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="white"
                      className="bi bi-cart-check-fill text-white"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                    </svg>
                  </div>
                  <div className="h-[93px] bg-white text-xs flex items-center justify-center p-2 border-b border-b-[#D9D9D9]">
                    <button className="h-[15px] bg-red-500 text-white text-[10px] rounded-md px-1 mr-2">
                      + 100%
                    </button>
                    <p className="text-[10px]">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                  </div>
                  <div className="grid grid-cols-2 bg-white text-[10px] p-2">
                    <div className="flex justify-start pl-4 font-semibold">
                      <p>12345</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        width="14"
                        height="14"
                        fill="blue"
                        className="ml-2 mt-[3px]"
                      >
                        <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                      </svg>
                    </div>
                    <div className="flex justify-end pr-4 font-light">
                      <p>Last: 12345</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 3 */}
              <div className="col-span-1">
                <div className="w-full  mx-auto">
                  <div className="h-[41px] bg-[#53CC96] flex items-center justify-between px-2">
                    <p className="text-white text-sm font-bold">Lorem Ipsum</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="white"
                      className="bi bi-cart-check-fill text-white"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                    </svg>
                  </div>
                  <div className="h-[93px] bg-white text-xs flex items-center justify-center p-2 border-b border-b-[#D9D9D9]">
                    <button className="h-[15px] bg-green-500 text-white text-[10px] rounded-md px-1 mr-2">
                      + 100%
                    </button>
                    <p className="text-[10px]">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                  </div>
                  <div className="grid grid-cols-2 bg-white text-[10px] p-2">
                    <div className="flex justify-start pl-4 font-semibold">
                      <p>12345</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        width="14"
                        height="14"
                        fill="blue"
                        className="ml-2 mt-[3px]"
                      >
                        <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                      </svg>
                    </div>
                    <div className="flex justify-end pr-4 font-light">
                      <p>Last: 12345</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 4 */}
              <div className="col-span-1">
                <div className="w-full  mx-auto">
                  <div className="h-[41px] bg-[#53CC96] flex items-center justify-between px-2">
                    <p className="text-white text-sm font-bold">Lorem Ipsum</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="white"
                      className="bi bi-cart-check-fill text-white"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                    </svg>
                  </div>
                  <div className="h-[93px] bg-white text-xs flex items-center justify-center p-2 border-b border-b-[#D9D9D9]">
                    <button className="h-[15px] bg-yellow-500 text-white text-[10px] rounded-md px-1 mr-2">
                      + 100%
                    </button>
                    <p className="text-[10px]">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                  </div>
                  <div className="grid grid-cols-2 bg-white text-[10px] p-2">
                    <div className="flex justify-start pl-4 font-semibold">
                      <p>12345</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        width="14"
                        height="14"
                        fill="blue"
                        className="ml-2 mt-[3px]"
                      >
                        <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                      </svg>
                    </div>
                    <div className="flex justify-end pr-4 font-light">
                      <p>Last: 12345</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HeaderContainer>

        <div className="flex flex-col md:flex-row justify-center items-center md:items-start overflow-hidden">
          {/* Area Chart */}
          <div className="w-full mb-4 md:mb-0 md:mr-4 md:max-w-[440px] lg:max-w-[678px]">
            <MyAreaChart data={sampleData} />
          </div>

          {/* Bar Chart */}
          <div className="w-full md:max-w-[440px] lg:max-w-[440px]">
            <MyBarChart data={sampleData} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center pt-4 space-y-4 md:space-y-0 md:space-x-4 lg:space-y-0 lg:space-x-4">
          {/* Monthly Earning Card */}
          <div className="bg-white w-full max-w-xs md:max-w-sm lg:max-w-sm h-[185px]">
            <div>
              <p className="text-black text-[8px] md:text-[10px] lg:text-[10px] p-2 font-semibold font-inter">
                Monthly Earning
              </p>
            </div>
            <div className="flex">
              <div className="w-[60%] p-[5px]">
                <p className="text-lg md:text-xl lg:text-xl font-bold">$6451</p>
                <p className="text-[10px] md:text-[12px] lg:text-[12px] p-[5px] font-semibold">
                  Monthly Earning
                </p>
                <p className="text-[8px] md:text-[10px] lg:text-[10px] p-2">
                  Lorem ipsum dolor
                </p>
                <p className="text-green-300 text-sm font-semibold">
                  Learn more...
                </p>
              </div>
              <div className="w-[40%] p-[5px] positive">
                <div className="ml-4 w-full float-right">
                  <CustomPieChart />
                </div>
              </div>
            </div>
          </div>

          {/* Recent Activity Feed Card */}
          <div className="bg-white w-full max-w-xs md:max-w-sm lg:max-w-sm h-[185px]">
            <div>
              <p className="text-black text-[8px] md:text-[10px] lg:text-[10px] p-2 lg:p-4 font-semibold font-inter">
                Recent Activity Feed
              </p>
            </div>
            <div className="flex">
              <div className="w-1/2">
                <p className="p-2 ml-4 text-[8px] md:text-xs lg:text-xs">
                  12 Oct
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-[8px] md:text-[10px] lg:text-[10px]">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-[8px] md:text-[10px] lg:text-[10px]">
                  Lorem ipsum dolor sit amet.
                </p>
              </div>
            </div>
            <div className="flex mt-2">
              <div className="w-1/2">
                <p className="p-2 ml-4 text-[8px] md:text-xs lg:text-xs">
                  12 Oct
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-[8px] md:text-[10px] lg:text-[10px]">
                  Lorem ipsum dolor sit amet.
                </p>
                <p className="text-[8px] md:text-[10px] lg:text-[10px]">
                  Lorem ipsum dolor sit amet.
                </p>
              </div>
            </div>
          </div>

          {/* Weather Card */}
          <div className="bg-white w-full max-w-xs md:max-w-sm lg:max-w-sm flex h-[185px]">
            <div className="w-1/2 p-2 h-full bg-[#59CB9A] flex flex-col items-center justify-center">
              <div className="h-8 md:h-16 lg:h-20 flex items-center">
                <span>
                  {/* Icon */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="24"
                    height="24"
                    fill="white"
                  >
                    <path d="M416 128c-.6 0-1.1 .2-1.6 .2 ..." />
                  </svg>
                </span>
              </div>
              <p className="text-white text-xs md:text-sm lg:text-sm">32°C</p>
              <p className="text-white text-xs md:text-sm lg:text-sm">
                Heavy rain
              </p>
              <p className="text-white text-xs md:text-sm lg:text-sm">
                New York
              </p>
            </div>
            <div className="w-1/2 p-2 h-full bg-[#F9C053] flex flex-col items-center justify-center">
              <div className="h-8 md:h-16 lg:h-20 flex items-center">
                <span>
                  {/* Icon */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    width="24"
                    height="24"
                    fill="white"
                  >
                    <path d="M575.2 325.7c.2-1.9 .8-3.7 .8-5.6 ..." />
                  </svg>
                </span>
              </div>
              <p className="text-white text-xs md:text-sm lg:text-sm">32°C</p>
              <p className="text-white text-xs md:text-sm lg:text-sm">
                Partly Cloudy
              </p>
              <p className="text-white text-xs md:text-sm lg:text-sm">
                California
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
