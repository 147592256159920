interface Props {
  children?: React.ReactNode;
}
/**
 * BoxBodyContainer is used inside
 * Box, BoxMedium, BoxNarrow to provide side spacing.
 */

export const BoxBodyContainer: React.FC<Props> = ({ children }) => {
  return <div className="px-1">{children}</div>;
};
