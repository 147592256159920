import {
  ColumnDirective,
  ColumnsDirective,
  DataStateChangeEventArgs,
  EditSettingsModel,
  Grid,
  GridComponent,
  Inject,
  Page,
  Toolbar,
  DataResult,
} from '@syncfusion/ej2-react-grids';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CustomSelectOption } from '@components/Form/CustomSelect';
import { DynamicRecordFilter, TableActions } from '@components/Grid';
import { Loading } from '@components/Loading';
import { PageContainer } from '@components/PageContainer';
import { routes } from '@config/routes';
import { ResultViewModel } from '@models/common/ResultViewModel';
import { recurringInvoiceStatusSelectOptions } from '@models/RecurringInvoice';
import { RecurringInvoiceService } from '@services/RecurringInvoice';

export const RecurringInvoiceList: React.FC = () => {
  const [dataUrlQueryFilter, setDataUrlQueryFilter] = useState<string>('');
  const [customerFilter, setCustomerFilter] = useState<string>('');
  const [customerFilterSelectOptions, setCustomerFilterSelectOptions] =
    useState<CustomSelectOption[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [dateFromFilter, setDateFromFilter] = useState<string>('');
  const [dateToFilter, setDateToFilter] = useState<string>('');
  const [invoiceNumberFilter, setInvoiceNumberFilter] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCustomerFilterLoading, setIsCustomerFilterLoading] =
    useState<boolean>(true);
  const [custoemrfiltercount, setCustomerFilterCount] = useState<string>('');
  const navigate = useNavigate();

  let grid: Grid | null;
  let data;

  const editOptions: EditSettingsModel = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Dialog',
  };

  const loadCustomerFilterSelectOptions = () => {
    RecurringInvoiceService.getCustomerFilterSelectOptions().then((result) => {
      const customerSelectOptions = result.data.map((row) => ({
        value: row.value,
        text: row.text,
      }));
      setCustomerFilterSelectOptions(customerSelectOptions);

      setIsCustomerFilterLoading(false);
    });
  };

  useEffect(() => {
    loadCustomerFilterSelectOptions();
  }, []);

  useEffect(() => {
    if (!isCustomerFilterLoading) {
      setIsLoading(false);
    }
  }, [isCustomerFilterLoading]);

  useEffect(() => {
    grid?.refresh();
  }, [dataUrlQueryFilter]);

  useEffect(() => {
    if (custoemrfiltercount) {
      console.log('filter', 1);
    }
  });

  useEffect(() => {
    let tempDataUrlQueryFilter = '';
    if (customerFilter) {
      tempDataUrlQueryFilter += `&customerFilters=${customerFilter}`;
    }
    if (statusFilter) {
      tempDataUrlQueryFilter += `&statusFilters=${statusFilter}`;
    }
    if (dateFromFilter) {
      tempDataUrlQueryFilter += `&dateFromFilters=${dateFromFilter}`;
    }
    if (dateToFilter) {
      tempDataUrlQueryFilter += `&dateToFilters=${dateToFilter}`;
    }
    if (invoiceNumberFilter) {
      tempDataUrlQueryFilter += `&invoiceNumberFilters=${invoiceNumberFilter}`;
    }
    setDataUrlQueryFilter(tempDataUrlQueryFilter);
  }, [
    customerFilter,
    statusFilter,
    dateFromFilter,
    dateToFilter,
    invoiceNumberFilter,
  ]);

  function renderComplete() {
    if (
      grid &&
      grid.dataSource instanceof Array &&
      !(grid.dataSource as object[]).length
    ) {
      const state = { skip: 0, take: 10 };
      dataStateChange(state);
    }
  }

  function dataStateChange(state: DataStateChangeEventArgs) {
    execute(state).then((gridData) => {
      if (grid) {
        grid.dataSource = gridData;
      }
    });
  }

  function execute(state: DataStateChangeEventArgs): Promise<DataResult> {
    return getData(state);
  }

  function getData(state: DataStateChangeEventArgs): Promise<DataResult> {
    const page =
      !state || !state.skip || !state.take ? 1 : state.skip / state?.take + 1;

    return RecurringInvoiceService.getList(
      page,
      state.take,
      undefined,
      undefined,
      dataUrlQueryFilter
    ).then((data) => {
      return { result: data.data, count: data.total };
    });
  }

  const handleEdit = (rowId: number) => {
    navigate(`${routes.RNC_RECURRING_INVOICES}/${rowId}/edit`);
  };

  const handleEndRecord = (id: number) => {
    const endTime = new Date();

    RecurringInvoiceService.end(id, { endTime }).then((response) => {
      if (response.status == 200) {
        console.log('Schedule successfully ended today');
        alert('Schedule ended for today');
        window.location.reload();
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Error: Failed to End the schedule. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };

  const handleDuplicate = (rowId: number) => {
    RecurringInvoiceService.duplicate(rowId).then((response) => {
      if (response.status == 200) {
        const message = `Successfuly duplicated.`;
        toast.success(`${message}`, {
          position: toast.POSITION.TOP_LEFT,
          theme: 'colored',
          autoClose: 500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Estimate duplication encountered an error. ${result.errorMessage}`;
          toast.error(`${message}`, {
            position: toast.POSITION.TOP_LEFT,
            theme: 'colored',
            autoClose: 500,
          });
        });
      }
    });
  };

  // const handleDelete = async (rowId: number) => {
  //   try {
  //     setSelectedInvoiceId(rowId);
  //     setShowDelete(true);
  //   } catch (error) {
  //     console.error('Error: ', error);
  //   }
  // };

  // const deleteRecord = (id: number) => {
  //   RecurringInvoiceService.delete(id).then((response) => {
  //     if (response.status == 204) {
  //       console.log('Successfully deleted the record.');
  //       alert('Success');
  //       window.location.reload();
  //     } else {
  //       const failureResponse = response.data as Promise<ResultViewModel>;
  //       return failureResponse.then((result) => {
  //         const message = `Error: Failed to delete the record. ${result.errorMessage}`;
  //         alert(message);
  //         console.log(message);
  //       });
  //     }
  //   });
  // };

  const actionButtons = (props: any) => {
    const menuOnTop = props.index >= 1;
    return (
      <TableActions
        inlineActions={[
          {
            title: 'End',
            onClick: () => handleDuplicate(props.id),
          },
        ]}
        dropdownActions={[
          {
            title: 'Edit',
            onClick: () => handleEdit(props.id),
          },
          {
            showTopSeparator: true,
            title: 'End',
            onClick: () => handleEndRecord(props.id),
          },
        ]}
        menuOnTop={menuOnTop}
      />
    );
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <DynamicRecordFilter
          label="Filters"
          filters={[
            {
              label: 'Select Customer',
              type: 'select',
              selection: customerFilterSelectOptions,
              value: customerFilter,
              onChange: (e) => {
                setCustomerFilter(e.target.value);
                setCustomerFilterCount(customerFilter);
              },
            },
            {
              label: 'Select Status',
              type: 'select',
              selection: recurringInvoiceStatusSelectOptions,
              value: statusFilter,
              onChange: (e) => {
                setStatusFilter(e.target.value);
              },
            },
          ]}
          onFilter={() => {
            setDataUrlQueryFilter(
              `&customerFilters=${customerFilter}&statusFilters=${statusFilter}`
            );
          }}
          onClear={() => {
            setDataUrlQueryFilter('');
            setCustomerFilter('');
            setStatusFilter('');
            setDateFromFilter('');
            setDateToFilter('');
            setInvoiceNumberFilter('');
          }}
        />
        <PageContainer>
          <GridComponent
            dataBound={renderComplete}
            dataSource={data}
            ref={(g) => (grid = g)}
            allowPaging={true}
            pageSettings={{ pageSize: 10 }}
            editSettings={editOptions}
            dataStateChange={dataStateChange}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="statusDisplayString"
                headerText="Status"
              />
              <ColumnDirective
                field="customerCompanyName"
                headerText="Customer"
              />
              <ColumnDirective field="schedule" headerText="Schedule" />
              <ColumnDirective
                field="total"
                headerText="Total"
                template={(props: any) => {
                  return (
                    <span>
                      {Number(props.total).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  );
                }}
              />
              <ColumnDirective
                headerText="Actions"
                width={140}
                template={actionButtons}
              />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar]} />
          </GridComponent>
          {/* {showDelete && (
            <>
              <Modal show={true}>
                <CloseButton onClick={() => setShowDelete(false)} />
                <Box size="medium">
                  <div className="px-4 pb-2">
                    <span>
                      Are you sure you want to delete this Recurring Invoice
                    </span>
                  </div>
                  <FormButtonsContainer>
                    <div className="space-x-2">
                      <CancelButton onClick={() => setShowDelete(false)} />
                      <OkButton onClick={() => deleteRecord(selectedInvoiceId)}>
                        Delete
                      </OkButton>
                    </div>
                  </FormButtonsContainer>
                </Box>
              </Modal>
            </>
          )} */}
        </PageContainer>
      </>
    );
  }
};
