import { useField, FieldHookConfig } from 'formik';
import React from 'react';
import tw from 'tailwind-styled-components';

interface BaseTextAreaProps {
  hasError: boolean;
}

const BaseTextArea = tw.textarea<BaseTextAreaProps>`
block w-full  shadow-sm  lg:text-sm md:text-xs text-xs
${(props) =>
  !props.hasError &&
  !props.value &&
  'border-primary-300 focus:border-primary-500 focus:ring-primary-500'} 
  ${(props) =>
    !props.hasError &&
    !!props.value &&
    'border-primary-300 focus:border-primary-500 focus:ring-primary-500 bg-green-50'} 
  ${(props) =>
    props.hasError &&
    !props.value &&
    'border-primary-300 focus:border-primary-500 focus:ring-primary-500'} 
  ${(props) =>
    props.hasError &&
    !!props.value &&
    'border-red-300 focus:border-red-500 focus:ring-red-500 bg-red-100'} 
  `;

type Props = {
  label?: string;
  rows?: number;
  readonly?: boolean;
  hideError?: boolean;
} & FieldHookConfig<string>;

export const FormikTextArea: React.FC<Props> = ({
  label,
  rows,
  readonly,
  hideError,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full">
      <label className="block lg:text-sm md:text-sm text-xs font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <BaseTextArea
          {...field}
          readOnly={readonly}
          rows={rows}
          hasError={!!meta.error && !hideError}
        />
        {meta.error && !hideError ? (
          <div className="text-red-500 lg:text-sm md:text-sm text-xs">
            {meta.error}
          </div>
        ) : null}
      </div>
    </div>
  );
};
