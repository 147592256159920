interface Props {
  children?: React.ReactNode;
}

export const DetailTableDataRight: React.FC<Props> = ({ children }) => {
  return (
    <td className="relative whitespace-nowrap py-3 pl-3 pr-6 lg:text-sm md:text-sm text-xs text-right font-normal align-top space-x-2 text-gray-900">
      {children}
    </td>
  );
};
