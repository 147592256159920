interface Props {
  children?: React.ReactNode;
}

export const DetailTableDataCenter: React.FC<Props> = ({ children }) => {
  return (
    <td className="whitespace-nowrap text-center px-3 py-3 text-gray-900 lg:text-sm md:text-sm text-xs align-top font-normal">
      {children}
    </td>
  );
};
