import axios from 'axios';

import { api } from '@config/apiEndpoints';
import { MyProfileTableViewModel } from '@models/auth/MyProfileCompany';

interface SwitchCompany {
  companyId: string;
}

export const MyProfileService = {
  getMyCompanies: async function () {
    return axios
      .get(`${api.AUTH_BASE_URL}api/my-profile/companies?limit=10`)
      .then((response) => {
        return response.data as Promise<MyProfileTableViewModel>;
      });
  },
  switchCompany: async function (companyId: string) {
    const payload: SwitchCompany = {
      companyId,
    };
    return axios.post(
      `${api.AUTH_BASE_URL}api/my-profile/switch-company`,
      payload
    );
  },
};
