import {
  ColumnDirective,
  ColumnsDirective,
  DataStateChangeEventArgs,
  EditSettingsModel,
  Grid,
  GridComponent,
  Inject,
  Page,
  Toolbar,
  DataResult,
  Filter,
} from '@syncfusion/ej2-react-grids';
import { useEffect, useState } from 'react';

import { SalesTaxEdit } from './SalesTaxEdit';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import { FormButtonsContainer } from '@components/Container';
import { CancelButton } from '@components/Form';
import { OkButton } from '@components/Form/OkButton';
import { FilterRecord, TableActions } from '@components/Grid';
import { Modal } from '@components/Modal';
import { PageContainer } from '@components/PageContainer';
import { Space } from '@components/Space';
import { SalesTaxResultViewModel } from '@models/SalesTax';
import { SalesTaxService } from '@services/SalesTax';

export const SalesTaxList: React.FC = () => {
  const [showEditSalesTax, setShowEditSalesTax] = useState(false);
  const [dataUrlQueryFilter, setDataUrlQueryFilter] = useState<string>('');
  const [showDelete, setShowDelete] = useState(false);
  const [selectedSalesTaxId, setSelectedSalesTaxId] = useState<number>(0);
  const [salesTaxName, setSalesTaxName] = useState<string>('');
  const [nameFilter, setNameFilter] = useState<string>('');

  let grid: Grid | null;
  let data;

  const editOptions: EditSettingsModel = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Dialog',
  };

  useEffect(() => {
    grid?.refresh();
  }, [dataUrlQueryFilter]);

  function renderComplete() {
    if (
      grid &&
      grid.dataSource instanceof Array &&
      !(grid.dataSource as object[]).length
    ) {
      const state = { skip: 0, take: 10 };
      dataStateChange(state);
    }
  }

  function dataStateChange(state: DataStateChangeEventArgs) {
    execute(state).then((gridData) => {
      if (grid) {
        grid.dataSource = gridData;
      }
    });
  }

  function execute(state: DataStateChangeEventArgs): Promise<DataResult> {
    return getData(state);
  }

  function getData(state: DataStateChangeEventArgs): Promise<DataResult> {
    const page =
      !state || !state.skip || !state.take ? 1 : state.skip / state?.take + 1;

    return SalesTaxService.getList(
      page,
      state.take,
      undefined,
      undefined,
      dataUrlQueryFilter
    ).then((data) => {
      return { result: data.data, count: data.total };
    });
  }

  const handleEdit = (rowId: any) => {
    setSelectedSalesTaxId(rowId);
    setShowEditSalesTax(true);
  };

  const deleteRecord = (id: number) => {
    SalesTaxService.delete(id).then((response) => {
      if (response.status === 204) {
        console.log('successfully deleted a record.');
        window.location.reload();
      } else {
        console.log('Error: Failed to delete the record.');
      }
    });
  };

  const handleDelete = async (rowId: any) => {
    try {
      SalesTaxService.getById(Number(rowId))
        .then((respone) => respone.data as Promise<SalesTaxResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            setSelectedSalesTaxId(rowId);
            setSalesTaxName(result.data.name);
            setShowDelete(true);
            console.log('Success: Fetched the record.');
          } else {
            const message = `Error: Failed to fetch sales tax details for deletion. ${result.errorMessage}`;
            alert(message);
            console.log(message);
          }
        });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const rateTemplate = (props: any) => {
    const rateValue = props.rate;
    return rateValue !== null && rateValue !== undefined ? (
      <div>{rateValue}%</div>
    ) : null;
  };

  const actionButtons = (props: any) => {
    const menuOnTop = props.index >= 1;

    return (
      <TableActions
        dropdownActions={[
          {
            title: 'Edit',
            onClick: () => handleEdit(props.id),
          },
          {
            showTopSeparator: true,
            title: 'Delete',
            onClick: () => handleDelete(props.id),
          },
        ]}
        menuOnTop={menuOnTop}
      />
    );
  };

  return (
    <>
      <FilterRecord
        label="Filter by Name"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        onFilter={() => {
          setDataUrlQueryFilter(`&nameFilters=${nameFilter}`);
        }}
        onClear={() => {
          setDataUrlQueryFilter('');
          setNameFilter('');
        }}
      />

      <Space />

      <PageContainer>
        <GridComponent
          dataBound={renderComplete}
          dataSource={data}
          ref={(g) => (grid = g)}
          allowPaging={true}
          pageSettings={{ pageSize: 10 }}
          editSettings={editOptions}
          dataStateChange={dataStateChange}
        >
          <ColumnsDirective>
            <ColumnDirective field="name" headerText="Tax Name" />
            <ColumnDirective field="description" headerText="Description" />
            <ColumnDirective field="abbreviation" headerText="Abbreviation" />
            <ColumnDirective template={rateTemplate} headerText="Tax Rate" />
            <ColumnDirective
              headerText="Actions"
              width={100}
              template={actionButtons}
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Filter]} />
        </GridComponent>

        {showEditSalesTax && (
          <>
            <Modal show={true}>
              {selectedSalesTaxId && (
                <SalesTaxEdit
                  taxId={selectedSalesTaxId}
                  onClose={() => {
                    setShowEditSalesTax(false);
                  }}
                  onDelete={() => {
                    setShowEditSalesTax(false);
                    handleDelete(selectedSalesTaxId);
                  }}
                />
              )}
            </Modal>
          </>
        )}

        {showDelete && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setShowDelete(false)} />
              <Box size="medium">
                <div className="px-4 pb-2">
                  <span>Are you sure to delete Sales Tax: {salesTaxName}</span>
                </div>
                <FormButtonsContainer>
                  <div className="space-x-2">
                    <CancelButton onClick={() => setShowDelete(false)} />
                    <OkButton onClick={() => deleteRecord(selectedSalesTaxId)}>
                      Delete
                    </OkButton>
                  </div>
                </FormButtonsContainer>
              </Box>
            </Modal>
          </>
        )}
      </PageContainer>
    </>
  );
};
