import axios from 'axios';

import { api } from '@config/apiEndpoints';
import { ResultViewModel } from '@models/common/ResultViewModel';
import {
  ProductSelectOptionResultViewModel,
  ProductTableResultViewModel,
  ProductViewModel,
} from '@models/Product';
import { handleAxiosError } from '@utils/axiosErrorHandler';
import { getDataUrl } from '@utils/dataUrls';

export const ProductService = {
  create: async function (
    products: ProductViewModel
  ): Promise<ProductResultViewModel | ResultViewModel> {
    const createUrl = `${api.BASE_URL}${api.PRODUCTS_ENDPOINT}`;
    try {
      const response = await axios.post(createUrl, products);
      return response.data as ProductResultViewModel;
    } catch (error) {
      return handleAxiosError(error);
    }
  },
  getlist: async function (
    page?: number,
    pageSize?: number,
    searchFields?: string,
    search?: string,
    filters?: string
  ): Promise<ProductTableResultViewModel | ResultViewModel> {
    let dataUrl = getDataUrl(
      api.BASE_URL,
      api.PRODUCTS_ENDPOINT,
      page,
      pageSize,
      searchFields,
      search
    );

    if (filters) {
      dataUrl += filters;
    }

    try {
      const response = await axios.get(dataUrl);
      return response.data as ProductTableResultViewModel;
    } catch (error) {
      return handleAxiosError(error);
    }
  },
  getById: async function (
    id: number
  ): Promise<ProductViewModel | ResultViewModel> {
    const dataUrl = `${api.BASE_URL}${api.PRODUCTS_ENDPOINT}/${id}`;
    try {
      const response = await axios.get(dataUrl);
      return response.data as ProductViewModel;
    } catch (error) {
      return handleAxiosError(error);
    }
  },
  getProductSelectOptions: async function (): Promise<
    ProductSelectOptionResultViewModel | ResultViewModel
  > {
    const dataUrl = `${api.BASE_URL}${api.PRODUCTS_ENDPOINT}/select-options`;
    try {
      const response = await axios.get(dataUrl);
      return response.data as ProductSelectOptionResultViewModel;
    } catch (error) {
      return handleAxiosError(error);
    }
  },
  update: async function (
    id: number,
    product: ProductViewModel
  ): Promise<ResultViewModel> {
    const updateUrl = `${api.BASE_URL}${api.PRODUCTS_ENDPOINT}/${id}`;
    try {
      const response = await axios.put(updateUrl, product);
      if (response.status === 204) {
        return {
          isSuccess: true,
          errorMessage: '',
        };
      }
    } catch (error) {
      return handleAxiosError(error);
    }
  },
  delete: async function (id: number): Promise<ResultViewModel> {
    const deleteURL = `${api.BASE_URL}${api.PRODUCTS_ENDPOINT}/${id}`;
    try {
      const response = await axios.delete(deleteURL);
      if (response.status === 204) {
        return {
          isSuccess: true,
          errorMessage: '',
        };
      }
    } catch (error) {
      return handleAxiosError(error);
    }
  },
};
