import { PlusIcon } from '@heroicons/react/20/solid';
import { format, addDays } from 'date-fns';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ProductCreate } from '../../Inventory/Product';
import { EstimateAndQuoteCreateCustomerSelect } from '../EstimateAndQuote/EstimateAndQuoteCreateCustomerSelect';

import { Box, BoxColumn, BoxRowColumn } from '@components/Box';
import { PrimaryButton } from '@components/Buttons/PrimaryButtons';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import {
  CancelButton,
  FormikDateInput,
  FormikSelect,
  FormikTextArea,
  SubmitButton,
} from '@components/Form';
import { CustomInput } from '@components/Form/CustomInput';
import { CustomSelect } from '@components/Form/CustomSelect';
import { FormikInput } from '@components/Form/FormikInput';
import { TrashIcon } from '@components/Icons';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import {
  TableContainer,
  TableDataCenter,
  TableDataLeft,
  TableDataRight,
  TableHeaderCenter,
  TableHeaderLeft,
  TableHeaderRight,
} from '@components/TableContainers';
import { routes } from '@config/routes';
import { FormikSelectOption } from '@models/common/FormikSelectOption';
import { ContactResultViewModel } from '@models/Contact';
import { CustomerResultViewModel } from '@models/Customer';
import { invoiceDefaultValue, invoiceValidation } from '@models/Invoice';
import {
  invoiceDetailDefaultValue,
  InvoiceDetailViewModel,
} from '@models/InvoiceDetail';
import {
  invoiceDetailSalesTaxDefaultValue,
  InvoiceDetailSalesTaxViewModel,
} from '@models/InvoiceDetailSalesTax';
import { SalesTaxViewModel } from '@models/SalesTax';
import { ContactService } from '@services/Contact';
import { CustomerService } from '@services/Customer';
import { InvoiceService } from '@services/Invoice';
import { ProductService } from '@services/Product';
import { SalesTaxService } from '@services/SalesTax';

export const InvoiceCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = location.state?.from || routes.RNC_INVOICES;

  const [customerContacts, setCustomerContacts] = useState<
    FormikSelectOption[]
  >([]);
  const [customerBillingAddresses, setCustomerBillingAddresses] = useState<
    FormikSelectOption[]
  >([]);
  const [customerShippingAddresses, setCustomerShippingAddresses] = useState<
    FormikSelectOption[]
  >([]);

  const [salesTaxes, setSalesTaxes] = useState<FormikSelectOption[]>([]);
  const [salesTaxEffectiveRates, setSalesTaxEffectiveRates] = useState<
    SalesTaxViewModel[]
  >([]);

  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [products, setProducts] = useState<FormikSelectOption[]>([]);

  const [invoiceDetails, setInvoiceDetails] = useState([
    {
      ...invoiceDetailDefaultValue,
      invoiceDetailSalesTaxes: [invoiceDetailSalesTaxDefaultValue],
    },
  ]);

  const [currentDate] = useState(() => {
    return format(new Date(), 'MM/dd/yyy');
  });
  const [validUntil] = useState(() => {
    const newDate = addDays(new Date(), 30);
    return format(newDate, 'MM/dd/yyyy');
  });

  const loadSelectedCustomer = (customerId: string, formikProps: any) => {
    if (customerId === '0') {
      formikProps.setFieldValue('contactID', 0);
      formikProps.setFieldValue('contactEmail', '');
      formikProps.setFieldValue('contactPhone', '');
      formikProps.setFieldValue('billingAddressID', 0);
      formikProps.setFieldValue('isSameShipping', false);
      formikProps.setFieldValue('shippingAddressID', 0);
      setCustomerContacts([{ value: '0', text: '' }]);
      setCustomerBillingAddresses([{ value: '0', text: '' }]);
      setCustomerShippingAddresses([{ value: '0', text: '' }]);
    } else {
      CustomerService.getById(Number(customerId))
        .then((response) => response.data as Promise<CustomerResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            formikProps.setFieldValue(
              'contactID',
              result.data.primaryContact?.id || 0
            );
            formikProps.setFieldValue(
              'contactEmail',
              result.data.primaryContact?.email || ''
            );
            formikProps.setFieldValue(
              'contactPhone',
              result.data.primaryContact?.phone || ''
            );
            formikProps.setFieldValue(
              'billingAddressID',
              result.data.billingID || 0
            );
            formikProps.setFieldValue(
              'isSameShipping',
              result.data.isSameShipping
            );
            formikProps.setFieldValue(
              'shippingAddressID',
              result.data.shippingID || 0
            );

            console.log('Fetched Data:', result.data);
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch customer details.');
          console.log(error);
        });

      CustomerService.getCustomerContactsSelectOptions(Number(customerId)).then(
        (result) => {
          const customerContactsSelectOptions = [
            { value: '0', text: '' },
          ].concat(
            result.data.map((row) => ({
              value: row.value,
              text: row.text,
            }))
          );

          setCustomerContacts(customerContactsSelectOptions);
        }
      );

      CustomerService.getCustomerBillingAddressesSelectOptions(
        Number(customerId)
      ).then((result) => {
        const customerBillingAddressesSelectOptions = [
          { value: '0', text: '' },
        ].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );

        setCustomerBillingAddresses(customerBillingAddressesSelectOptions);
      });

      CustomerService.getCustomerShippingAddressesSelectOptions(
        Number(customerId)
      ).then((result) => {
        const customerShippingAddressesSelectOptions = [
          { value: '0', text: '' },
        ].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );

        setCustomerShippingAddresses(customerShippingAddressesSelectOptions);
      });
    }
  };

  const loadSelectedContact = (contactId: string, formikProps: any) => {
    if (contactId === '0') {
      formikProps.setFieldValue('contactEmail', '');
      formikProps.setFieldValue('contactPhone', '');
    } else {
      ContactService.getById(Number(contactId))
        .then((response) => response.data as Promise<ContactResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            formikProps.setFieldValue('contactEmail', result.data.email || '');
            formikProps.setFieldValue('contactPhone', result.data.phone || '');
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch contact details.');
          console.log(error);
        });
    }
  };

  const loadProducts = () => {
    ProductService.getProductSelectOptions()
      .then((result) => {
        const productSelectOptions = [{ value: '0', text: '' }].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );
        setProducts(productSelectOptions);
      })
      .catch((error) => {
        alert('Error: Failed to handle the requst.');
        console.log(error);
      });
  };

  const loadEffectiveSalesTaxRates = (effectiveDate: Date) => {
    SalesTaxService.getSalesTaxEffectiveRates(effectiveDate).then((result) => {
      const salesTaxSelectOptions = [{ value: '0', text: '' }].concat(
        result.data.map((row) => ({
          value: row.id.toString(),
          text: row.abbreviation,
        }))
      );
      setSalesTaxes(salesTaxSelectOptions);
      setSalesTaxEffectiveRates(result.data);
    });
  };

  const loadSelectedProduct = (productId: string, index: number) => {
    const emptyInvoiceDetail: InvoiceDetailViewModel =
      invoiceDetailDefaultValue;
    if (productId === '0') {
      const updatedInvoiceDetails = [...invoiceDetails];
      updatedInvoiceDetails[index] = emptyInvoiceDetail;
      setInvoiceDetails(updatedInvoiceDetails);
    } else {
      ProductService.getById(Number(productId))
        .then((result) => {
          if (result.isSuccess) {
            const invoiceDetailSalesTaxes = result.data.productSalesTaxes
              .map((o) => {
                const salesTaxEffectiveRate = salesTaxEffectiveRates.find(
                  (i) => i.id == o.salesTaxId
                );
                return {
                  salesTaxID: o.salesTaxId,
                  salesTaxAbbreviation: o.salesTax.abbreviation,
                  rate: salesTaxEffectiveRate ? salesTaxEffectiveRate.rate : 0,
                  salesTaxAmount:
                    (Number(
                      salesTaxEffectiveRate ? salesTaxEffectiveRate.rate : 0
                    ) *
                      Number(result.data.price)) /
                    100,
                } as InvoiceDetailSalesTaxViewModel;
              })
              .concat([invoiceDetailSalesTaxDefaultValue]);

            const newInvoiceDetail: InvoiceDetailViewModel = {
              id: 0,
              invoiceID: 0,
              productID: result.data.id,
              product: result.data,
              description: result.data.description,
              quantity: 1,
              price: result.data.price,
              invoiceDetailSalesTaxes: invoiceDetailSalesTaxes,
              subTotal: result.data.price,
            };

            const updatedInvoiceDetails = [...invoiceDetails];
            updatedInvoiceDetails[index] = newInvoiceDetail;
            setInvoiceDetails(updatedInvoiceDetails);
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch product details.');
          console.log(error);
        });
    }
  };

  const handleUpdateInvoiceDetailQuantity = (
    index: number,
    quantity: number
  ) => {
    const updatedInvoiceDetails = [...invoiceDetails];
    const updatedInvoiceDetail = { ...updatedInvoiceDetails[index] };
    updatedInvoiceDetail.quantity = quantity;

    const salesTaxRate =
      updatedInvoiceDetail.invoiceDetailSalesTaxes.reduce((total, tax) => {
        return tax.salesTaxID === 0 ? total + Number(tax.rate) : total;
      }, 0) || 0;

    const computedSubTotal =
      Number(updatedInvoiceDetail.price) *
      Number(quantity) *
      (1 + salesTaxRate / 100);

    updatedInvoiceDetail.subTotal = computedSubTotal;
    updatedInvoiceDetails[index] = updatedInvoiceDetail;
    updatedInvoiceDetail.invoiceDetailSalesTaxes =
      updatedInvoiceDetail.invoiceDetailSalesTaxes.map((o) => {
        return {
          ...o,
          salesTaxAmount:
            (Number(o.rate) *
              Number(updatedInvoiceDetail.price) *
              Number(quantity)) /
            100,
        };
      });

    setInvoiceDetails(updatedInvoiceDetails);
  };

  const handleUpdateInvoiceDetailSalesTax = (
    invoiceDetailIndex: number,
    invoiceDetailSalesTaxIndex: number,
    invoiceDetailSalesTaxesLength: number,
    salesTaxID: number
  ) => {
    if (salesTaxID == 0) {
      if (invoiceDetailSalesTaxIndex < invoiceDetailSalesTaxesLength) {
        const updatedInvoiceDetailSalesTaxes = [
          ...invoiceDetails[invoiceDetailIndex].invoiceDetailSalesTaxes,
        ];

        updatedInvoiceDetailSalesTaxes.splice(invoiceDetailSalesTaxIndex, 1);

        const updatedInvoiceDetails = [...invoiceDetails];
        const updatedInvoiceDetail = {
          ...updatedInvoiceDetails[invoiceDetailIndex],
          invoiceDetailSalesTaxes: updatedInvoiceDetailSalesTaxes,
        };
        updatedInvoiceDetails[invoiceDetailIndex] = updatedInvoiceDetail;
        setInvoiceDetails(updatedInvoiceDetails);
      }
    } else {
      // Get the sales tax
      const salesTax = salesTaxEffectiveRates.find((o) => o.id == salesTaxID);

      // Get the invoice detail
      const updatedInvoiceDetails = [...invoiceDetails];
      const updatedInvoiceDetail = {
        ...updatedInvoiceDetails[invoiceDetailIndex],
      };

      // If sales tax already exist, do nothing
      if (
        updatedInvoiceDetail.invoiceDetailSalesTaxes.findIndex(
          (o) => o.salesTaxID == salesTax?.id
        ) != -1
      ) {
        updatedInvoiceDetail.invoiceDetailSalesTaxes[
          invoiceDetailSalesTaxIndex
        ] = invoiceDetailSalesTaxDefaultValue;

        updatedInvoiceDetails[invoiceDetailIndex] = updatedInvoiceDetail;

        setInvoiceDetails(updatedInvoiceDetails);

        return;
      }

      // Compute the sales tax amount
      const updatedInvoiceDetailSalesTax = {
        ...updatedInvoiceDetail.invoiceDetailSalesTaxes[
          invoiceDetailSalesTaxIndex
        ],
        salesTaxID: salesTax?.id ?? 0,
        salesTaxAbbreviation: salesTax?.abbreviation ?? '',
        rate: salesTax?.rate ?? 0,
        salesTaxAmount:
          (Number(salesTax?.rate ?? 0) *
            Number(invoiceDetails[invoiceDetailIndex].price) *
            Number(invoiceDetails[invoiceDetailIndex].quantity)) /
          100,
      };

      // Update the sales tax
      updatedInvoiceDetail.invoiceDetailSalesTaxes[invoiceDetailSalesTaxIndex] =
        updatedInvoiceDetailSalesTax;

      // Add new sales tax line if the last sales tax line is not empty
      if (
        updatedInvoiceDetail.invoiceDetailSalesTaxes[
          updatedInvoiceDetail.invoiceDetailSalesTaxes.length - 1
        ].salesTaxID > 0
      ) {
        updatedInvoiceDetail.invoiceDetailSalesTaxes = [
          ...updatedInvoiceDetail.invoiceDetailSalesTaxes,
          invoiceDetailSalesTaxDefaultValue,
        ];
      }

      updatedInvoiceDetails[invoiceDetailIndex] = updatedInvoiceDetail;

      setInvoiceDetails(updatedInvoiceDetails);
    }
  };

  const getSalesTaxesBySalesTaxID = () => {
    const salesTaxes = invoiceDetails.map((o) =>
      o.invoiceDetailSalesTaxes
        .filter((o) => o.salesTaxID != 0)
        .map((salesTaxes) => ({ ...salesTaxes, salesTaxRate: salesTaxes.rate }))
    );

    const groupedSalesTaxes = groupBy(
      salesTaxes.flat(),
      'salesTaxAbbreviation'
    ) as InvoiceDetailSalesTaxViewModel[][];

    return groupedSalesTaxes;
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const validateInvoiceDetails = () => {
    for (const detail of invoiceDetails) {
      if (!detail.productID) {
        return false;
      }
    }
    return true;
  };

  const handleAddInvoiceDetail = () => {
    if (!validateInvoiceDetails()) {
      alert('Please fill in the fields before adding a new product.');
      return;
    }

    const emptyInvoiceDetail = {
      ...invoiceDetailDefaultValue,
      invoiceDetailSalesTaxes: [invoiceDetailSalesTaxDefaultValue],
    };
    getSalesTaxesBySalesTaxID();
    setInvoiceDetails((prevInvoiceDetails) => [
      ...prevInvoiceDetails,
      emptyInvoiceDetail,
    ]);
  };

  const handleRemoveInvoiceDetail = (index: number) => {
    if (invoiceDetails.length <= 1) {
      console.log('At least one item must remain in the table.');
      return;
    }

    const updatedItems = [...invoiceDetails];
    updatedItems.splice(index, 1);
    setInvoiceDetails(updatedItems);
  };

  const handleResetInvoiceDetail = (index: number) => {
    const updatedItems = [...invoiceDetails];
    updatedItems[index] = {
      ...invoiceDetailDefaultValue,
      invoiceDetailSalesTaxes: [invoiceDetailSalesTaxDefaultValue],
    };
    setInvoiceDetails(updatedItems);
  };

  const handleUpdateInvoiceDetailSalesTaxRates = () => {
    const updatedInvoiceDetails = invoiceDetails.map((invoiceDetail) => {
      return {
        ...invoiceDetail,
        invoiceDetailSalesTaxes: invoiceDetail.invoiceDetailSalesTaxes.map(
          (invoiceDetailSalesTax) => {
            const salesTax = salesTaxEffectiveRates.find(
              (i) => i.id == invoiceDetailSalesTax.salesTaxID
            );
            return {
              ...invoiceDetailSalesTax,
              rate: salesTax?.rate ?? 0,
              salesTaxAmount:
                (Number(salesTax?.rate ?? 0) *
                  Number(invoiceDetail.price) *
                  Number(invoiceDetail.quantity)) /
                100,
            };
          }
        ),
      };
    });

    setInvoiceDetails(updatedInvoiceDetails);
  };

  useEffect(() => {
    handleUpdateInvoiceDetailSalesTaxRates();
  }, [salesTaxEffectiveRates]);

  useEffect(() => {
    loadProducts();
    loadEffectiveSalesTaxRates(new Date());
  }, []);

  return (
    <>
      <Box>
        <div className="pt-2 pb-6">
          <div className="pt-2 pb-6">
            <p className="w-full text-center text-2xl font-bold text-primary-900">
              Create Invoice
            </p>
          </div>
        </div>
        <Formik
          initialValues={{
            ...invoiceDefaultValue,
            date: currentDate,
            dueDate: validUntil,
          }}
          validationSchema={invoiceValidation}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values, actions) => {
            const formattedValues = {
              ...values,
              date: format(new Date(values.date), 'yyyy-MM-dd'),
              dueDate: format(new Date(values.dueDate), 'yyyy-MM-dd'),
            };

            formattedValues.invoiceDetails = invoiceDetails;

            InvoiceService.create(formattedValues)
              .then((response) => {
                if (response.status == 201) {
                  navigate(`${routes.RNC_INVOICES}`);
                } else {
                  console.log('Error: Failed to create a record.');
                  alert('Error: Failed to create a record.');
                }
              })
              .catch((error) => {
                alert('Error: Failed to handle the request.');
                console.log(error);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => {
            return (
              <>
                <form method="POST" onSubmit={formikProps.handleSubmit}>
                  <FormSectionContainer>
                    <BoxRowColumn>
                      <div className="lg:w-[73%]  sm:w-full md:-w-full">
                        <BoxColumn>
                          <EstimateAndQuoteCreateCustomerSelect
                            onCustomerSelectChange={(customerId: string) => {
                              loadSelectedCustomer(customerId, formikProps);
                            }}
                          />

                          <FormikInput
                            label="Customer Ref"
                            name="customerRef"
                          />

                          <FormikSelect
                            label="Contact Name"
                            name="contactID"
                            selection={customerContacts}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              loadSelectedContact(
                                event.target.value,
                                formikProps
                              );
                            }}
                          />

                          <BoxRowColumn>
                            <BoxColumn>
                              <FormikInput
                                label="Email"
                                name="contactEmail"
                                readonly={true}
                              />
                            </BoxColumn>

                            <BoxColumn>
                              <FormikInput
                                label="Phone"
                                name="contactPhone"
                                readonly={true}
                              />
                            </BoxColumn>
                          </BoxRowColumn>

                          <BoxRowColumn>
                            <BoxColumn>
                              <FormikDateInput
                                label="Date (mm/dd/yyyy)"
                                name="date"
                                type="date"
                                changeHandler={(value: string) => {
                                  loadEffectiveSalesTaxRates(new Date(value));
                                }}
                              />
                            </BoxColumn>

                            <BoxColumn>
                              <FormikDateInput
                                label="Due Date (mm/dd/yyyy)"
                                name="dueDate"
                                type="date"
                                onChange={(e: any) =>
                                  formikProps.setFieldValue(
                                    'dueDate',
                                    format(
                                      new Date(e.target.value),
                                      'MM/dd/yyyy'
                                    )
                                  )
                                }
                              />
                            </BoxColumn>
                          </BoxRowColumn>

                          <FormikSelect
                            label="Billing Address"
                            name="billingAddressID"
                            selection={customerBillingAddresses}
                          />

                          {!formikProps.values.isSameShipping ? (
                            <FormikSelect
                              label="Shipping Address"
                              name="shippingAddressID"
                              selection={customerShippingAddresses}
                            />
                          ) : (
                            <>
                              <label className="block text-sm font-medium text-primary-700">
                                Shipping Address
                              </label>
                              <i>Same as Billing Address</i>
                            </>
                          )}

                          <FormikTextArea label="Notes" name="notes" />
                        </BoxColumn>
                      </div>

                      <BoxColumn>
                        <div>
                          <div className="max-w-5x1 mx-auto">
                            <div className="pt-6">
                              <p className="w-full bg-primary-100 text-sm font-bold text-black py-1.5 text-center">
                                Details
                              </p>
                            </div>

                            <TableContainer>
                              <div className="bg-[#e7eeeb50]">
                                <thead>
                                  <tr>
                                    <TableHeaderLeft>
                                      <div className="pl-2">Item Name</div>
                                    </TableHeaderLeft>
                                    <TableHeaderLeft>
                                      Description
                                    </TableHeaderLeft>
                                    <TableHeaderCenter>
                                      Quantity
                                    </TableHeaderCenter>
                                    <TableHeaderCenter>Price</TableHeaderCenter>
                                    <TableHeaderCenter>Tax</TableHeaderCenter>
                                    <TableHeaderRight>
                                      SubTotal
                                    </TableHeaderRight>
                                    <TableHeaderRight>
                                      <div className="pr-2"></div>
                                    </TableHeaderRight>
                                  </tr>
                                </thead>
                                <tbody>
                                  {invoiceDetails.map(
                                    (invoiceDetail, invoiceDetailIndex) => (
                                      <tr
                                        key={invoiceDetailIndex}
                                        className={`${
                                          invoiceDetailIndex % 2 === 0
                                            ? ''
                                            : 'bg-[#56c39225]'
                                        }`}
                                      >
                                        <TableDataLeft>
                                          <div className="pl-2 flex gap-0">
                                            <CustomSelect
                                              label=""
                                              value={
                                                invoiceDetails[
                                                  invoiceDetailIndex
                                                ].productID
                                              }
                                              selection={products}
                                              onChange={(
                                                event: React.ChangeEvent<HTMLSelectElement>
                                              ) => {
                                                {
                                                  loadSelectedProduct(
                                                    event.target.value,
                                                    invoiceDetailIndex
                                                  );
                                                }
                                              }}
                                            />
                                            <button
                                              type="button"
                                              className="mt-1 relative -ml-px inline-flex items-center gap-x-1.5 px-2 py-1 text-sm font-semibold text-gray-900 bg-white border-l border-gray-500 hover:bg-gray-100"
                                              onClick={() =>
                                                setShowCreateProduct(true)
                                              }
                                            >
                                              <PlusIcon
                                                width={18}
                                                height={18}
                                              />
                                            </button>
                                          </div>
                                        </TableDataLeft>
                                        <TableDataLeft>
                                          <CustomInput
                                            readonly={true}
                                            label=""
                                            value={
                                              invoiceDetails[invoiceDetailIndex]
                                                .description
                                            }
                                          />
                                        </TableDataLeft>
                                        <TableDataCenter>
                                          <CustomInput
                                            label=""
                                            value={invoiceDetail.quantity}
                                            dataType="number"
                                            onChange={(e: any) => {
                                              handleUpdateInvoiceDetailQuantity(
                                                invoiceDetailIndex,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </TableDataCenter>
                                        <TableDataCenter>
                                          <CustomInput
                                            label=""
                                            value={Number(
                                              invoiceDetails[invoiceDetailIndex]
                                                .price
                                            ).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                            dataType="number"
                                            readonly={true}
                                          />
                                        </TableDataCenter>
                                        <div className="min-w-max">
                                          <TableDataCenter>
                                            {invoiceDetails[
                                              invoiceDetailIndex
                                            ].invoiceDetailSalesTaxes.map(
                                              (
                                                invoiceDetailSalesTax,
                                                invoiceDetailSalesTaxIndex
                                              ) => {
                                                return (
                                                  <CustomSelect
                                                    key={
                                                      invoiceDetailSalesTaxIndex
                                                    }
                                                    label=""
                                                    value={
                                                      invoiceDetails[
                                                        invoiceDetailIndex
                                                      ].invoiceDetailSalesTaxes[
                                                        invoiceDetailSalesTaxIndex
                                                      ].salesTaxID
                                                    }
                                                    selection={salesTaxes || []}
                                                    onChange={(e: any) => {
                                                      handleUpdateInvoiceDetailSalesTax(
                                                        invoiceDetailIndex,
                                                        invoiceDetailSalesTaxIndex,
                                                        invoiceDetail
                                                          .invoiceDetailSalesTaxes
                                                          .length,
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                );
                                              }
                                            )}
                                          </TableDataCenter>
                                        </div>
                                        <TableDataRight>
                                          <CustomInput
                                            label=""
                                            value={Number(
                                              invoiceDetails[invoiceDetailIndex]
                                                .subTotal
                                            ).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                            dataType="number"
                                            readonly={true}
                                          />
                                        </TableDataRight>
                                        <TableDataRight>
                                          <div className="pr-2">
                                            <button
                                              className="py-2"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleRemoveInvoiceDetail(
                                                  invoiceDetailIndex
                                                );
                                              }}
                                            >
                                              <div className="opacity-75 text-primary-500 hover:text-red-800">
                                                <TrashIcon
                                                  height="24"
                                                  width="24"
                                                />
                                              </div>
                                            </button>
                                          </div>
                                        </TableDataRight>
                                      </tr>
                                    )
                                  )}

                                  <tr>
                                    <TableDataLeft>
                                      <PrimaryButton
                                        onClick={handleAddInvoiceDetail}
                                      >
                                        Add Item
                                      </PrimaryButton>
                                    </TableDataLeft>
                                  </tr>

                                  <tr>
                                    <td colSpan={7}>
                                      <div className="p-4">
                                        <div className="flex text-sm justify-end">
                                          <div className="font-bold mr-4">
                                            Subtotal:
                                          </div>
                                          <div className="w-24 text-right">
                                            {invoiceDetails
                                              .reduce(
                                                (acc, cur) =>
                                                  acc + cur.subTotal,
                                                0
                                              )
                                              .toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                          </div>
                                        </div>

                                        <Space />

                                        {Object.entries(
                                          getSalesTaxesBySalesTaxID()
                                        ).map(([abbreviation, details]) => (
                                          <div
                                            className="flex text-sm justify-end"
                                            key={abbreviation}
                                          >
                                            <div className="mr-4">
                                              {abbreviation} ({details[0]?.rate}
                                              %)
                                            </div>
                                            <div className="w-24 text-right">
                                              {details
                                                .reduce(
                                                  (acc, cur) =>
                                                    acc + cur.salesTaxAmount,
                                                  0
                                                )
                                                .toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                            </div>
                                          </div>
                                        ))}

                                        <div className="flex text-sm justify-end">
                                          <div className="font-bold mr-4">
                                            Total Tax:
                                          </div>
                                          <div className="w-24 text-right">
                                            {invoiceDetails
                                              .map((o) =>
                                                o.invoiceDetailSalesTaxes.reduce(
                                                  (acc, cur) =>
                                                    acc + cur.salesTaxAmount,
                                                  0
                                                )
                                              )
                                              .reduce(
                                                (acc, cur) => acc + cur,
                                                0
                                              )
                                              .toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                          </div>
                                        </div>

                                        <Space />

                                        <div className="flex text-sm justify-end ">
                                          <div className="font-bold mr-4">
                                            Total:
                                          </div>
                                          <div className="w-24 text-right">
                                            {(
                                              invoiceDetails.reduce(
                                                (acc, cur) =>
                                                  acc + cur.subTotal,
                                                0
                                              ) +
                                              invoiceDetails
                                                .map((o) =>
                                                  o.invoiceDetailSalesTaxes.reduce(
                                                    (acc, cur) =>
                                                      acc + cur.salesTaxAmount,
                                                    0
                                                  )
                                                )
                                                .reduce(
                                                  (acc, cur) => acc + cur,
                                                  0
                                                )
                                            ).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </div>
                            </TableContainer>
                          </div>
                        </div>

                        <div className="flex items-end justify-end h-full">
                          <FormButtonsContainer>
                            <CancelButton
                              onClick={() => {
                                navigate(previousLocation);
                              }}
                            />

                            <SubmitButton
                              label="Save"
                              disabled={
                                formikProps.isSubmitting || !formikProps.isValid
                              }
                            />
                          </FormButtonsContainer>
                        </div>
                      </BoxColumn>
                    </BoxRowColumn>
                  </FormSectionContainer>
                </form>
              </>
            );
          }}
        </Formik>
      </Box>
      {showCreateProduct && (
        <>
          <Modal show={true}>
            <ProductCreate
              onClose={(productResultViewModel) => {
                setShowCreateProduct(false);

                if (productResultViewModel && productResultViewModel.data) {
                  const newProducts = [
                    ...products.slice(0, products.length - 1),
                    {
                      value: productResultViewModel.data.id.toString(),
                      text: productResultViewModel.data.name,
                    },
                  ];

                  setProducts(newProducts);

                  loadSelectedProduct(
                    productResultViewModel.data.id.toString(),
                    invoiceDetails.length - 1
                  );
                } else {
                  handleResetInvoiceDetail(invoiceDetails.length - 1);
                }
              }}
            />
          </Modal>
        </>
      )}
    </>
  );
};
