import { Formik } from 'formik';
import { useContext, useState, useEffect } from 'react';

import { CustomerCreateContext } from './EstimateAndQuoteCreateCustomerSelect';

import { CloseButton } from '@components/ActionButtons';
import {
  FormSectionContainer,
  FormButtonsContainer,
} from '@components/Container';
import { FormikInput, CancelButton, SubmitButton } from '@components/Form';
import { ActionableSelect } from '@components/Form/ActionableSelect';
import { Modal } from '@components/Modal';
import { FormikSelectOption } from '@models/common/FormikSelectOption';
import {
  ShippingAddressViewModel,
  shippingAddressDefaultValue,
  shippingAddressValidation,
} from '@models/ShippingAddress';

export const EstimateAndQuoteCustomerCreateShippingAddressSelect = () => {
  const { customer, setCustomer } = useContext(CustomerCreateContext);
  const [latestShippingAddressUiid, setLatestShippingAddressUiid] =
    useState<number>(0);
  const customerShippingAddresses = customer.shippingAddresses || [];
  const [
    customerShippingAddressSelectOptions,
    setCustomerShippingAddressSelectOptions,
  ] = useState<FormikSelectOption[]>([]);
  const [isShowAddShippingAddress, setIsShowAddShippingAddress] =
    useState(false);
  const [isShowEditShippingAddress, setIsShowEditShippingAddress] =
    useState(false);
  const [shippingAddressToEdit, setShippingAddressToEdit] =
    useState<ShippingAddressViewModel | null>();

  const loadShippingAddressOptions = () => {
    const customerShippingAddressSelection = [{ value: '0', text: '' }].concat(
      customerShippingAddresses.map((row) => ({
        value: row.uiid.toString(),
        text: `${row.address1.substring(0, 50)}...`,
      }))
    );
    setCustomerShippingAddressSelectOptions(customerShippingAddressSelection);
  };

  useEffect(() => {
    loadShippingAddressOptions();
  }, [customerShippingAddresses]);

  return (
    <>
      <ActionableSelect
        label="Shipping Address"
        value={customer.shippingUiid?.toString()}
        selection={customerShippingAddressSelectOptions}
        onAddAction={() => {
          setIsShowAddShippingAddress(true);
        }}
        onEditAction={() => {
          if (customer.shippingUiid == undefined || customer.shippingUiid == 0)
            return;

          const selectedShippingAddress = customerShippingAddresses.find(
            (row) => row.uiid == customer.shippingUiid
          );

          if (selectedShippingAddress == undefined) return;

          setShippingAddressToEdit(selectedShippingAddress);
          setIsShowEditShippingAddress(true);
        }}
        onDeleteAction={() => {
          const updatedShippingAddresses = customerShippingAddresses.filter(
            (o) => o.uiid !== customer.shippingUiid
          );
          setCustomer({
            ...customer,
            shippingUiid: 0,
            shippingAddresses: updatedShippingAddresses,
          });
        }}
        onDuplicateAction={() => {
          const selectedShippingAddress = customerShippingAddresses.find(
            (row) => row.uiid == customer.shippingUiid
          );

          if (selectedShippingAddress == undefined) return;

          const newShippingAddressUiid = latestShippingAddressUiid + 1;
          const newShippingAddress = {
            ...selectedShippingAddress,
            uiid: newShippingAddressUiid,
          };

          const updatedShippingAddresses =
            customerShippingAddresses.concat(newShippingAddress);
          setLatestShippingAddressUiid(newShippingAddressUiid);
          setCustomer({
            ...customer,
            shippingUiid: newShippingAddressUiid,
            shippingAddresses: updatedShippingAddresses,
          });

          setShippingAddressToEdit(newShippingAddress);
          setIsShowEditShippingAddress(true);
        }}
        onChange={(e) => {
          const updateCustomer = {
            ...customer,
            shippingUiid: Number(e.target.value),
          };
          setCustomer(updateCustomer);
        }}
      />

      {isShowAddShippingAddress && (
        <>
          <Modal show={true}>
            <CloseButton onClick={() => setIsShowAddShippingAddress(false)} />

            <Formik
              initialValues={shippingAddressDefaultValue}
              validationSchema={shippingAddressValidation}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, actions) => {
                const newShippingAddressUiid = latestShippingAddressUiid + 1;
                values.uiid = newShippingAddressUiid;

                const updatedShippingAddresses =
                  customerShippingAddresses.concat(values);
                setLatestShippingAddressUiid(newShippingAddressUiid);
                setCustomer({
                  ...customer,
                  shippingUiid: newShippingAddressUiid,
                  shippingAddresses: updatedShippingAddresses,
                });

                actions.resetForm();
                setIsShowAddShippingAddress(false);
              }}
            >
              {(formikProps) => {
                return (
                  <>
                    <form method="POST" onSubmit={formikProps.handleSubmit}>
                      <div className="mx-2 lg:px-6 md:px-4 px-4 space-y-6 pb-4">
                        <FormikInput
                          label="Currency Code"
                          name="currencyCode"
                        />
                        <FormikInput label="Address 1" name="address1" />
                        <FormikInput label="Address 2" name="address2" />
                        <FormikInput label="City" name="city" />
                        <FormikInput label="State / Province" name="state" />
                        <FormikInput label="Country" name="country" />
                        <FormikInput label="Postal Code" name="postalCode" />
                      </div>
                      <FormButtonsContainer>
                        <CancelButton
                          onClick={() => {
                            setIsShowAddShippingAddress(false);
                            formikProps.resetForm();
                          }}
                        />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </FormButtonsContainer>
                    </form>
                  </>
                );
              }}
            </Formik>
          </Modal>
        </>
      )}

      {isShowEditShippingAddress && shippingAddressToEdit && (
        <>
          <Modal show={true}>
            <CloseButton onClick={() => setIsShowEditShippingAddress(false)} />

            <Formik
              initialValues={shippingAddressToEdit}
              validationSchema={shippingAddressValidation}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, actions) => {
                const updatedShippingAddresses = customerShippingAddresses.map(
                  (row) => (row.uiid === values.uiid ? values : row)
                );
                setCustomer({
                  ...customer,
                  shippingID: values.uiid,
                  shippingAddresses: updatedShippingAddresses,
                });

                actions.resetForm();
                setIsShowEditShippingAddress(false);
              }}
            >
              {(formikProps) => {
                return (
                  <>
                    <form method="POST" onSubmit={formikProps.handleSubmit}>
                      <FormSectionContainer>
                        <FormikInput
                          label="Currency Code"
                          name="currencyCode"
                        />
                        <FormikInput label="Address 1" name="address1" />
                        <FormikInput label="Address 2" name="address2" />
                        <FormikInput label="City" name="city" />
                        <FormikInput label="State / Province" name="state" />
                        <FormikInput label="Country" name="country" />
                        <FormikInput label="Postal Code" name="postalCode" />
                      </FormSectionContainer>
                      <FormButtonsContainer>
                        <CancelButton
                          onClick={() => {
                            setIsShowEditShippingAddress(false);
                            formikProps.resetForm();
                          }}
                        />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </FormButtonsContainer>
                    </form>
                  </>
                );
              }}
            </Formik>
          </Modal>
        </>
      )}
    </>
  );
};
