import { useField, FieldHookConfig } from 'formik';
import tw from 'tailwind-styled-components';

interface BaseInputProps {
  hasError: boolean;
}

const BaseInput = tw.input<BaseInputProps>`
block w-full shadow-sm bg-white lg:text-sm md:text-xs text-xs
${(props) =>
  !props.hasError &&
  !props.value &&
  'border-primary-300 focus:border-primary-500 focus:ring-primary-500'} 
${(props) =>
  !props.hasError &&
  !!props.value &&
  'border-primary-300 focus:border-primary-500 focus:ring-primary-500 bg-green-50'} 
${(props) =>
  props.hasError &&
  !props.value &&
  'border-primary-300 focus:border-primary-500 focus:ring-primary-500'} 
${(props) =>
  props.hasError &&
  !!props.value &&
  'border-red-300 focus:border-red-500 focus:ring-red-500 bg-red-100 '} 
`;

type Props = {
  label?: string;
  readonly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & FieldHookConfig<string>;

export const FormikInput: React.FC<Props> = ({
  label,
  readonly,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full">
      <label className="block lg:text-sm md:text-sm text-xs font-medium text-primary-700">
        {label}
      </label>
      <div className="mt-1">
        <BaseInput
          {...field}
          readOnly={readonly}
          type="text"
          onChange={(e) => {
            field.onChange(e);
            onChange && onChange(e);
          }}
          hasError={!!meta.error}
        />
        {meta.error ? (
          <div className="text-red-500 lg:text-sm md:text-sm text-xs">
            {meta.error}
          </div>
        ) : null}
      </div>
    </div>
  );
};
