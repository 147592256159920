import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';

import { Layout } from './pages/Layout/Layout';

import { JwtPayloadExtended } from '@models/auth/JwtPayloadExtended';
import { useAppDispatch } from 'src/store/hooks';
import { setUser } from 'src/store/slices/userSlice';

const App = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      const jwtPayload = jwtDecode<JwtPayloadExtended>(auth.user.access_token);

      dispatch(
        setUser({
          username: jwtPayload.name,
          family_name: jwtPayload.family_name,
          given_name: jwtPayload.given_name,
          email: jwtPayload.email,
          currentCompany: {
            name: jwtPayload.company_name,
            id: jwtPayload.company_id,
            tenantId: jwtPayload.tenant_id,
            tenantName: jwtPayload.tenant_name,
          },
        })
      );
    }
  }, [auth.isAuthenticated, auth.user, dispatch]);

  axios.interceptors.request.use((config) => {
    if (auth.user) {
      config.headers.Authorization = `Bearer ${auth.user.access_token}`;
    }

    return config;
  });

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  } else {
    auth.signinRedirect();
    return <div>Redirecting...</div>;
  }
};

export default App;
