interface Props {
  children?: React.ReactNode;
}

export const CallToActionItems: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className="lg:p-6 md:p-6 p-4 flex justify-between items-start">
        {children}
      </div>
    </>
  );
};
