import axios from 'axios';

import { ResultViewModel } from '@models/common/ResultViewModel';

export function handleAxiosError(error: unknown): ResultViewModel {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      console.error('Error response:', error.response);
      console.log('Error response data:', error.response.data);
      return error.response.data as ResultViewModel;
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
  } else {
    console.error('Error:', error);
  }
  return {
    isSuccess: false,
    message: 'An error occurred while processing the request.',
  } as ResultViewModel;
}
