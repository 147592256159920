import { Formik } from 'formik';
import { useContext, useState, useEffect } from 'react';

import { CustomerCreateContext } from './EstimateAndQuoteCreateCustomerSelect';

import { CloseButton } from '@components/ActionButtons';
import {
  FormSectionContainer,
  FormButtonsContainer,
} from '@components/Container';
import { FormikInput, CancelButton, SubmitButton } from '@components/Form';
import { ActionableSelect } from '@components/Form/ActionableSelect';
import { Modal } from '@components/Modal';
import {
  BillingAddressViewModel,
  billingAddressDefaultValue,
  billingAddressValidation,
} from '@models/BillingAddress';
import { FormikSelectOption } from '@models/common/FormikSelectOption';

export const EstimateAndQuoteCustomerCreateBillingAddressSelect = () => {
  const { customer, setCustomer } = useContext(CustomerCreateContext);
  const [latestBillingAddressUiid, setLatestBillingAddressUiid] =
    useState<number>(0);
  const customerBillingAddresses = customer.billingAddresses || [];
  const [
    customerBillingAddressSelectOptions,
    setCustomerBillingAddressSelectOptions,
  ] = useState<FormikSelectOption[]>([]);
  const [isShowAddBillingAddress, setIsShowAddBillingAddress] = useState(false);
  const [isShowEditBillingAddress, setIsShowEditBillingAddress] =
    useState(false);
  const [billingAddressToEdit, setBillingAddressToEdit] =
    useState<BillingAddressViewModel | null>();

  const loadBillingAddressOptions = () => {
    const customerBillingAddressSelection = [{ value: '0', text: '' }].concat(
      customerBillingAddresses.map((row) => ({
        value: row.uiid.toString(),
        text: `${row.address1.substring(0, 50)}...`,
      }))
    );
    setCustomerBillingAddressSelectOptions(customerBillingAddressSelection);
  };

  useEffect(() => {
    loadBillingAddressOptions();
  }, [customerBillingAddresses]);

  console.log(customer);

  return (
    <>
      <ActionableSelect
        label="Billing Address"
        value={customer.billingUiid?.toString()}
        selection={customerBillingAddressSelectOptions}
        onAddAction={() => {
          setIsShowAddBillingAddress(true);
        }}
        onEditAction={() => {
          if (customer.billingUiid == undefined || customer.billingUiid == 0)
            return;

          const selectedBillingAddress = customerBillingAddresses.find(
            (row) => row.uiid == customer.billingUiid
          );

          if (selectedBillingAddress == undefined) return;

          setBillingAddressToEdit(selectedBillingAddress);
          setIsShowEditBillingAddress(true);
        }}
        onDeleteAction={() => {
          const updatedBillingAddresses = customerBillingAddresses.filter(
            (o) => o.uiid !== customer.billingUiid
          );
          setCustomer({
            ...customer,
            billingUiid: 0,
            billingAddresses: updatedBillingAddresses,
          });
        }}
        onDuplicateAction={() => {
          const selectedBillingAddress = customerBillingAddresses.find(
            (row) => row.uiid == customer.billingUiid
          );

          if (selectedBillingAddress == undefined) return;

          const newBillingAddressUiid = latestBillingAddressUiid + 1;
          const newBillingAddress = {
            ...selectedBillingAddress,
            uiid: newBillingAddressUiid,
          };

          const updatedBillingAddresses =
            customerBillingAddresses.concat(newBillingAddress);
          setLatestBillingAddressUiid(newBillingAddressUiid);
          setCustomer({
            ...customer,
            billingUiid: newBillingAddressUiid,
            billingAddresses: updatedBillingAddresses,
          });

          setBillingAddressToEdit(newBillingAddress);
          setIsShowEditBillingAddress(true);
        }}
        onChange={(e) => {
          const updateCustomer = {
            ...customer,
            billingUiid: Number(e.target.value),
          };
          setCustomer(updateCustomer);
        }}
      />

      {isShowAddBillingAddress && (
        <>
          <Modal show={true}>
            <CloseButton onClick={() => setIsShowAddBillingAddress(false)} />

            <Formik
              initialValues={billingAddressDefaultValue}
              validationSchema={billingAddressValidation}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, actions) => {
                const newBillingAddressUiid = latestBillingAddressUiid + 1;
                values.uiid = newBillingAddressUiid;

                const updatedBillingAddresses =
                  customerBillingAddresses.concat(values);
                setLatestBillingAddressUiid(newBillingAddressUiid);
                setCustomer({
                  ...customer,
                  billingUiid: newBillingAddressUiid,
                  billingAddresses: updatedBillingAddresses,
                });

                actions.resetForm();
                setIsShowAddBillingAddress(false);
              }}
            >
              {(formikProps) => {
                return (
                  <>
                    <form method="POST" onSubmit={formikProps.handleSubmit}>
                      <div className="mx-2 lg:px-6 md:px-4 px-4 space-y-6 pb-4">
                        <FormikInput
                          label="Currency Code"
                          name="currencyCode"
                        />
                        <FormikInput label="Address 1" name="address1" />
                        <FormikInput label="Address 2" name="address2" />
                        <FormikInput label="City" name="city" />
                        <FormikInput label="State / Province" name="state" />
                        <FormikInput label="Country" name="country" />
                        <FormikInput label="Postal Code" name="postalCode" />
                      </div>
                      <FormButtonsContainer>
                        <CancelButton
                          onClick={() => {
                            setIsShowAddBillingAddress(false);
                            formikProps.resetForm();
                          }}
                        />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </FormButtonsContainer>
                    </form>
                  </>
                );
              }}
            </Formik>
          </Modal>
        </>
      )}

      {isShowEditBillingAddress && billingAddressToEdit && (
        <>
          <Modal show={true}>
            <CloseButton onClick={() => setIsShowEditBillingAddress(false)} />

            <Formik
              initialValues={billingAddressToEdit}
              validationSchema={billingAddressValidation}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, actions) => {
                const updatedBillingAddresses = customerBillingAddresses.map(
                  (row) => (row.uiid === values.uiid ? values : row)
                );
                setCustomer({
                  ...customer,
                  billingUiid: values.uiid,
                  billingAddresses: updatedBillingAddresses,
                });

                actions.resetForm();
                setIsShowEditBillingAddress(false);
              }}
            >
              {(formikProps) => {
                return (
                  <>
                    <form method="POST" onSubmit={formikProps.handleSubmit}>
                      <FormSectionContainer>
                        <FormikInput
                          label="Currency Code"
                          name="currencyCode"
                        />
                        <FormikInput label="Address 1" name="address1" />
                        <FormikInput label="Address 2" name="address2" />
                        <FormikInput label="City" name="city" />
                        <FormikInput label="State / Province" name="state" />
                        <FormikInput label="Country" name="country" />
                        <FormikInput label="Postal Code" name="postalCode" />
                      </FormSectionContainer>
                      <FormButtonsContainer>
                        <CancelButton
                          onClick={() => {
                            setIsShowEditBillingAddress(false);
                            formikProps.resetForm();
                          }}
                        />

                        <SubmitButton
                          label="Save"
                          disabled={
                            formikProps.isSubmitting || !formikProps.isValid
                          }
                        />
                      </FormButtonsContainer>
                    </form>
                  </>
                );
              }}
            </Formik>
          </Modal>
        </>
      )}
    </>
  );
};
