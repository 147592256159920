interface IProps {
  children: React.ReactNode;
}

export const BoxHeaderTitle: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <div className="flex gap-0 items-end">
        <div className="max-w-max pl-2 pr-4 lg:py-4 md:py-4 py-2.5 bg-white lg:rounded-tl-[20px] md:rounded-tl-[15px] rounded-tl-md rounded-tr-[50px]">
          {children}
        </div>

        <div className="relative -ml-[1.6px] bg-white max-w-max">
          <div className="w-8 h-8 rounded-bl-full bg-gray-100 lg:block md:block hidden" />
        </div>
      </div>
    </>
  );
};
