interface Props {
  onClick?: () => void;
}

export const CancelButton: React.FC<Props> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="rounded-md border border-gray-300 bg-white py-2 px-4 lg:text-sm md:text-xs text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50"
    >
      Cancel
    </button>
  );
};
