import { PlusIcon } from '@heroicons/react/20/solid';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ProductCreate } from '../../Inventory/Product';

import { EstimateAndQuoteCreateCustomerSelectEdit } from './EstimateAndQuoteCreateCustomerSelectEdit';

import { Box, BoxColumn, BoxRowColumn } from '@components/Box';
import { PrimaryButton } from '@components/Buttons/PrimaryButtons';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import {
  CancelButton,
  FormikInput,
  FormikSelect,
  FormikTextArea,
  SubmitButton,
} from '@components/Form';
import { CustomInput } from '@components/Form/CustomInput';
import { CustomSelect } from '@components/Form/CustomSelect';
import { FormikDateInput } from '@components/Form/FormikDateInput';
import { TrashIcon } from '@components/Icons';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import {
  TableContainer,
  TableDataCenter,
  TableDataLeft,
  TableDataRight,
  TableHeaderCenter,
  TableHeaderLeft,
  TableHeaderRight,
} from '@components/TableContainers';
import { routes } from '@config/routes';
import { FormikSelectOption } from '@models/common/FormikSelectOption';
import { ContactResultViewModel } from '@models/Contact';
import { CustomerResultViewModel } from '@models/Customer';
import {
  EstimateAndQuoteResultViewModel,
  EstimateAndQuoteViewModel,
  estimateAndQuoteDefaultValue,
  estimateAndQuoteValidation,
} from '@models/EstimateAndQuote';
import {
  EstimateAndQuoteDetailViewModel,
  estimateAndQuoteDetailDefaultValue,
} from '@models/EstimateAndQuoteDetail';
import {
  EstimateAndQuoteDetailSalesTaxViewModel,
  estimateAndQuoteDetailSalesTaxDefaultValue,
} from '@models/EstimateAndQuoteDetailSalesTax';
import { SalesTaxViewModel } from '@models/SalesTax';
import { ContactService } from '@services/Contact';
import { CustomerService } from '@services/Customer';
import { EstimateAndQuoteService } from '@services/EstimateAndQuote';
import { ProductService } from '@services/Product';
import { SalesTaxService } from '@services/SalesTax';
import { formatAsCurrency } from '@utils/numberFormat';

export const EstimateAndQuoteEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = location.state?.from || routes.RNC_ESTIMATES;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [isEstimateLoading, setEstimateIsLoading] = useState(true);
  const [estimate, setEstimate] = useState<EstimateAndQuoteViewModel>(
    estimateAndQuoteDefaultValue
  );

  const [isEstimateDetailsLoading, setEstimateDetailsIsLoading] =
    useState(true);
  const [estimateDetails, setEstimateDetails] = useState<
    EstimateAndQuoteDetailViewModel[]
  >([]);

  const [isCustomerContactDetailsLoading, setCustomerContactDetailsIsLoading] =
    useState<boolean>(true);
  const [isCustomerContactsLoading, setCustomerContactsIsLoading] =
    useState<boolean>(true);
  const [customerContacts, setCustomerContacts] = useState<
    FormikSelectOption[]
  >([]);

  const [
    isCustomerBillingAddressesLoading,
    setCustomerBillingAddressesIsLoading,
  ] = useState<boolean>(true);
  const [customerBillingAddresses, setCustomerBillingAddresses] = useState<
    FormikSelectOption[]
  >([]);

  const [
    isCustomerShippingAddressesLoading,
    setCustomerShippingAddressesIsLoading,
  ] = useState<boolean>(true);
  const [customerShippingAddresses, setCustomerShippingAddresses] = useState<
    FormikSelectOption[]
  >([]);

  const [isSalesTaxEffectiveRatesLoading, setSalesTaxEffectiveRatesIsLoading] =
    useState<boolean>(true);
  const [salesTaxes, setSalesTaxes] = useState<FormikSelectOption[]>([]);
  const [salesTaxEffectiveRates, setSalesTaxEffectiveRates] = useState<
    SalesTaxViewModel[]
  >([]);

  const [isProductsLoading, setProductsIsLoading] = useState(true);
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [products, setProducts] = useState<FormikSelectOption[]>([]);

  const loadEstimate = () => {
    EstimateAndQuoteService.getById(Number(id))
      .then(
        (response) => response.data as Promise<EstimateAndQuoteResultViewModel>
      )
      .then((result) => {
        if (result.isSuccess) {
          setEstimate(result.data);
          setEstimateIsLoading(false);
          console.log('Success: Fetched the record.');
        } else {
          console.log(`Error: Failed to get record. ${result.errorMessage}`);
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle the request.');
        console.log(error);
      });
  };

  const loadEstimateCustomerContacts = (customerId: number) => {
    if (customerId === 0) {
      setCustomerContacts([{ value: '0', text: '' }]);
    } else {
      CustomerService.getCustomerContactsSelectOptions(customerId).then(
        (result) => {
          const customerContactsSelectOptions = [
            { value: '0', text: '' },
          ].concat(
            result.data.map((row) => ({
              value: row.value,
              text: row.text,
            }))
          );

          setCustomerContacts(customerContactsSelectOptions);
          setCustomerContactsIsLoading(false);
        }
      );
    }
  };

  const loadEstimateCustomerContactDetails = (contactId: number) => {
    if (contactId === 0) {
      setEstimate({
        ...estimate,
        contactEmail: '',
        contactPhone: '',
      });
    } else {
      ContactService.getById(contactId)
        .then((response) => response.data as Promise<ContactResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            setEstimate({
              ...estimate,
              contactEmail: result.data.email ?? '',
              contactPhone: result.data.phone ?? '',
            });
            setCustomerContactDetailsIsLoading(false);
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch contact details.');
          console.log(error);
        });
    }
  };

  const loadEstimateCustomerBillingAddresses = (customerId: number) => {
    if (customerId === 0) {
      setCustomerBillingAddresses([{ value: '0', text: '' }]);
    } else {
      CustomerService.getCustomerBillingAddressesSelectOptions(customerId).then(
        (result) => {
          const customerBillingAddressesSelectOptions = [
            { value: '0', text: '' },
          ].concat(
            result.data.map((row) => ({
              value: row.value,
              text: row.text,
            }))
          );

          setCustomerBillingAddresses(customerBillingAddressesSelectOptions);
          setCustomerBillingAddressesIsLoading(false);
        }
      );
    }
  };

  const loadEstimateCustomerShippingAddresses = (customerId: number) => {
    if (customerId === 0) {
      setCustomerShippingAddresses([{ value: '0', text: '' }]);
    } else {
      CustomerService.getCustomerShippingAddressesSelectOptions(
        customerId
      ).then((result) => {
        const customerShippingAddressesSelectOptions = [
          { value: '0', text: '' },
        ].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );

        setCustomerShippingAddresses(customerShippingAddressesSelectOptions);
        setCustomerShippingAddressesIsLoading(false);
      });
    }
  };

  const loadEstimateDetails = (estimateId: number) => {
    if (estimateId === 0) {
      setEstimateDetails([]);
    } else {
      EstimateAndQuoteService.getEstimateDetails(Number(id)).then((result) => {
        const estimateDetails = result.data.map((row) => ({
          ...row,
          estimateDetailSalesTaxes: row.estimateDetailSalesTaxes.concat([
            estimateAndQuoteDetailSalesTaxDefaultValue,
          ]),
        }));
        setEstimateDetails(estimateDetails);
        setEstimateDetailsIsLoading(false);
      });
    }
  };

  const loadSelectedCustomer = (customerId: string, formikProps: any) => {
    if (customerId === '0') {
      formikProps.setFieldValue('contactID', 0);
      formikProps.setFieldValue('contactEmail', '');
      formikProps.setFieldValue('contactPhone', '');
      formikProps.setFieldValue('billingAddressID', 0);
      formikProps.setFieldValue('isSameShipping', false);
      formikProps.setFieldValue('shippingAddressID', 0);
      setCustomerContacts([{ value: '0', text: '' }]);
      setCustomerBillingAddresses([{ value: '0', text: '' }]);
      setCustomerShippingAddresses([{ value: '0', text: '' }]);
    } else {
      CustomerService.getById(Number(customerId))
        .then((response) => response.data as Promise<CustomerResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            formikProps.setFieldValue(
              'contactID',
              result.data.primaryContact?.id || 0
            );
            formikProps.setFieldValue(
              'contactEmail',
              result.data.primaryContact?.email || ''
            );
            formikProps.setFieldValue(
              'contactPhone',
              result.data.primaryContact?.phone || ''
            );
            formikProps.setFieldValue(
              'billingAddressID',
              result.data.billingID || 0
            );
            formikProps.setFieldValue(
              'isSameShipping',
              result.data.isSameShipping
            );
            formikProps.setFieldValue(
              'shippingAddressID',
              result.data.shippingID || 0
            );
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch customer details.');
          console.log(error);
        });

      CustomerService.getCustomerContactsSelectOptions(Number(customerId)).then(
        (result) => {
          const customerContactsSelectOptions = [
            { value: '0', text: '' },
          ].concat(
            result.data.map((row) => ({
              value: row.value,
              text: row.text,
            }))
          );

          setCustomerContacts(customerContactsSelectOptions);
        }
      );

      CustomerService.getCustomerBillingAddressesSelectOptions(
        Number(customerId)
      ).then((result) => {
        const customerBillingAddressesSelectOptions = [
          { value: '0', text: '' },
        ].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );

        setCustomerBillingAddresses(customerBillingAddressesSelectOptions);
      });

      CustomerService.getCustomerShippingAddressesSelectOptions(
        Number(customerId)
      ).then((result) => {
        const customerShippingAddressesSelectOptions = [
          { value: '0', text: '' },
        ].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );

        setCustomerShippingAddresses(customerShippingAddressesSelectOptions);
      });
    }
  };

  const loadSelectedContact = (contactId: string, formikProps: any) => {
    if (contactId === '0') {
      formikProps.setFieldValue('contactEmail', '');
      formikProps.setFieldValue('contactPhone', '');
    } else {
      ContactService.getById(Number(contactId))
        .then((response) => response.data as Promise<ContactResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            formikProps.setFieldValue('contactEmail', result.data.email || '');
            formikProps.setFieldValue('contactPhone', result.data.phone || '');
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch contact details.');
          console.log(error);
        });
    }
  };

  const loadProducts = () => {
    ProductService.getProductSelectOptions()
      .then((result) => {
        const productsSelectOptions = [{ value: '0', text: '' }].concat(
          result.data.map((row) => ({
            value: row.value,
            text: row.text,
          }))
        );
        setProducts(productsSelectOptions);
        setProductsIsLoading(false);
      })
      .catch((error) => {
        alert('Error: Faild to handle the request.');
        console.log(error);
      });
  };

  const loadEffectiveSalesTaxRates = (effectiveDate: Date) => {
    SalesTaxService.getSalesTaxEffectiveRates(effectiveDate).then((result) => {
      const salesTaxSelectOptions = [{ value: '0', text: '' }].concat(
        result.data.map((row) => ({
          value: row.id.toString(),
          text: row.abbreviation,
        }))
      );
      setSalesTaxes(salesTaxSelectOptions);
      setSalesTaxEffectiveRates(result.data);
      setSalesTaxEffectiveRatesIsLoading(false);
    });
  };

  const loadSelectedProduct = (productId: string, index: number) => {
    const emptyEstimateDetail: EstimateAndQuoteDetailViewModel =
      estimateAndQuoteDetailDefaultValue;
    if (productId === '0') {
      const updatedEstimateDetails = [...estimateDetails];
      updatedEstimateDetails[index] = emptyEstimateDetail;
      setEstimateDetails(updatedEstimateDetails);
    } else {
      ProductService.getById(Number(productId))
        .then((result) => {
          if (result.isSuccess) {
            const estimateDetailSalesTaxes = result.data.productSalesTaxes
              .map((o) => {
                const salesTaxEffectiveRate = salesTaxEffectiveRates.find(
                  (i) => i.id == o.salesTaxId
                );
                return {
                  salesTaxID: o.salesTaxId,
                  salesTaxAbbreviation: o.salesTax.abbreviation,
                  rate: salesTaxEffectiveRate ? salesTaxEffectiveRate.rate : 0,
                  salesTaxAmount:
                    (Number(
                      salesTaxEffectiveRate ? salesTaxEffectiveRate.rate : 0
                    ) *
                      Number(result.data.price)) /
                    100,
                } as EstimateAndQuoteDetailSalesTaxViewModel;
              })
              .concat([estimateAndQuoteDetailSalesTaxDefaultValue]);

            const newEstimateDetail: EstimateAndQuoteDetailViewModel = {
              id: 0,
              estimateID: 0,
              productID: result.data.id,
              product: result.data,
              description: result.data.description,
              quantity: 1,
              price: result.data.price,
              estimateDetailSalesTaxes: estimateDetailSalesTaxes,
              subTotal: result.data.price,
            };

            const updatedEstimateDetails = [...estimateDetails];
            updatedEstimateDetails[index] = newEstimateDetail;
            setEstimateDetails(updatedEstimateDetails);
          } else {
            console.log(`Error: Failed to get record. ${result.errorMessage}`);
          }
        })
        .catch((error) => {
          alert('Error: Failed to fetch product details.');
          console.log(error);
          console.log('Contact fetch error:', error);
        });
    }
  };

  const handleUpdateEstimateDetailQuantity = (
    index: number,
    quantity: number
  ) => {
    const updatedEstimateDetails = [...estimateDetails];
    const updatedEstimateDetail = { ...updatedEstimateDetails[index] };
    updatedEstimateDetail.quantity = quantity;

    const salesTaxRate =
      updatedEstimateDetail.estimateDetailSalesTaxes.reduce((total, tax) => {
        return tax.salesTaxID === 0 ? total + Number(tax.rate) : total;
      }, 0) || 0;

    const computedSubTotal =
      Number(updatedEstimateDetail.price) *
      Number(quantity) *
      (1 + salesTaxRate / 100);

    updatedEstimateDetail.subTotal = computedSubTotal;
    updatedEstimateDetails[index] = updatedEstimateDetail;
    updatedEstimateDetail.estimateDetailSalesTaxes =
      updatedEstimateDetail.estimateDetailSalesTaxes.map((o) => {
        return {
          ...o,
          salesTaxAmount:
            (Number(o.rate) *
              Number(updatedEstimateDetail.price) *
              Number(quantity)) /
            100,
        };
      });

    setEstimateDetails(updatedEstimateDetails);
  };

  const handleUpdateEstimateDetailSalesTax = (
    estimateDetailIndex: number,
    estimateDetailSalesTaxIndex: number,
    estimateDetailSalesTaxesLength: number,
    salesTaxID: number
  ) => {
    if (salesTaxID == 0) {
      if (estimateDetailSalesTaxIndex < estimateDetailSalesTaxesLength) {
        const updatedEstimateDetailSalesTaxes = [
          ...estimateDetails[estimateDetailIndex].estimateDetailSalesTaxes,
        ];

        updatedEstimateDetailSalesTaxes.splice(estimateDetailSalesTaxIndex, 1);

        const updatedEstimateDetails = [...estimateDetails];
        const updatedEstimateDetail = {
          ...updatedEstimateDetails[estimateDetailIndex],
          estimateDetailSalesTaxes: updatedEstimateDetailSalesTaxes,
        };
        updatedEstimateDetails[estimateDetailIndex] = updatedEstimateDetail;
        setEstimateDetails(updatedEstimateDetails);
      }
    } else {
      // Get the sales tax
      const salesTax = salesTaxEffectiveRates.find((o) => o.id == salesTaxID);

      // Get the estimate detail
      const updatedEstimateDetails = [...estimateDetails];
      const updatedEstimateDetail = {
        ...updatedEstimateDetails[estimateDetailIndex],
      };

      // If sales tax already exist, do nothing
      if (
        updatedEstimateDetail.estimateDetailSalesTaxes.findIndex(
          (o) => o.salesTaxID == salesTax?.id
        ) != -1
      ) {
        updatedEstimateDetail.estimateDetailSalesTaxes[
          estimateDetailSalesTaxIndex
        ] = estimateAndQuoteDetailSalesTaxDefaultValue;

        updatedEstimateDetails[estimateDetailIndex] = updatedEstimateDetail;

        setEstimateDetails(updatedEstimateDetails);

        return;
      }

      // Compute the sales tax amount
      const updatedEstimateDetailSalesTax = {
        ...updatedEstimateDetail.estimateDetailSalesTaxes[
          estimateDetailSalesTaxIndex
        ],
        salesTaxID: salesTax?.id ?? 0,
        salesTaxAbbreviation: salesTax?.abbreviation ?? '',
        rate: salesTax?.rate ?? 0,
        salesTaxAmount:
          (Number(salesTax?.rate ?? 0) *
            Number(estimateDetails[estimateDetailIndex].price) *
            Number(estimateDetails[estimateDetailIndex].quantity)) /
          100,
      };

      // Update the sales tax
      updatedEstimateDetail.estimateDetailSalesTaxes[
        estimateDetailSalesTaxIndex
      ] = updatedEstimateDetailSalesTax;

      // Add new sales tax line if the last sales tax line is not empty
      if (
        updatedEstimateDetail.estimateDetailSalesTaxes[
          updatedEstimateDetail.estimateDetailSalesTaxes.length - 1
        ].salesTaxID > 0
      ) {
        updatedEstimateDetail.estimateDetailSalesTaxes = [
          ...updatedEstimateDetail.estimateDetailSalesTaxes,
          estimateAndQuoteDetailSalesTaxDefaultValue,
        ];
      }

      updatedEstimateDetails[estimateDetailIndex] = updatedEstimateDetail;

      setEstimateDetails(updatedEstimateDetails);
    }
  };

  const getSalesTaxesBySalesTaxID = () => {
    const salesTaxes = estimateDetails.map((o) =>
      o.estimateDetailSalesTaxes
        .filter((o) => o.salesTaxID != 0)
        .map((salesTaxes) => salesTaxes)
    );

    const groupedSalesTaxes = groupBy(
      salesTaxes.flat(),
      'salesTaxAbbreviation'
    ) as EstimateAndQuoteDetailSalesTaxViewModel[][];

    return groupedSalesTaxes;
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const validateEstimateDetails = () => {
    for (const detail of estimateDetails) {
      if (!detail.productID) {
        return false;
      }
    }
    return true;
  };

  const handleAddEstimateDetail = () => {
    if (!validateEstimateDetails()) {
      alert('Please fill in the fields before adding a new product.');
      return;
    }

    const emptyEstimateDetail = {
      ...estimateAndQuoteDetailDefaultValue,
      estimateDetailSalesTaxes: [estimateAndQuoteDetailSalesTaxDefaultValue],
    };

    getSalesTaxesBySalesTaxID();
    setEstimateDetails((prevEstimateDetails) => [
      ...prevEstimateDetails,
      emptyEstimateDetail,
    ]);
  };

  const handleRemoveEstimateDetail = (index: number) => {
    if (estimateDetails.length <= 1) {
      console.log('At least one item must remain in the table.');
      return;
    }

    const updatedItems = [...estimateDetails];
    updatedItems.splice(index, 1);
    setEstimateDetails(updatedItems);
  };

  const handleResetEstimateDetail = (index: number) => {
    const updatedItems = [...estimateDetails];
    updatedItems[index] = {
      ...estimateAndQuoteDetailDefaultValue,
      estimateDetailSalesTaxes: [estimateAndQuoteDetailSalesTaxDefaultValue],
    };
    setEstimateDetails(updatedItems);
  };

  const handleUpdateEstimateDetailSalesTaxRates = () => {
    const updatedEstimateDetails = estimateDetails.map((estimateDetail) => {
      return {
        ...estimateDetail,
        estimateDetailSalesTaxes: estimateDetail.estimateDetailSalesTaxes.map(
          (estimateDetailSalesTax) => {
            const salesTax = salesTaxEffectiveRates.find(
              (i) => i.id == estimateDetailSalesTax.salesTaxID
            );
            return {
              ...estimateDetailSalesTax,
              rate: salesTax?.rate ?? 0,
              salesTaxAmount:
                (Number(salesTax?.rate ?? 0) *
                  Number(estimateDetail.price) *
                  Number(estimateDetail.quantity)) /
                100,
            };
          }
        ),
      };
    });

    setEstimateDetails(updatedEstimateDetails);
  };

  useEffect(() => {
    handleUpdateEstimateDetailSalesTaxRates();
  }, [salesTaxEffectiveRates]);

  useEffect(() => {
    if (!isEstimateLoading) {
      loadEstimateCustomerContacts(Number(estimate.customerID));
      loadEstimateCustomerContactDetails(Number(estimate.contactID));
      loadEstimateCustomerBillingAddresses(Number(estimate.customerID));
      loadEstimateCustomerShippingAddresses(Number(estimate.customerID));
      loadEffectiveSalesTaxRates(new Date(estimate.date));
    }
  }, [isEstimateLoading]);

  useEffect(() => {
    if (
      !isSalesTaxEffectiveRatesLoading &&
      !isEstimateLoading &&
      !isEstimateDetailsLoading &&
      !isCustomerContactDetailsLoading &&
      !isCustomerContactsLoading &&
      !isCustomerBillingAddressesLoading &&
      !isCustomerShippingAddressesLoading &&
      !isProductsLoading
    ) {
      setIsLoading(false);
    }
  }, [
    isSalesTaxEffectiveRatesLoading,
    isEstimateLoading,
    isEstimateDetailsLoading,
    isCustomerContactDetailsLoading,
    isCustomerContactsLoading,
    isCustomerBillingAddressesLoading,
    isCustomerShippingAddressesLoading,
    isProductsLoading,
  ]);

  useEffect(() => {
    if (!isEstimateLoading) {
    }
  }, [isEstimateLoading]);

  useEffect(() => {
    if (id) {
      loadEstimate();
      loadEstimateDetails(Number(id));
      loadProducts();
    }
  }, []);

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <Box>
          <div className="pt-2 pb-6">
            <p className="w-full text-center text-2xl font-bold text-primary-900">
              Edit Estimate Details
            </p>
          </div>

          <Formik
            initialValues={estimate}
            validationSchema={estimateAndQuoteValidation}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              const formattedValues = {
                ...values,
                date: format(new Date(values.date), 'yyyy-MM-dd'),
                dueDate: format(new Date(values.dueDate), 'yyyy-MM-dd'),
              };

              formattedValues.estimateDetails = estimateDetails;

              EstimateAndQuoteService.update(Number(id), formattedValues)
                .then((response) => {
                  if (response.status == 204) {
                    console.log('Success: Successfully updated a record');
                    navigate(`${routes.RNC_ESTIMATES}/${id}`);
                  } else {
                    console.log('Error: Failed to update a record');
                  }
                })
                .catch((error) => {
                  alert('Error: Failed to handle the request.');
                  console.log(error);
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => {
              return (
                <form method="POST" onSubmit={formikProps.handleSubmit}>
                  <FormSectionContainer>
                    <BoxRowColumn>
                      <div className="w-[73%]">
                        <BoxColumn>
                          <FormikInput
                            label="Estimate Number"
                            name="estimateNumber"
                            readonly={true}
                          />

                          <EstimateAndQuoteCreateCustomerSelectEdit
                            initialSelectedCustomerId={`${estimate.customerID}`}
                            onCustomerSelectChange={(customerId: string) => {
                              loadSelectedCustomer(customerId, formikProps);
                            }}
                          />

                          <FormikInput
                            label="Customer Ref"
                            name="customerRef"
                          />

                          <FormikSelect
                            label="Contact Name"
                            name="contactID"
                            selection={customerContacts}
                            onChange={(
                              event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              loadSelectedContact(
                                event.target.value,
                                formikProps
                              );
                            }}
                          />

                          <BoxRowColumn>
                            <BoxColumn>
                              <FormikInput
                                label="Email"
                                name="contactEmail"
                                readonly={true}
                              />
                            </BoxColumn>

                            <BoxColumn>
                              <FormikInput
                                label="Phone"
                                name="contactPhone"
                                readonly={true}
                              />
                            </BoxColumn>
                          </BoxRowColumn>

                          <BoxRowColumn>
                            <BoxColumn>
                              <FormikDateInput
                                label="Date (mm/dd/yyyy)"
                                name="date"
                                type="date"
                                changeHandler={(value: string) => {
                                  loadEffectiveSalesTaxRates(new Date(value));
                                }}
                              />
                            </BoxColumn>

                            <BoxColumn>
                              <FormikDateInput
                                label="Due Date (mm/dd/yyyy)"
                                name="dueDate"
                                type="date"
                                onChange={(e: any) =>
                                  formikProps.setFieldValue(
                                    'dueDate',
                                    format(
                                      new Date(e.target.value),
                                      'MM/dd/yyyy'
                                    )
                                  )
                                }
                              />
                            </BoxColumn>
                          </BoxRowColumn>

                          <FormikSelect
                            label="Billing Address"
                            name="billingAddressID"
                            selection={customerBillingAddresses}
                          />

                          {!formikProps.values.isSameShipping ? (
                            <FormikSelect
                              label="Shipping Address"
                              name="shippingAddressID"
                              selection={customerShippingAddresses}
                            />
                          ) : (
                            <>
                              <label className="block text-sm font-medium text-primary-700">
                                Shipping Address
                              </label>
                              <i>Same as Billing Address</i>
                            </>
                          )}

                          <FormikTextArea label="Notes" name="notes" />
                        </BoxColumn>
                      </div>

                      <BoxColumn>
                        <div>
                          <div className="max-w-5x1 mx-auto">
                            <div className="pt-6">
                              <p className="w-full bg-primary-100 text-sm font-bold text-black py-1.5 text-center">
                                Details
                              </p>
                            </div>

                            <TableContainer>
                              <div className="min-w-full bg-[#e7eeeb50]">
                                <thead>
                                  <tr>
                                    <TableHeaderLeft>
                                      <div className="pl-2">Item Name</div>
                                    </TableHeaderLeft>
                                    <TableHeaderLeft>
                                      Description
                                    </TableHeaderLeft>
                                    <TableHeaderCenter>
                                      Quantity
                                    </TableHeaderCenter>
                                    <TableHeaderCenter>Price</TableHeaderCenter>
                                    <TableHeaderCenter>Tax</TableHeaderCenter>
                                    <TableHeaderRight>
                                      SubTotal
                                    </TableHeaderRight>
                                    <TableHeaderRight>
                                      <div className="pr-2"></div>
                                    </TableHeaderRight>
                                  </tr>
                                </thead>
                                <tbody>
                                  {estimateDetails.map(
                                    (estimateDetail, estimateDetailIndex) => (
                                      <tr
                                        key={estimateDetailIndex}
                                        className={`${
                                          estimateDetailIndex % 2 === 0
                                            ? ''
                                            : 'bg-[#56c39225]'
                                        }`}
                                      >
                                        <TableDataLeft>
                                          <div className="pl-2 flex gap-0">
                                            <CustomSelect
                                              label=""
                                              value={
                                                estimateDetails[
                                                  estimateDetailIndex
                                                ].productID
                                              }
                                              selection={products}
                                              onChange={(
                                                event: React.ChangeEvent<HTMLSelectElement>
                                              ) => {
                                                {
                                                  loadSelectedProduct(
                                                    event.target.value,
                                                    estimateDetailIndex
                                                  );
                                                }
                                              }}
                                            />
                                            <button
                                              type="button"
                                              className="mt-1 relative -ml-px inline-flex items-center gap-x-1.5 px-2 py-1 text-sm font-semibold text-gray-900 bg-white border-l border-gray-500 hover:bg-gray-100"
                                              onClick={() =>
                                                setShowCreateProduct(true)
                                              }
                                            >
                                              <PlusIcon
                                                width={18}
                                                height={18}
                                              />
                                            </button>
                                          </div>
                                        </TableDataLeft>
                                        <TableDataLeft>
                                          <CustomInput
                                            readonly={true}
                                            label=""
                                            value={
                                              estimateDetails[
                                                estimateDetailIndex
                                              ].description
                                            }
                                          />
                                        </TableDataLeft>
                                        <TableDataCenter>
                                          <CustomInput
                                            label=""
                                            value={estimateDetail.quantity}
                                            dataType="number"
                                            onChange={(e: any) => {
                                              handleUpdateEstimateDetailQuantity(
                                                estimateDetailIndex,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </TableDataCenter>
                                        <TableDataCenter>
                                          <CustomInput
                                            label=""
                                            value={Number(
                                              estimateDetails[
                                                estimateDetailIndex
                                              ].price
                                            ).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                            dataType="number"
                                            readonly={true}
                                          />
                                        </TableDataCenter>
                                        <div className="min-w-max">
                                          <TableDataCenter>
                                            {estimateDetails[
                                              estimateDetailIndex
                                            ].estimateDetailSalesTaxes.map(
                                              (
                                                estimateDetailSalesTax,
                                                estimateDetailSalesTaxIndex
                                              ) => {
                                                return (
                                                  <CustomSelect
                                                    key={
                                                      estimateDetailSalesTaxIndex
                                                    }
                                                    label=""
                                                    value={
                                                      estimateDetails[
                                                        estimateDetailIndex
                                                      ]
                                                        .estimateDetailSalesTaxes[
                                                        estimateDetailSalesTaxIndex
                                                      ].salesTaxID
                                                    }
                                                    selection={salesTaxes || []}
                                                    onChange={(e: any) => {
                                                      handleUpdateEstimateDetailSalesTax(
                                                        estimateDetailIndex,
                                                        estimateDetailSalesTaxIndex,
                                                        estimateDetail
                                                          .estimateDetailSalesTaxes
                                                          .length,
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                );
                                              }
                                            )}
                                          </TableDataCenter>
                                        </div>
                                        <TableDataRight>
                                          <CustomInput
                                            label=""
                                            value={Number(
                                              estimateDetails[
                                                estimateDetailIndex
                                              ].subTotal
                                            ).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                            dataType="number"
                                            readonly={true}
                                          />
                                        </TableDataRight>
                                        <TableDataRight>
                                          <div className="pr-2">
                                            <button
                                              className="py-2"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleRemoveEstimateDetail(
                                                  estimateDetailIndex
                                                );
                                              }}
                                            >
                                              <div className="opacity-75 text-primary-500 hover:text-red-800">
                                                <TrashIcon
                                                  height="24"
                                                  width="24"
                                                />
                                              </div>
                                            </button>
                                          </div>
                                        </TableDataRight>
                                      </tr>
                                    )
                                  )}

                                  <tr>
                                    <TableDataLeft>
                                      <PrimaryButton
                                        onClick={handleAddEstimateDetail}
                                      >
                                        Add Item
                                      </PrimaryButton>
                                    </TableDataLeft>
                                  </tr>

                                  <tr>
                                    <td colSpan={7}>
                                      <div className="p-4">
                                        <div className="flex text-sm justify-end">
                                          <div className="font-bold mr-4">
                                            Subtotal:
                                          </div>
                                          <div className="w-24 text-right">
                                            {formatAsCurrency(
                                              estimateDetails.reduce(
                                                (acc, cur) =>
                                                  acc + cur.subTotal,
                                                0
                                              )
                                            )}
                                          </div>
                                        </div>

                                        <Space />

                                        {Object.entries(
                                          getSalesTaxesBySalesTaxID()
                                        ).map(([abbreviation, details]) => (
                                          <div
                                            className="flex text-sm justify-end"
                                            key={abbreviation}
                                          >
                                            <div className="mr-4">
                                              {abbreviation} ({details[0]?.rate}
                                              %)
                                            </div>
                                            <div className="w-24 text-right">
                                              {formatAsCurrency(
                                                details.reduce(
                                                  (acc, cur) =>
                                                    acc + cur.salesTaxAmount,
                                                  0
                                                )
                                              )}
                                            </div>
                                          </div>
                                        ))}

                                        <div className="flex text-sm justify-end">
                                          <div className="font-bold mr-4">
                                            Total Tax:
                                          </div>
                                          <div className="w-24 text-right">
                                            {formatAsCurrency(
                                              estimateDetails
                                                .map((o) =>
                                                  o.estimateDetailSalesTaxes.reduce(
                                                    (acc, cur) =>
                                                      acc + cur.salesTaxAmount,
                                                    0
                                                  )
                                                )
                                                .reduce(
                                                  (acc, cur) => acc + cur,
                                                  0
                                                )
                                            )}
                                          </div>
                                        </div>

                                        <Space />

                                        <div className="flex text-sm justify-end ">
                                          <div className="font-bold mr-4">
                                            Total:
                                          </div>
                                          <div className="w-24 text-right">
                                            {formatAsCurrency(
                                              estimateDetails.reduce(
                                                (acc, cur) =>
                                                  acc + cur.subTotal,
                                                0
                                              ) +
                                                estimateDetails
                                                  .map((o) =>
                                                    o.estimateDetailSalesTaxes.reduce(
                                                      (acc, cur) =>
                                                        acc +
                                                        cur.salesTaxAmount,
                                                      0
                                                    )
                                                  )
                                                  .reduce(
                                                    (acc, cur) => acc + cur,
                                                    0
                                                  )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </div>
                            </TableContainer>
                          </div>
                        </div>

                        <div className="flex items-end justify-end h-full">
                          <FormButtonsContainer>
                            <CancelButton
                              onClick={() => {
                                navigate(previousLocation);
                              }}
                            />

                            <SubmitButton
                              label="Save"
                              disabled={
                                formikProps.isSubmitting || !formikProps.isValid
                              }
                            />
                          </FormButtonsContainer>
                        </div>
                      </BoxColumn>
                    </BoxRowColumn>
                  </FormSectionContainer>
                </form>
              );
            }}
          </Formik>
        </Box>

        {showCreateProduct && (
          <>
            <Modal show={true}>
              <ProductCreate
                onClose={(productResultViewModel) => {
                  setShowCreateProduct(false);

                  if (productResultViewModel && productResultViewModel.data) {
                    const newProducts = [
                      ...products.slice(0, products.length - 1),
                      {
                        value: productResultViewModel.data.id.toString(),
                        text: productResultViewModel.data.name,
                      },
                    ];

                    setProducts(newProducts);

                    loadSelectedProduct(
                      productResultViewModel.data.id.toString(),
                      estimateDetails.length - 1
                    );
                  } else {
                    handleResetEstimateDetail(estimateDetails.length - 1);
                  }
                }}
              />
            </Modal>
          </>
        )}
      </>
    );
  }
};
