import { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components';

interface BaseSelectProps {
  hasError: boolean;
}

const BaseSelect = tw.select<BaseSelectProps>`
mt-1 block w-full py-2 pl-3 pr-10 text-xs  focus:outline-none
${(props) =>
  !props.hasError &&
  !props.value &&
  'border-none focus:border-green-500 focus:ring-green-500'} 
${(props) =>
  !props.hasError &&
  !!props.value &&
  'border-none focus:border-green-500 focus:ring-green-500'} 
${(props) =>
  props.hasError &&
  !props.value &&
  'border-none focus:border-green-500 focus:ring-green-500'} 
${(props) =>
  props.hasError &&
  !!props.value &&
  'border-red-300 focus:border-red-500 focus:ring-red-500 bg-red-100'} 
`;

export interface CustomSelectOption {
  selected?: boolean;
  value: string;
  text: string | number;
  id?: number;
}

type Props = {
  label?: string;
  value?: any;
  selection: CustomSelectOption[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const CustomSelect: React.FC<Props> = ({
  label,
  value,
  selection,
  onChange,
}) => {
  const [selectValue, setSelectValue] = useState(value);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  return (
    <div>
      <label className="block text-xs font-medium text-left text-primary-700">
        {label}
      </label>
      <BaseSelect
        onChange={(e) => {
          setSelectValue(e.target.value);
          if (onChange) {
            onChange(e);
          }
        }}
        hasError={false}
        value={selectValue}
      >
        {selection.map((row, index) => (
          <option key={index} value={row.value}>
            {row.text}
          </option>
        ))}
      </BaseSelect>
    </div>
  );
};
