interface Props {
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFilter?: () => void;
  onClear?: () => void;
  label?: string;
}

export const FilterRecord: React.FC<Props> = ({
  value,
  onChange,
  onClear,
  onFilter,
  label,
}) => {
  return (
    <>
      <div className="w-full">
        <div className="grid justify-start gap-4">
          <label className="lg:text-sm md:text-xs text-xs font-medium text-green-700">
            {label}
          </label>
          <div className="lg:flex md:flex block lg:text-sm md:text-xs text-xs font-medium text-green-700 w-full lg:space-y-0 md:space-y-0 space-y-2 lg:space-x-4 md:space-x-2">
            <input
              type="text"
              value={value}
              onChange={(e) => onChange && onChange(e)}
              placeholder={label}
              className="w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 md:text-xs lg:text-sm text-xs"
            />
            <button
              onClick={onFilter}
              className="bg-primary-500 rounded-md border border-gray-300 py-2 px-4 md:text-xs lg:text-sm text-xs font-medium text-white shadow-sm hover:bg-primary-700 flex-shrink-0 mr-2"
            >
              Filter
            </button>
            <button
              onClick={onClear}
              className="rounded-md border border-gray-300 bg-white py-2 px-4 md:text-xs lg:text-sm text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 flex-shrink-0"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
