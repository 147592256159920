import { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components';

interface BaseInputProps {
  hasError: boolean;
  datatype: 'text' | 'number';
}

const BaseInput = tw.input<BaseInputProps>`
block w-full bg-white text-xs
${(props) =>
  !props.hasError &&
  !props.value &&
  'border-none focus:border-green-500 focus:ring-green-500'} 
${(props) =>
  !props.hasError &&
  !!props.value &&
  'border-none focus:border-green-500 focus:ring-green-500'} 
${(props) =>
  props.hasError &&
  !props.value &&
  'border-none focus:border-green-500 focus:ring-green-500'} 
${(props) =>
  props.hasError &&
  !!props.value &&
  'border-red-300 focus:border-red-500 focus:ring-red-500 bg-red-100'} 
${(props) => props.datatype == 'number' && 'text-right'}
`;

type Props = {
  label?: string;
  value?: string | number;
  dataType?: 'text' | 'number';
  readonly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CustomInput: React.FC<Props> = ({
  label,
  value,
  dataType = 'text',
  readonly,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className="w-full">
      <label className="block text-xs font-medium text-primary-700">
        {label}
      </label>
      <div className="mt-1">
        <BaseInput
          value={inputValue}
          datatype={dataType}
          readOnly={readonly}
          type="text"
          onChange={(e) => {
            setInputValue(e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          hasError={false}
        />
      </div>
    </div>
  );
};
