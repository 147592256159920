import { FolderPlusIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PrimaryButton } from '@components/Buttons/PrimaryButtons';
import {
  CallToActionContainer,
  CallToActionItems,
} from '@components/Container';
import { Details, DetailsButton } from '@components/Details';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { TuosLogo } from '@components/Icons';
import { Loading } from '@components/Loading';
import { routes } from '@config/routes';
import {
  billingAddressDefaultValue,
  BillingAddressResultViewModel,
} from '@models/BillingAddress';
import {
  CustomerResultViewModel,
  customerDefaultValue,
} from '@models/Customer';
import { BillingAddressService } from '@services/BillingAddress';
import { CustomerService } from '@services/Customer';
import { toTitleCase } from '@utils/titleCase';

export const CustomerDetails: React.FC = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(customerDefaultValue);
  const navigate = useNavigate();

  const [customerAddress, setCustomerAddress] = useState(
    billingAddressDefaultValue
  );

  const location = useLocation();

  const loadData = () => {
    CustomerService.getById(Number(id))
      .then((response) => response.data as Promise<CustomerResultViewModel>)
      .then((result) => {
        if (result) {
          setData(result.data);
          setIsLoading(false);
          console.log('fetchedDate', result);

          if (result.data.billingID) {
            BillingAddressService.getById(result.data.billingID)
              .then(
                (addressResponse) =>
                  addressResponse.data as Promise<BillingAddressResultViewModel>
              )
              .then((addressResult) => {
                setCustomerAddress(addressResult.data);
              });
          }

          console.log('Success: Fetch the record.');
        } else {
          console.log('Error: Failed to get record.');
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle thr request');
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, []);

  const handleEdit = (rowId: number) => {
    navigate(`${routes.RNC_CUSTOMERS}/${rowId}/edit`, {
      state: { from: location },
    });
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <GridHeaderMenuTitle>
          <div className="lg:text-xl md:text-lg text-sm font-bold uppercase">
            {data.companyName}
          </div>

          <span className="lg:flex md:flex hidden">
            <PrimaryButton
              onClick={() => {
                navigate(`${routes.RNC_CUSTOMERS}/create`, {
                  state: { from: location },
                });
              }}
            >
              Create new customer
            </PrimaryButton>
          </span>

          <button
            onClick={() => {
              navigate(`${routes.RNC_CUSTOMERS}/create`, {
                state: { from: location },
              });
            }}
            className="text-primary-500 lg:hidden md:hidden flex items-center"
          >
            <PlusCircleIcon className="w-6 h-6 md:w-7 md:h-7" />
          </button>
        </GridHeaderMenuTitle>

        <Details>
          <div>
            <CallToActionContainer>
              <CallToActionItems>
                <div className="space-y-3">
                  <div className="flex text-primary-500 lg:text-2xl md:text-lg text-sm items-center lg:space-x-4 md:space-x-3 space-x-2">
                    <FolderPlusIcon className="lg:w-7 lg:h-7 md:w-6 md:h-6 w-5 h-5" />
                    <div className="text-gray-400 font-normal">Create</div>
                  </div>
                </div>

                <div>
                  <DetailsButton onClick={() => handleEdit(data.id)}>
                    Edit Estimate
                  </DetailsButton>
                </div>
              </CallToActionItems>
            </CallToActionContainer>
          </div>

          <div className="shadow-md border">
            <div className="lg:p-8 md:p-6 p-4 flex justify-between">
              {/* Desktop */}
              <div className="lg:flex md:hidden hidden items-center">
                <TuosLogo height="100" width="75" />
              </div>
              {/* Tablet */}
              <div className="md:flex lg:hidden hidden items-center">
                <TuosLogo height="75" width="56.25" />
              </div>
              {/* Mobile */}
              <div className="flex lg:hidden md:hidden items-center">
                <TuosLogo height="56.25" width="42.1875" />
              </div>

              <div className="lg:text-xl md:text-base text-sm font-semibold text-end text-gray-500">
                CUSTOMER
                <div className="text-black lg:text-lg md:text-base text-sm">
                  Tuos Development <br />
                  <span className="lg:text-sm md:text-sm text-xs">Canada</span>
                </div>
              </div>
            </div>

            <div className="border-t-2 border-gray-700 lg:py-4 lg:px-8 md:py-4 md:px-6 py-2 px-4">
              <div className="space-y-4">
                <div>
                  <div className="lg:text-base md:text-sm text-sm font-semibold text-gray-500">
                    Primary Contact
                  </div>
                  <div className="lg:text-base md:text-sm text-xs">
                    <span className="uppercase">
                      {data.primaryContact?.firstname}{' '}
                      {data.primaryContact?.lastname}
                    </span>
                    <br />
                    {data.primaryContact?.phone}
                    <br />
                    <span className="lowercase">
                      {data.primaryContact?.email}
                    </span>
                  </div>
                </div>

                <div>
                  <div className="lg:text-base md:text-sm text-sm font-semibold text-gray-500">
                    Billing Address
                  </div>
                  <div className="lg:text-base md:text-sm text-xs">
                    {toTitleCase(`${customerAddress.address1}`)}
                    <br />
                    {toTitleCase(
                      `${customerAddress.address2}${','} ${
                        customerAddress.city
                      }`
                    )}
                    <br />
                    {toTitleCase(
                      `${customerAddress.state}${','} ${
                        customerAddress.country
                      }${','} ${customerAddress.postalCode}`
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Details>
      </>
    );
  }
};
