import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Toolbar,
  EditSettingsModel,
  Grid,
  DataResult,
  DataStateChangeEventArgs,
  // EditSettingsModel,
  // DataStateChangeEventArgs,
  // DataResult,
} from '@syncfusion/ej2-react-grids';
import React, { useEffect, useState } from 'react';

import { CurrencyEdit } from './CurrencyEdit';

import { CloseButton } from '@components/ActionButtons/CloseButton';
import { Box } from '@components/Box';
import { FormButtonsContainer } from '@components/Container';
import { CancelButton } from '@components/Form';
import { OkButton } from '@components/Form/OkButton';
import { FilterRecord } from '@components/Grid';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import { CurrencyResultViewModel } from '@models/Currency';
import { CurrencyService } from '@services/Currency';

export const CurrencyList: React.FC = () => {
  const [showEditCurrency, setShowEditCurrency] = useState(false);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<number>(0);
  const [currencyName, setCurrencyName] = useState<string>('');
  const [showDelete, setShowDelete] = useState(false);

  const [dataUrlQueryFilter, setDataUrlQueryFilter] = useState<string>('');
  const [nameFilter, setNameFilter] = useState<string>('');

  let grid: Grid | null;
  //let data;

  const editOptions: EditSettingsModel = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Dialog',
  };

  useEffect(() => {
    grid?.refresh();
  }, [dataUrlQueryFilter]);

  function renderComplete() {
    if (
      grid &&
      grid.dataSource instanceof Array &&
      !(grid.dataSource as object[]).length
    ) {
      const state = { skip: 0, take: 10 };
      dataStateChange(state);
    }
  }

  function dataStateChange(state: DataStateChangeEventArgs) {
    execute(state).then((gridData) => {
      if (grid) {
        grid.dataSource = gridData;
      }
    });
  }

  function execute(state: DataStateChangeEventArgs): Promise<DataResult> {
    return getData(state);
  }

  const fetchData = async (page: number, take: number) => {
    try {
      const response = await CurrencyService.getList(page, take);
      const data = response.data;
      return { result: data, count: response.total };
    } catch (error) {
      console.error('Error fetching currency data:', error);
    }
  };

  function getData(state: DataStateChangeEventArgs): Promise<DataResult> {
    const { skip, take } = state;
    const page = skip && take ? skip / take + 1 : 1;

    return CurrencyService.getList(
      page,
      state.take,
      undefined,
      undefined,
      dataUrlQueryFilter
    ).then((data) => {
      return { result: data.data, count: data.total };
    });
  }

  const handleEdit = (rowId: any) => {
    setShowEditCurrency(true);
    setSelectedCurrencyId(rowId);
  };

  const deleteRecord = (id: number) => {
    CurrencyService.delete(id).then((response) => {
      if (response.status === 204) {
        console.log('successfully deleted a record.');
        window.location.reload();
      } else {
        console.log('Error: Failed to delete the record.');
      }
    });
  };

  const handleDelete = async (rowId: any) => {
    try {
      CurrencyService.getById(Number(rowId))
        .then((response) => response.data as Promise<CurrencyResultViewModel>)
        .then((result) => {
          if (result.isSuccess) {
            setSelectedCurrencyId(rowId);
            setCurrencyName(result.data.name);
            setShowDelete(true);
          } else {
            console.log('Error: Failed to fetch product details for deletion.');
          }
        });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const actionButtons = (props: any) => {
    const menuOnTop = props.index >= 1;

    return (
      <>
        <div className="flex">
          <Menu as="div" className="absolute ml-auto">
            <Menu.Button className="-my-3 mx-5 block text-primary-500 hover:text-primary-200">
              <span className="sr-only">Open options</span>
              <ChevronDownIcon width="20" height="20" />
            </Menu.Button>
            <Menu.Items
              className={`absolute right-12 -top-1 z-50 mt-0.5 w-32 origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none ${
                menuOnTop && '-top-14'
              }`}
            >
              <div>
                <div className="hover:bg-primary-500 rounded-md">
                  <button
                    onClick={() => handleEdit(props.id)}
                    className="w-full text-start px-3"
                  >
                    Edit
                  </button>
                </div>
                <div className="hover:bg-primary-500 rounded-md">
                  <button
                    onClick={() => handleDelete(props.id)}
                    className="w-full text-start px-3"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </>
    );
  };

  return (
    <>
      <FilterRecord
        label="Filter by Name"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        onFilter={() => {
          setDataUrlQueryFilter(`&nameFilters=${nameFilter}`);
        }}
        onClear={() => {
          setDataUrlQueryFilter('');
          setNameFilter('');
        }}
      />

      <Space />

      <GridComponent
        dataBound={renderComplete}
        dataSource={fetchData}
        ref={(g) => (grid = g)}
        allowPaging={true}
        pageSettings={{ pageSize: 10 }}
        allowFiltering={true}
        editSettings={editOptions}
        //dataStateChange={dataStateChange}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="name"
            headerText="Name"
            //template={gridNameTemplate}
          />
          <ColumnDirective field="shortName" headerText="Short Name" />
          <ColumnDirective field="symbol" headerText="Symbol" />
          <ColumnDirective
            headerText="Actions"
            template={actionButtons}
            width={100}
          />
        </ColumnsDirective>
        <Inject services={[Page, Toolbar]} />
      </GridComponent>
      {showEditCurrency && (
        <>
          <Modal show={true}>
            {selectedCurrencyId && (
              <CurrencyEdit
                currencyId={selectedCurrencyId}
                onClose={() => setShowEditCurrency(false)}
                onDelete={() => {
                  setShowEditCurrency(false);
                  handleDelete(selectedCurrencyId);
                }}
              />
            )}
          </Modal>
        </>
      )}
      {showDelete && (
        <>
          <Modal show={true}>
            <CloseButton onClick={() => setShowDelete(false)} />
            <Box size="medium">
              <>
                <div className="px-4 pb-2">
                  <span>Are you sure to delete Customer: {currencyName}</span>
                </div>
                <FormButtonsContainer>
                  <div className="space-x-2">
                    <CancelButton onClick={() => setShowDelete(false)} />
                    <OkButton onClick={() => deleteRecord(selectedCurrencyId)}>
                      Delete
                    </OkButton>
                  </div>
                </FormButtonsContainer>
              </>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
