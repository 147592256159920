import {
  ColumnDirective,
  ColumnsDirective,
  DataResult,
  DataStateChangeEventArgs,
  EditSettingsModel,
  GridComponent,
  Inject,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';

import { ProductEdit } from './ProductEdit';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import { FormButtonsContainer } from '@components/Container';
import { CancelButton } from '@components/Form';
import { OkButton } from '@components/Form/OkButton';
import { FilterRecord, TableActions } from '@components/Grid';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import { ProductService } from '@services/Product';

export const ProductList: React.FC = () => {
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [productName, setProductName] = useState<string>('');
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [dataUrlQueryFilter, setDataUrlQueryFilter] = useState<string>('');
  const [nameFilter, setNameFilter] = useState<string>('');
  const [gridData, setGridData] = useState<DataResult>({
    result: [],
    count: 0,
  });
  const gridRef = useRef<GridComponent | null>(null);

  const editOptions: EditSettingsModel = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Dialog',
  };

  useEffect(() => {
    fetchData();
  }, [dataUrlQueryFilter]);

  const fetchData = async () => {
    const state = { skip: 0, take: 10 };
    const data = await getData(state);
    setGridData(data);
  };

  function renderComplete() {
    if (
      gridRef.current &&
      gridRef.current.dataSource instanceof Array &&
      !(gridRef.current.dataSource as object[]).length
    ) {
      const state = { skip: 0, take: 10 };
      dataStateChange(state);
    }
  }

  function dataStateChange(state: DataStateChangeEventArgs) {
    execute(state).then((gridData) => {
      setGridData(gridData);
    });
  }

  function execute(state: DataStateChangeEventArgs): Promise<DataResult> {
    return getData(state);
  }

  function getData(state: DataStateChangeEventArgs): Promise<DataResult> {
    const page =
      !state || !state.skip || !state.take ? 1 : state.skip / state?.take + 1;

    return ProductService.getlist(
      page,
      state.take,
      undefined,
      undefined,
      dataUrlQueryFilter
    ).then((data) => {
      return { result: data.data, count: data.total };
    });
  }

  const handleEdit = (rowId: any) => {
    setSelectedProductId(rowId);
    setShowEditProduct(true);
  };

  const deleteRecord = async (id: number) => {
    try {
      const result = await ProductService.delete(id);
      if (result.isSuccess) {
        console.log('Successfully deleted a record.');
        fetchData();
        setShowDelete(false);
      } else {
        console.error(
          'Error: Failed to delete the record.',
          result.errorMessage
        );
        alert(`Error: ${result.errorMessage}`);
        setShowDelete(false);
      }
    } catch (error) {
      console.error('Error: ', error);
      alert('An unexpected error occurred while deleting the record.');
      setShowDelete(false);
    }
  };

  const handleDelete = async (rowId: any) => {
    try {
      ProductService.getById(rowId).then((result) => {
        if (result.isSuccess) {
          setSelectedProductId(rowId);
          setProductName(result.data.name);
          setShowDelete(true);
        } else {
          console.log('Error: Failed to fetch product details for deletion.');
        }
      });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const actionButtons = (props: any) => {
    const menuOnTop = props.index >= 1;

    return (
      <TableActions
        dropdownActions={[
          {
            title: 'Edit',
            onClick: () => handleEdit(props.id),
          },
          {
            showTopSeparator: true,
            title: 'Delete',
            onClick: () => handleDelete(props.id),
          },
        ]}
        menuOnTop={menuOnTop}
      />
    );
  };

  return (
    <>
      <FilterRecord
        label="Filter by Name"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        onFilter={() => {
          setDataUrlQueryFilter(`&nameFilters=${nameFilter}`);
        }}
        onClear={() => {
          setDataUrlQueryFilter('');
          setNameFilter('');
        }}
      />

      <Space />

      <GridComponent
        dataBound={renderComplete}
        dataSource={gridData.result}
        ref={gridRef}
        allowPaging={true}
        pageSettings={{ pageSize: 10 }}
        editSettings={editOptions}
        dataStateChange={dataStateChange}
      >
        <ColumnsDirective>
          <ColumnDirective field="name" headerText="Name" />
          <ColumnDirective field="price" headerText="Price" />
          <ColumnDirective
            headerText="Actions"
            width={100}
            template={actionButtons}
          />
        </ColumnsDirective>
        <Inject services={[Page, Toolbar]} />
      </GridComponent>

      {showEditProduct && (
        <Modal show={true}>
          {selectedProductId && (
            <ProductEdit
              productId={selectedProductId}
              onClose={() => setShowEditProduct(false)}
              onSuccessfulSave={() => {
                setShowEditProduct(false);
                fetchData();
              }}
              onDelete={() => {
                setShowEditProduct(false);
                handleDelete(selectedProductId);
              }}
            />
          )}
        </Modal>
      )}

      {showDelete && (
        <Modal show={true}>
          <CloseButton onClick={() => setShowDelete(false)} />

          <Box size="medium">
            <div className="px-4 pb-2">
              <span>Are you sure to delete Product: {productName}</span>
            </div>
            <FormButtonsContainer>
              <div className="space-x-2">
                <CancelButton onClick={() => setShowDelete(false)} />
                <OkButton onClick={() => deleteRecord(selectedProductId)}>
                  Delete
                </OkButton>
              </div>
            </FormButtonsContainer>
          </Box>
        </Modal>
      )}
    </>
  );
};
