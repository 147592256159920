import {
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';

import { ChartOfAccountCreate } from './ChartOfAccountCreate';
import { ChartOfAccountEdit } from './ChartOfAccountEdit';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import { FormButtonsContainer } from '@components/Container';
import {
  DetailTableDataCenter,
  DetailTableDataLeft,
  DetailTableDataRight,
  DetailTableHeaderCenter,
  DetailTableHeaderLeft,
  DetailTableHeaderRight,
} from '@components/Details';
import { CancelButton } from '@components/Form';
import { OkButton } from '@components/Form/OkButton';
import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Space } from '@components/Space';
import { Table, TableContainer } from '@components/TableContainers';
import TextWrapper from '@components/TextWrapper';
import {
  ChartOfAccountTypeViewModel,
  ChartOfAccountViewModel,
} from '@models/ChartOfAccount';
import { ResultViewModel } from '@models/common/ResultViewModel';
import { ChartOfAccountService } from '@services/ChartofAccount';

const accountTypeGroups = [
  { typeGroup: 'Assets', label: 'Assets', isActive: true },
  {
    typeGroup: 'LiabilitiesAndCreditCards',
    label: 'Liabilities & Credit Cards',
    isActive: false,
  },
  { typeGroup: 'Income', label: 'Income', isActive: false },
  { typeGroup: 'Expenses', label: 'Expenses', isActive: false },
  { typeGroup: 'Equity', label: 'Equity', isActive: false },
];

export const ChartOfAccountList: React.FC = () => {
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [showEditChartOfAccount, setShowEditChartOfAccount] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedChartOfAccountId, setSelectedChartOfAccountId] =
    useState<number>(0);
  const [chartOfAccountName, setChartOfAccountName] = useState<string>('');

  const [headerTabs, setHeaderTabs] = useState(accountTypeGroups);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAccountTypeLoading, setIsAccountTypeLoading] =
    useState<boolean>(true);
  const [accountTypes, setAccountTypes] =
    useState<ChartOfAccountTypeViewModel[]>();

  const [isAccountLoading, setIsAccountLoading] = useState<boolean>(true);
  const [accounts, setAccounts] = useState<ChartOfAccountViewModel[]>();

  const handleActiveClick = (tabName: any) => {
    const tabs = headerTabs.map((tab) => {
      if (tab.typeGroup === tabName) {
        return { ...tab, isActive: true };
      } else {
        return { ...tab, isActive: false };
      }
    });
    setHeaderTabs(tabs);
  };

  const loadAccountTypes = () => {
    ChartOfAccountService.getChartOfAccountTypes().then((result) => {
      setAccountTypes(result.data);
      setIsAccountTypeLoading(false);
    });
  };

  const loadAccounts = () => {
    ChartOfAccountService.getList().then((result) => {
      setAccounts(result.data);
      setIsAccountLoading(false);
    });
  };

  useEffect(() => {
    if (!isAccountTypeLoading && !isAccountLoading) {
      setIsLoading(false);
    }
  }, [isAccountTypeLoading, isAccountLoading]);

  useEffect(() => {
    loadAccountTypes();
    loadAccounts();
  }, []);

  const handleEdit = (rowId: any) => {
    setSelectedChartOfAccountId(rowId);
    setShowEditChartOfAccount(true);
  };

  const deleteRecord = (id: number) => {
    ChartOfAccountService.delete(id).then((response) => {
      if (response.status == 204) {
        console.log('Successfully deleted the record.');
        window.location.reload();
      } else {
        const failureResponse = response.data as Promise<ResultViewModel>;
        return failureResponse.then((result) => {
          const message = `Error: Failed to delete the record. ${result.errorMessage}`;
          alert(message);
          console.log(message);
        });
      }
    });
  };

  const handleDelete = async (rowId: number, rowChartsName: string) => {
    try {
      setSelectedChartOfAccountId(rowId);
      setChartOfAccountName(rowChartsName);
      setShowDelete(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="lg:block md:block hidden">
          <Space />
        </div>
        <div className="flex px-4 justify-center">
          {/* Deskstop and Tablet */}
          <ul className="lg:flex md:flex hidden space-x-2 p-2 bg-[#CDEDDF] rounded-xl">
            {headerTabs.map((tab, index) => (
              <li
                key={index}
                className={`rounded-md p-2 hover:bg-[#318760] hover:text-white  lg:text-sm md:text-md text-xs cursor-pointer ${
                  tab.isActive
                    ? 'bg-[#318760] text-white lg:font-semibold md:font-semibold font-medium'
                    : ''
                }`}
                onClick={() => {
                  handleActiveClick(tab.typeGroup);
                }}
              >
                {tab.label}
              </li>
            ))}
          </ul>
          {/* Mobile */}
          <div className="mt-4 lg:hidden md:hidden flex justify-evenly bg-[#CDEDDF] rounded-xl p-2 w-full">
            <ul
              role="list"
              className="grid grid-cols-3 gap-2 w-full justify-center"
            >
              {headerTabs.map((tabs, index) => (
                <li
                  className={`rounded-md text-center justify-center flex items-center p-2 hover:bg-[#318760] hover:text-white  lg:text-sm md:text-md text-xs cursor-pointer ${
                    tabs.isActive
                      ? 'bg-[#318760] text-white lg:font-semibold md:font-semibold font-medium'
                      : ''
                  }`}
                  key={index}
                  onClick={() => {
                    handleActiveClick(tabs.typeGroup);
                  }}
                >
                  {tabs.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Space />
        {accountTypes &&
          accountTypes
            .filter(
              (o) =>
                o.typeGroup == headerTabs.find((i) => i.isActive)?.typeGroup
            )
            .map((accountType, index) => (
              <div className="lg:px-20 md:px-10 px-4" key={index}>
                <TableContainer>
                  <Table>
                    <thead className="bg-[#56C392]">
                      <tr className=" bg-[#56C392]">
                        <DetailTableHeaderLeft>
                          <p className="font-semibold text-black text-sm">
                            {accountType.name}
                          </p>
                        </DetailTableHeaderLeft>
                        <DetailTableHeaderCenter></DetailTableHeaderCenter>
                        <DetailTableHeaderRight></DetailTableHeaderRight>
                      </tr>
                    </thead>
                    <tbody>
                      {accounts &&
                        accounts
                          .filter((account) => account.type == accountType.name)
                          .map((account, index) => (
                            <tr
                              key={index}
                              className={`${
                                index % 2 === 0
                                  ? 'bg-white'
                                  : 'bg-[#56C392] bg-opacity-[15%]'
                              }`}
                            >
                              <DetailTableDataLeft>
                                <div className="w-full">
                                  <div className="font-semibold text-start">
                                    <TextWrapper text={account.name || ''} />
                                  </div>
                                  <p className="font-light text-xs italic">
                                    <TextWrapper
                                      text={
                                        `Account ID: ${account.account}` || ''
                                      }
                                    />
                                  </p>
                                </div>
                              </DetailTableDataLeft>
                              <DetailTableDataCenter>
                                <TextWrapper text={account.description || ''} />
                              </DetailTableDataCenter>
                              <DetailTableDataRight>
                                <button
                                  onClick={() => handleEdit(account.id)}
                                  className="text-[#56C392]"
                                >
                                  <PencilSquareIcon className="lg:w-6 md:w-6 w-4" />
                                </button>
                                <button
                                  className="text-[#56C392] hover:text-red-500"
                                  onClick={() =>
                                    handleDelete(account.id, account.name)
                                  }
                                >
                                  <TrashIcon className="lg:w-6 md:w-6 w-4" />
                                </button>
                              </DetailTableDataRight>
                            </tr>
                          ))}

                      <tr className="border-t">
                        <DetailTableDataLeft></DetailTableDataLeft>
                        <DetailTableDataCenter></DetailTableDataCenter>
                        <DetailTableDataRight>
                          <button
                            className="text-[#56C392]"
                            onClick={() => setShowCreateAccount(true)}
                          >
                            <PlusCircleIcon className="lg:w-6 md:w-6 w-4" />
                          </button>
                        </DetailTableDataRight>
                      </tr>
                    </tbody>
                  </Table>
                </TableContainer>
              </div>
            ))}
        {showCreateAccount && (
          <>
            <Modal show={true}>
              <ChartOfAccountCreate
                onClose={() => setShowCreateAccount(false)}
              />
            </Modal>
          </>
        )}
        {showEditChartOfAccount && (
          <>
            <Modal show={true}>
              {selectedChartOfAccountId && (
                <ChartOfAccountEdit
                  onClose={() => setShowEditChartOfAccount(false)}
                  onDelete={() => {
                    setShowEditChartOfAccount(false);
                    handleDelete(selectedChartOfAccountId, chartOfAccountName);
                  }}
                  chartOfAccountId={selectedChartOfAccountId}
                />
              )}
            </Modal>
          </>
        )}
        {showDelete && (
          <>
            <Modal show={true}>
              <CloseButton onClick={() => setShowDelete(false)} />
              <Box size="medium">
                <>
                  <div className="px-4 pb-2">
                    <span>
                      Are you sure to delete Account: {chartOfAccountName}
                    </span>
                  </div>
                  <FormButtonsContainer>
                    <div className="space-x-2">
                      <CancelButton onClick={() => setShowDelete(false)} />
                      <OkButton
                        onClick={() => deleteRecord(selectedChartOfAccountId)}
                      >
                        Delete
                      </OkButton>
                    </div>
                  </FormButtonsContainer>
                </>
              </Box>
            </Modal>
          </>
        )}
      </>
    );
  }
};
