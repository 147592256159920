import html2pdf from 'html2pdf.js';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { InvoicePDFExport } from './InvoicePDFExport';

import {
  DetailTableHeaderLeft,
  DetailTableHeaderCenter,
  DetailTableHeaderRight,
  DetailTableDataLeft,
  DetailTableDataCenter,
  DetailTableDataRight,
  Details,
} from '@components/Details';
import { DownloadIcon, TuosLogo } from '@components/Icons';
import { Space } from '@components/Space';
import { Table, TableContainer } from '@components/TableContainers';
import TextWrapper from '@components/TextWrapper';
import { routes } from '@config/routes';
import {
  BillingAddressResultViewModel,
  billingAddressDefaultValue,
} from '@models/BillingAddress';
import {
  CustomerResultViewModel,
  customerDefaultValue,
} from '@models/Customer';
import { InvoiceResultViewModel, invoiceDefaultValue } from '@models/Invoice';
import { InvoiceDetailViewModel } from '@models/InvoiceDetail';
import { InvoiceDetailSalesTaxViewModel } from '@models/InvoiceDetailSalesTax';
import { BillingAddressService } from '@services/BillingAddress';
import { CustomerService } from '@services/Customer';
import { InvoiceService } from '@services/Invoice';
import { formatAsCurrency } from '@utils/numberFormat';
import { toTitleCase } from '@utils/titleCase';

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

export const InvoiceDetailsPDF = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState(invoiceDefaultValue);

  const location = useLocation();
  const previousLocation = location.state?.from || routes.RNC_INVOICES;

  const [customer, setCustomer] = useState(customerDefaultValue);

  const [customerAddress, setCustomerAddress] = useState(
    billingAddressDefaultValue
  );

  const [invoiceDetails, setInvoiceDetails] = useState<
    InvoiceDetailViewModel[]
  >([]);

  const loadInvoiceDetails = () => {
    InvoiceService.getInvoiceDetails(Number(id)).then((result) => {
      setInvoiceDetails(result.data);
    });
  };

  const loadData = () => {
    InvoiceService.getById(Number(id))
      .then((response) => response.data as Promise<InvoiceResultViewModel>)
      .then((result) => {
        if (result.isSuccess) {
          setData(result.data);

          if (result.data.billingAddressID) {
            BillingAddressService.getById(result.data.billingAddressID)
              .then(
                (addressResponse) =>
                  addressResponse.data as Promise<BillingAddressResultViewModel>
              )
              .then((addressResult) => setCustomerAddress(addressResult.data));
          }

          if (result.data.customerID) {
            CustomerService.getById(result.data.customerID)
              .then(
                (customerResponse) =>
                  customerResponse.data as Promise<CustomerResultViewModel>
              )
              .then((customerResult) => {
                setCustomer(customerResult.data);
              });
          }

          console.log('Success: Fetched the record');
        } else {
          console.log(
            `Error: Failed to get the record. ${result.errorMessage}`
          );
        }
      });
  };

  console.log('data:', data);

  useEffect(() => {
    if (id) {
      loadData();
      loadInvoiceDetails();
    }
  }, [id]);

  const getSalesTaxesBySalesTaxID = () => {
    const salesTaxes = invoiceDetails.map((o) =>
      o.invoiceDetailSalesTaxes
        .filter((o) => o.salesTaxID != 0)
        .map((salesTaxes) => ({
          ...salesTaxes,
          salesTaxKey: `${salesTaxes.salesTaxAbbreviation} (${salesTaxes.rate}%)`,
        }))
    );

    const groupedSalesTaxes = groupBy(
      salesTaxes.flat(),
      'salesTaxKey'
    ) as InvoiceDetailSalesTaxViewModel[][];

    return groupedSalesTaxes;
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById('pdf-content');
    if (element) {
      html2pdf()
        .from(element)
        .set({
          margin: 0.3,
          filename: `Invoice No: ${data.invoiceNumber}.pdf`,
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        })
        .save()
        .then(() => {
          console.log('PDF successfully downloaded.');
        })
        .catch((error: any) => {
          console.error('Error during PDF generation:', error);
        });
    } else {
      console.error('No content found to download as PDF.');
    }
  };

  return (
    <>
      <Details>
        <div className="flex justify-end">
          <button
            className="flex gap-x-2 items-center lg:text-base text-sm bg-primary-500 rounded-md text-white hover:bg-primary-700 px-3 py-1 lg:px-4 lg:py-2"
            onClick={() => handleDownloadPDF()}
          >
            Download PDF
            <DownloadIcon height={24} width={24} />
          </button>
        </div>

        <div className="shadow-md border">
          <div className="lg:p-8 md:p-6 p-4 flex justify-between">
            {/* Desktop */}
            <div className="lg:flex md:hidden hidden items-center">
              <TuosLogo height="100" width="75" />
            </div>
            {/* Tablet */}
            <div className="md:flex lg:hidden hidden items-center">
              <TuosLogo height="75" width="56.25" />
            </div>
            {/* Mobile */}
            <div className="flex lg:hidden md:hidden items-center">
              <TuosLogo height="56.25" width="42.1875" />
            </div>
            <div className="lg:text-xl md:text-base text-sm font-semibold text-end text-gray-500">
              INVOICE
              <div className="text-black lg:text-lg md:text-base text-sm">
                Tuos Development <br />
                <span className="lg:text-sm md:text-sm text-xs">Canada</span>
              </div>
            </div>
          </div>

          <div className="border-t-2 border-gray-700 lg:py-4 lg:px-8 md:py-4 md:px-6 py-2 px-4">
            <div className="lg:flex md:flex block grid-cols-2 justify-between">
              <div className="lg:col-span-1 md:col-span-1 col-span-2">
                <div className="lg:text-base md:text-sm text-sm font-semibold text-gray-500">
                  Bill to
                </div>
                <div className="lg:text-base md:text-sm text-sm font-semibold uppercase">
                  {customer.companyName}
                </div>
                <div className="lg:text-base md:text-sm text-xs">
                  <span className="uppercase">
                    {customer.primaryContact?.firstname}{' '}
                    {customer.primaryContact?.lastname}
                  </span>
                  <br />
                  {toTitleCase(
                    `${customerAddress.address1} ${customerAddress.address2}`
                  )}
                  <br />
                  {toTitleCase(
                    `${customerAddress.city}, ${customerAddress.state} ${customerAddress.postalCode}`
                  )}
                  <br />
                  {toTitleCase(`${customerAddress.country}`)}
                  <br />
                  <br />
                  {customer.primaryContact?.phone}
                  <br />
                  <span className="lowercase">
                    {customer.primaryContact?.email}
                  </span>
                </div>
              </div>

              <div className="lg:col-span-1 md:col-span-1 lg:text-base md:text-sm text-xs col-span-2 space-y-1">
                <div className="font-semibold w-full flex items-center justify-between space-x-4 lg:pl-4 md:pl-4 pl-0 pr-1">
                  <div>Invoice Number:</div>
                  <div className="font-normal text-end">
                    {data.invoiceNumber}
                  </div>
                </div>
                <div className="font-semibold w-full flex items-center justify-between space-x-4 lg:pl-4 md:pl-4 pl-0 pr-1">
                  <div>Invoice Date:</div>
                  <div className="font-normal text-end">
                    {formatDate(data.date)}
                  </div>
                </div>
                <div className="font-semibold w-full flex items-center justify-between space-x-4 lg:pl-4 md:pl-4 pl-0 pr-1">
                  <div>Valid until:</div>
                  <div className="font-normal text-end">
                    {formatDate(data.dueDate)}
                  </div>
                </div>
                <div className="font-semibold w-full flex items-center justify-between space-x-4 lg:pl-4 md:pl-4 pl-2 pr-1 bg-gray-300 py-2">
                  <div>Grand Total(CAD):</div>
                  <div className="font-normal text-end">
                    {formatAsCurrency(
                      invoiceDetails.reduce(
                        (acc, cur) => acc + cur.subTotal,
                        0
                      ) +
                        invoiceDetails
                          .map((o) =>
                            o.invoiceDetailSalesTaxes.reduce(
                              (acc, cur) => acc + cur.salesTaxAmount,
                              0
                            )
                          )
                          .reduce((acc, cur) => acc + cur, 0)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>

          <div className="">
            <div className="max-w-5x1 mx-auto">
              <TableContainer>
                <Table>
                  <thead className="bg-primary-100">
                    <tr>
                      <DetailTableHeaderLeft>
                        <span className="pl-2">Item</span>
                      </DetailTableHeaderLeft>

                      <DetailTableHeaderCenter></DetailTableHeaderCenter>
                      <DetailTableHeaderCenter>
                        Quantity
                      </DetailTableHeaderCenter>
                      <DetailTableHeaderCenter>Price</DetailTableHeaderCenter>
                      <DetailTableHeaderRight>
                        <span className="pr-2">Amount</span>
                      </DetailTableHeaderRight>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceDetails.map((row, index) => (
                      <tr
                        key={index}
                        className={`${
                          index % 2 === 0 ? ' bg-white' : 'bg-green-50'
                        }`}
                      >
                        <DetailTableDataLeft>
                          <div className="pl-2 ">
                            <div className="font-semibold text-start uppercase">
                              <TextWrapper text={row.product?.name || ''} />
                            </div>
                          </div>
                          <div className="font-light pl-2">
                            <TextWrapper text={row.description || ''} />
                          </div>
                        </DetailTableDataLeft>
                        <DetailTableDataCenter></DetailTableDataCenter>
                        <DetailTableDataCenter>
                          <span className="font-medium">{row.quantity}</span>
                        </DetailTableDataCenter>
                        <DetailTableDataCenter>
                          <span className="font-medium">
                            {formatAsCurrency(row.price)}
                          </span>
                        </DetailTableDataCenter>
                        <DetailTableDataRight>
                          <span className="font-medium">
                            {formatAsCurrency(row.subTotal)}
                          </span>
                        </DetailTableDataRight>
                      </tr>
                    ))}

                    <Space />

                    <tr>
                      <td colSpan={6}>
                        <div className="space-y-1">
                          <div className="flex justify-end mr-6 lg:text-sm md:text-sm text-xs">
                            <div className="font-bold mr-4">SubTotal:</div>

                            <div className="w-24 text-right">
                              {formatAsCurrency(
                                invoiceDetails.reduce(
                                  (acc, cur) => acc + cur.subTotal,
                                  0
                                )
                              )}
                            </div>
                          </div>

                          {Object.entries(getSalesTaxesBySalesTaxID()).map(
                            ([taxTitle, details]) => (
                              <div
                                className="flex justify-end mr-6 lg:text-sm md:text-sm text-xs"
                                key={taxTitle}
                              >
                                <div className="font-medium mr-4">
                                  {taxTitle}
                                </div>

                                <div className="w-24 text-right">
                                  {formatAsCurrency(
                                    details.reduce(
                                      (acc, cur) => acc + cur.salesTaxAmount,
                                      0
                                    )
                                  )}
                                </div>
                              </div>
                            )
                          )}

                          <div className="flex justify-end mr-6 pt-2 pl-96 lg:text-sm md:text-sm text-xs">
                            <div className="w-full ml-96 flex justify-end border-t border-gray-600 py-2 border-b-4">
                              <div className="font-bold mr-4">Total Tax:</div>
                              <div className="w-24 text-right">
                                {formatAsCurrency(
                                  invoiceDetails
                                    .map((o) =>
                                      o.invoiceDetailSalesTaxes.reduce(
                                        (acc, cur) => acc + cur.salesTaxAmount,
                                        0
                                      )
                                    )
                                    .reduce((acc, cur) => acc + cur, 0)
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end mr-6 lg:text-sm md:text-sm text-xs py-4">
                            <div className="font-bold mr-4">Grand Total:</div>
                            <div className="w-24 text-right">
                              {formatAsCurrency(
                                invoiceDetails.reduce(
                                  (acc, cur) => acc + cur.subTotal,
                                  0
                                ) +
                                  invoiceDetails
                                    .map((o) =>
                                      o.invoiceDetailSalesTaxes.reduce(
                                        (acc, cur) => acc + cur.salesTaxAmount,
                                        0
                                      )
                                    )
                                    .reduce((acc, cur) => acc + cur, 0)
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>

        <div className="text-xs flex justify-end">
          <button
            onClick={() => navigate(previousLocation)}
            className="italic py-2 text-blue-500 hover:text-blue-800"
          >
            go back to Invoices
          </button>
        </div>
      </Details>

      {/* pdf Export */}
      <InvoicePDFExport invoicesId={data.id} />
    </>
  );
};
