import { FolderPlusIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PrimaryButton } from '@components/Buttons/PrimaryButtons';
import { CallToActionContainer } from '@components/Container';
import { Details, DetailsButton } from '@components/Details';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { TuosLogo } from '@components/Icons';
import { Loading } from '@components/Loading';
import { routes } from '@config/routes';
import {
  billingAddressDefaultValue,
  BillingAddressResultViewModel,
} from '@models/BillingAddress';
import {
  CustomerResultViewModel,
  customerDefaultValue,
} from '@models/Customer';
import { BillingAddressService } from '@services/BillingAddress';
import { CustomerService } from '@services/Customer';
import { toTitleCase } from '@utils/titleCase';

export const CustomerDetails: React.FC = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(customerDefaultValue);
  const navigate = useNavigate();

  const [customerAddress, setCustomerAddress] = useState(
    billingAddressDefaultValue
  );

  const location = useLocation();

  const loadData = () => {
    CustomerService.getById(Number(id))
      .then((response) => response.data as Promise<CustomerResultViewModel>)
      .then((result) => {
        if (result) {
          setData(result.data);
          setIsLoading(false);
          console.log('fetchedDate', result);

          if (result.data.billingID) {
            BillingAddressService.getById(result.data.billingID)
              .then(
                (addressResponse) =>
                  addressResponse.data as Promise<BillingAddressResultViewModel>
              )
              .then((addressResult) => {
                setCustomerAddress(addressResult.data);
              });
          }

          console.log('Success: Fetch the record.');
        } else {
          console.log('Error: Failed to get record.');
        }
      })
      .catch((error) => {
        alert('Error: Failed to handle thr request');
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, []);

  const handleEdit = (rowId: number) => {
    navigate(`${routes.RNC_CUSTOMERS}/${rowId}/edit`, {
      state: { from: location },
    });
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <GridHeaderMenuTitle>
          <div className="text-xl font-bold uppercase">{data.companyName}</div>

          <div className="flex flex-row space-x-1">
            <PrimaryButton
              onClick={() => {
                navigate(`${routes.RNC_CUSTOMERS}/create`, {
                  state: { from: location },
                });
              }}
            >
              Create new customer
            </PrimaryButton>
          </div>
        </GridHeaderMenuTitle>

        <Details>
          <div>
            <CallToActionContainer>
              <div className="p-6 flex justify-between">
                <div className="space-y-2">
                  <div className="flex text-primary-500 text-lg items-center space-x-4">
                    <FolderPlusIcon width={30} height={30} />
                    <div className="text-gray-400 font-normal text-2xl">
                      Create
                    </div>
                  </div>
                </div>

                <div>
                  <DetailsButton onClick={() => handleEdit(data.id)}>
                    Edit Customer
                  </DetailsButton>
                </div>
              </div>
            </CallToActionContainer>
          </div>

          <div className="shadow-md border">
            <div className="p-8 flex justify-between">
              <div className="flex items-center">
                <TuosLogo height="100" width="75" />
              </div>
              <div className="text-xl font-semibold text-end text-gray-500">
                CUSTOMER
                <div className="text-black text-lg">
                  Tuos Development <br />
                  <span className="text-sm">Canada</span>
                </div>
              </div>
            </div>

            <div className="border-t-2 border-gray-700 py-4 px-8">
              <div className="space-y-4 divide-y-2">
                <div className="space-y-2">
                  <div className="text-base font-semibold text-gray-500">
                    Primary Contact
                  </div>
                  <div className="font-semibold">
                    <span className="uppercase">
                      {data.primaryContact?.firstname}{' '}
                      {data.primaryContact?.lastname}
                    </span>
                    <div className="font-normal text-sm">
                      <span className="lowercase">
                        {data.primaryContact?.email}
                      </span>
                      <br />
                      {data.primaryContact?.phone}
                    </div>
                  </div>
                </div>
                <div className="space-y-2 pt-4">
                  <div className="text-base font-semibold text-gray-500">
                    Billing Address
                  </div>

                  <div className="font-normal text-sm">
                    {toTitleCase(`${customerAddress.address1}`)}
                    <br />
                    {toTitleCase(
                      `${customerAddress.address2}${','} ${
                        customerAddress.city
                      }`
                    )}
                    <br />
                    {toTitleCase(
                      `${customerAddress.state}${','} ${
                        customerAddress.country
                      }${','} ${customerAddress.postalCode}`
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Details>
      </>
    );
  }
};
