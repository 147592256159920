import { loadConfig } from '@utils/configLoader';

const config = await loadConfig();
const BASE_URL = config.APIBaseUrl;
const AUTH_BASE_URL = config.oidc.authority;

export const api = {
  BASE_URL:
    process.env.name === 'APPLICATION_LOCAL'
      ? 'https://localhost:7441'
      : BASE_URL,
  AUTH_BASE_URL: AUTH_BASE_URL,
  BILLING_ADDRESSES_ENDPOINT: '/api/billing-addresses',
  SHIPPING_ADDRESSES_ENDPOINT: '/api/shipping-addresses',
  CATEGORIES_ENDPOINT: '/api/categories',
  CHART_OF_ACCOUNTS_ENDPOINT: '/api/chart-of-accounts',
  CONTACTS_ENDPOINT: '/api/contacts',
  CUSTOMERS_ENDPOINT: '/api/customers',
  SALESTAXES_ENDPOINT: '/api/sales-taxes',
  PAYMENTS_ENDPOINT: '/api/payments',
  PRODUCTS_ENDPOINT: '/api/products',
  CURRENCIES_ENDPOINT: '/api/currencies',
  CHARTS_ENDPOINT: '/api/chart-of-accounts',
  ESTIMATES_ENDPOINT: '/api/estimates',
  INVOICES_ENDPOINT: '/api/invoices',
  RECURRING_INVOICES_ENDPOINT: '/api/recurring-invoices',
};
