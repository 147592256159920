import { useField, FieldHookConfig } from 'formik';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  label?: string;
  cascadedValue?: string;
  changeHandler?: (value: string) => void;
  readonly?: any;
} & FieldHookConfig<string>;

export const FormikDateInput: React.FC<Props> = ({
  label,
  cascadedValue,
  changeHandler,
  readonly,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const [dateInputValue, setDateInputValue] = useState(
    field.value ? new Date(field.value) : null
  );

  useEffect(() => {
    if (cascadedValue) {
      const cascadedDate = new Date(cascadedValue);
      setDateInputValue(cascadedValue ? new Date(cascadedValue) : null);
      helpers.setValue(
        cascadedDate
          ? new Date(
              cascadedDate.getTime() - cascadedDate.getTimezoneOffset() * 60000
            ).toISOString()
          : ''
      );
    }
  }, [cascadedValue]);

  return (
    <div className="w-full">
      <label className="block lg:text-sm md:text-sm text-xs font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 formik-date-input">
        <DatePicker
          selected={dateInputValue}
          readOnly={readonly}
          onChange={(date) => {
            setDateInputValue(date ? new Date(date) : null);
            helpers.setValue(
              date
                ? new Date(
                    date.getTime() - date.getTimezoneOffset() * 60000
                  ).toISOString()
                : ''
            );
            changeHandler &&
              changeHandler(
                date
                  ? new Date(
                      date.getTime() - date.getTimezoneOffset() * 60000
                    ).toISOString()
                  : ''
              );
          }}
          className={
            !!meta.error && dateInputValue
              ? 'block w-full border-red-300 shadow-sm focus:border-red-500 focus:ring-red-500 lg:text-sm md:text-xs text-xs bg-red-100'
              : !meta.error && dateInputValue
              ? 'block w-full border-primary-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 lg:text-sm md:text-xs text-xs bg-green-50'
              : 'block w-full border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 lg:text-sm md:text-xs text-xs '
          }
        />

        {meta.error ? (
          <div className="text-red-500 lg:text-sm md:text-sm text-xs">
            {meta.error}
          </div>
        ) : null}
      </div>
    </div>
  );
};
