import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserCompanyState } from '../models/UserCompanyState';
import { UserState } from '../models/UserState';

const initialState: UserState = {
  username: '',
  family_name: '',
  given_name: '',
  email: '',
  currentCompany: {
    name: '',
    id: '',
    tenantId: '',
    tenantName: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.username = action.payload.username;
      state.family_name = action.payload.family_name;
      state.given_name = action.payload.given_name;
      state.email = action.payload.email;
      state.currentCompany = action.payload.currentCompany;
    },
    setCurrentCompany: (state, action: PayloadAction<UserCompanyState>) => {
      state.currentCompany = action.payload;
    },
  },
});

export const { setUser, setCurrentCompany } = userSlice.actions;
export const userReducer = userSlice.reducer;
