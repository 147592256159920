interface Props {
  children?: React.ReactNode;
}

export const DetailTableDataLeft: React.FC<Props> = ({ children }) => {
  return (
    <td className="whitespace-nowrap text-left py-3 pl-6 pr-3 lg:text-sm md:text-sm text-xs font-normal align-top text-gray-900">
      {children}
    </td>
  );
};
