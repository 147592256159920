import { PlusCircleIcon } from '@heroicons/react/20/solid';

interface Props {
  label: string;
  onClick: () => void;
}

export const AddRecord: React.FC<Props> = ({ onClick, label }) => {
  return (
    <>
      <button
        className="bg-primary-500 items-center text-white rounded-md lg:px-2 md:px-2 px-4 lg:text-sm md:text-sm text-xs hover:bg-primary-600 py-1.5 flex space-x-2"
        onClick={onClick}
      >
        <PlusCircleIcon width={24} height={24} />
        <div className="font-normal">{label}</div>
      </button>
    </>
  );
};
