interface Props {
  children?: React.ReactNode;
}

export const DetailTableHeaderCenter: React.FC<Props> = ({ children }) => {
  return (
    <th
      scope="col"
      className="px-3 py-3.5 lg:text-sm md:text-sm text-xs font-semibold text-gray-900 text-center"
    >
      {children}
    </th>
  );
};
