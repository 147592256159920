import { PlusIcon } from '@heroicons/react/20/solid';
import { useField, FieldHookConfig } from 'formik';
import { useEffect } from 'react';
import tw from 'tailwind-styled-components';

interface BaseSelectProps {
  hasError: boolean;
}

const BaseSelect = tw.select<BaseSelectProps>`
mt-1 block w-full py-2 pl-3 pr-10 lg:text-sm md:text-sm focus:outline-none text-xs
${(props) =>
  !props.hasError &&
  !props.value &&
  'border-primary-300 focus:border-primary-500 focus:ring-primary-500'} 
${(props) =>
  !props.hasError &&
  !!props.value &&
  'border-primary-300 focus:border-primary-500 focus:ring-primary-500 bg-green-50'} 
${(props) =>
  props.hasError &&
  !props.value &&
  'border-primary-300 focus:border-primary-500 focus:ring-primary-500'} 
${(props) =>
  props.hasError &&
  !!props.value &&
  'border-red-300 focus:border-red-500 focus:ring-red-500 bg-red-100'} 
`;

interface SelectOptions {
  selected?: boolean;
  value: string;
  text: string | number;
  id?: number;
}

type Props = {
  label?: string;
  selection: SelectOptions[];
  onAddAction?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
} & FieldHookConfig<string>;

export const ActionableFormikSelect: React.FC<Props> = ({
  label,
  selection,
  onChange,
  onAddAction,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    if (props.value) {
      helpers.setValue(props.value);
    }
  }, [props.value]);

  return (
    <div>
      <label className="block lg:text-sm md:text-sm text-xs font-medium text-primary-700">
        {label}
      </label>
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <BaseSelect
            {...field}
            hasError={!!meta.error}
            onChange={(e) => {
              helpers.setValue(e.target.value);
              if (onChange) {
                onChange(e);
              }
            }}
          >
            {selection.map((row, index) => (
              <option key={index} value={row.value}>
                {row.text}
              </option>
            ))}
          </BaseSelect>
        </div>
        <button
          type="button"
          className="mt-1 relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-primary-300 hover:bg-gray-50 hover:ring-primary-500"
          onClick={onAddAction}
        >
          <PlusIcon className="h-5 w-5" />
        </button>
      </div>
      {meta.error ? <div className="text-red-500">{meta.error}</div> : null}
    </div>
  );
};
