import { Menu } from '@headlessui/react';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid';
import tw from 'tailwind-styled-components';

interface BaseSelectProps {
  hasError: boolean;
}

const BaseSelect = tw.select<BaseSelectProps>`
mt-1 block w-full py-2 pl-3 pr-10 lg:text-sm md:text-xs text-xs focus:outline-none
${(props) =>
  !props.hasError &&
  !props.value &&
  'border-green-300 focus:border-primary-500 focus:ring-primary-500'} 
${(props) =>
  !props.hasError &&
  !!props.value &&
  'border-green-300 focus:border-primary-500 focus:ring-primary-500 bg-green-50'} 
${(props) =>
  props.hasError &&
  !props.value &&
  'border-green-300 focus:border-primary-500 focus:ring-primary-500'} 
${(props) =>
  props.hasError &&
  !!props.value &&
  'border-red-300 focus:border-red-500 focus:ring-red-500 bg-red-100'} 
`;

interface SelectOptions {
  selected?: boolean;
  value: string;
  text: string | number;
  id?: number;
}

type Props = {
  label?: string;
  selection: SelectOptions[];
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onAddAction?: () => void;
  onEditAction?: () => void;
  onDuplicateAction?: () => void;
  onDeleteAction?: () => void;
};

export const ActionableSelect: React.FC<Props> = ({
  label,
  selection,
  value,
  onChange,
  onAddAction,
  onEditAction,
  onDuplicateAction,
  onDeleteAction,
}) => {
  return (
    <div>
      <label className="block lg:text-sm md:text-sm text-xs font-medium text-primary-700">
        {label}
      </label>
      <div className="mt-2 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <BaseSelect
            value={value}
            hasError={false}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
            }}
          >
            {selection.map((row, index) => (
              <option key={index} value={row.value}>
                {row.text}
              </option>
            ))}
          </BaseSelect>
        </div>
        <button
          type="button"
          className="mt-1 relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-primary-300 hover:bg-gray-50 hover:ring-primary-500"
          onClick={onAddAction}
        >
          <PlusIcon className="sm:h-5 sm:w-5 h-4 w-4" />
        </button>
        <div
          // type="button"
          className="mt-1 relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-primary-300 hover:bg-gray-50"
        >
          <Menu as="div" className="ml-auto">
            <Menu.Button className="block text-primary-500 hover:text-primary-200">
              <span className="sr-only">Open options</span>
              <ChevronDownIcon className="h-5 w-5" />
            </Menu.Button>
            <Menu.Items
              className={`absolute right-12 -top-1 z-50 mt-0.5 w-32 origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none`}
            >
              <Menu.Item>
                <div
                  onClick={onEditAction}
                  className="hover:bg-primary-500 rounded-md cursor-pointer"
                >
                  <span className="w-full text-start px-3">Edit</span>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div
                  onClick={onDuplicateAction}
                  className="hover:bg-primary-500 cursor-pointer"
                >
                  <span className="w-full text-start px-3">Duplicate</span>
                </div>
              </Menu.Item>
              <hr className="mt-2" />
              <Menu.Item>
                <div
                  onClick={onDeleteAction}
                  className="hover:bg-primary-500 cursor-pointer"
                >
                  <span className="w-full text-start px-3">Delete</span>
                </div>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
    </div>
  );
};
