import { Link } from 'react-router-dom';

import {
  AccountingIcon,
  DashboardIcon,
  InventoryIcon,
  PurchaseIcon,
  ReportsIcon,
  RevenueIcon,
  SettingsIcon,
} from '@components/Icons';
import { SidebarMenu } from '@components/SidebarMenu';
import { routes } from '@config/routes';

interface Props {
  onClose: () => void;
}

export const SideBarResponsive: React.FC<Props> = ({ onClose }) => {
  return (
    <>
      <div className="grid gap-y-10">
        <Link to={`${routes.HOME}`}>
          <DashboardIcon height="25" width="25" />
        </Link>

        <SidebarMenu
          subMenuActions={[
            {
              title: 'Estimate and Quotes',
              to: `${routes.RNC_ESTIMATES}`,
              onClick: () => onClose(),
            },
            {
              title: 'Recurring Invoices',
              to: `${routes.RNC_RECURRING_INVOICES}`,
              onClick: () => onClose(),
            },
            {
              title: 'Invoices',
              to: `${routes.RNC_INVOICES}`,
              onClick: () => onClose(),
            },
            {
              title: 'Customers',
              to: `${routes.RNC_CUSTOMERS}`,
              onClick: () => onClose(),
            },
          ]}
        >
          <RevenueIcon width="25" height="25" />
        </SidebarMenu>

        <SidebarMenu
          subMenuActions={[
            {
              title: 'Category',
              to: `${routes.CATEGORIES}`,
              onClick: () => onClose(),
            },
            {
              title: 'Products and Services',
              to: `${routes.INVENTORY_PRODUCTS}`,
              onClick: () => onClose(),
            },
          ]}
        >
          <InventoryIcon width="25" height="25" />
        </SidebarMenu>

        <SidebarMenu subMenuActions={[]}>
          <PurchaseIcon width="25" height="25" />
        </SidebarMenu>

        <SidebarMenu
          subMenuActions={[
            {
              title: 'Charts of Accounts',
              to: `${routes.CHARTOFACCOUNTS}`,
              onClick: () => onClose(),
            },
          ]}
        >
          <AccountingIcon width="25" height="25" />
        </SidebarMenu>

        <SidebarMenu>
          <ReportsIcon width="25" height="25" />
        </SidebarMenu>

        <SidebarMenu
          subMenuActions={[
            {
              title: 'Sales Tax',
              to: `${routes.SALES_TAX}`,
              onClick: () => onClose(),
            },
          ]}
        >
          <SettingsIcon width="25" height="25" />
        </SidebarMenu>
      </div>
    </>
  );
};
