import { DocumentArrowDownIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { BreadCrumbs } from '../../Layout/Header/BreadCrumbs';

import { CustomerImportCSV } from './CustomerImportCSV';

import { AddRecord } from '@components/ActionButtons';
import { BoxHeaderTitle } from '@components/Box';
import { BoxBodyContainer } from '@components/Box/BoxBodyContainer';
import { BreadcrumbsContainer } from '@components/BreadcrumbsContainer';
import { PrimaryButton } from '@components/Buttons/PrimaryButtons';
import { GridHeaderMenuTitle } from '@components/HeaderTitle';
import { Modal } from '@components/Modal';
import { routes } from '@config/routes';

export const Customer: React.FC = () => {
  const [importCSVModal, setImportCSVModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const baseBreadCrumbs = [{ link: 'Revenue and Collections', to: '' }];

  const isOnCustomersPage = location.pathname === routes.RNC_CUSTOMERS;

  const breadCrumbLinks = isOnCustomersPage
    ? baseBreadCrumbs
    : [...baseBreadCrumbs, { link: 'Customers', to: routes.RNC_CUSTOMERS }];

  return (
    <>
      <div className="p-4 space-y-4">
        <BreadcrumbsContainer>
          <div className="text-sm font-semibold text-gray-500">
            Revenue and Collections
          </div>
          <div>
            <BreadCrumbs links={breadCrumbLinks} />
          </div>
        </BreadcrumbsContainer>

        <div>
          <div className="lg:hidden sm:hidden block pb-2">
            {isOnCustomersPage && (
              <div className="flex flex-row justify-end">
                <AddRecord
                  label="New Customer"
                  onClick={() => {
                    navigate(`${routes.RNC_CUSTOMERS}/create`);
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex justify-between items-center space-x-5">
            <BoxHeaderTitle>
              <GridHeaderMenuTitle>
                <div className="lg:text-2xl md:text-xl text-md lg:font-black md:font-black font-bold lg:flex grid md:grid ">
                  Customers
                </div>
              </GridHeaderMenuTitle>
            </BoxHeaderTitle>

            <div className="grid float-right">
              {isOnCustomersPage && (
                <div className="flex flex-row space-x-1 sm:text-xs text-xs lg:text-sm">
                  <div className="lg:block sm:block hidden">
                    <AddRecord
                      label="New Customer"
                      onClick={() => {
                        navigate(`${routes.RNC_CUSTOMERS}/create`);
                      }}
                    />
                  </div>

                  <div className="lg:pb-0 md:pb-0 pb-2">
                    <PrimaryButton onClick={() => setImportCSVModal(true)}>
                      <div className="flex space-x-2 items-center">
                        <DocumentArrowDownIcon width={24} height={24} />
                        <span>Import from Excel</span>
                      </div>
                    </PrimaryButton>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="p-4 bg-white rounded-b-xl rounded-tr-xl">
            <BoxBodyContainer>
              <Outlet />
            </BoxBodyContainer>
          </div>
        </div>
      </div>

      {importCSVModal && (
        <>
          <Modal show={true}>
            <CustomerImportCSV onClose={() => setImportCSVModal(false)} />
          </Modal>
        </>
      )}
    </>
  );
};
