/* eslint-disable import/no-named-as-default-member */
import { useState } from 'react';
import * as XLSX from 'xlsx';

import { CloseButton } from '@components/ActionButtons';
import { Box } from '@components/Box';
import {
  FormButtonsContainer,
  FormSectionContainer,
} from '@components/Container';
import { CancelButton, SubmitButton } from '@components/Form';
import { CsvFileInput } from '@components/Form/CsvFileInput';
import { Space } from '@components/Space';
import { CustomerViewModel } from '@models/Customer';
import { CustomerService } from '@services/Customer';

interface Props {
  onClose?: () => void;
}

export const CustomerImportCSV: React.FC<Props> = ({ onClose }) => {
  const [csvData, setCsvData] = useState<CustomerViewModel[]>([]);

  const handleCsvUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        if (data) {
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const parsedData: CustomerViewModel[] = XLSX.utils
            .sheet_to_json(worksheet)
            .map((row: any) => {
              return {
                companyName: row['Company Name'],
                accountNumber: `${row['Account Number']}`,
                website: row['Website'],
                notes: row['Notes'],
                primaryContact: {
                  firstname: `${row['Primary Contact First Name']}`,
                  lastname: `${row['Primary Contact Last Name']}`,
                  email: `${row['Primary Contact Email']}`,
                  phone: `${row['Primary Contact Phone']}`,
                  fax: `${row['Primary Contact Fax']}`,
                },
                defaultBillingAddress: {
                  currencyCode: `${row['Billing Currency Code']}`,
                  address1: `${row['Billing Address 1']}`,
                  address2: `${row['Billing Address 2']}`,
                  city: `${row['Billing Address City']}`,
                  state: `${row['Billing Address State']}`,
                  country: `${row['Billing Address Country']}`,
                  postalCode: `${row['Billing Address Postal Code']}`,
                },
                defaultShippingAddress: {
                  currencyCode: `${row['Shipping Currency Code']}`,
                  address1: `${row['Shipping Address 1']}`,
                  address2: `${row['Shipping Address 2']}`,
                  city: `${row['Shipping Address City']}`,
                  state: `${row['Shipping Address State']}`,
                  country: `${row['Shipping Address Country']}`,
                  postalCode: `${row['Shipping Address Postal Code']}`,
                },
              };
            });
          setCsvData(parsedData);
          console.log('ParsedData:', parsedData);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleCsvDataSubmit = async () => {
    try {
      const batchSize = 10;
      const totalCustomers = csvData.length;
      let startIndex = 0;

      while (startIndex < totalCustomers) {
        const endIndex = Math.min(startIndex + batchSize, totalCustomers);
        const batch = csvData.slice(startIndex, endIndex);

        const createPromises = batch.map(async (customer) => {
          return CustomerService.create(customer);
        });

        await Promise.all(createPromises);

        startIndex += batchSize;
      }
      console.log('All submissions successful');
      window.location.reload();
      setCsvData([]);
    } catch (error) {
      console.log('Submission failed: error');
      alert(error);
    }
  };

  return (
    <>
      <CloseButton onClick={onClose} />
      <Box size="medium">
        <FormSectionContainer>
          <CsvFileInput
            onChange={handleCsvUpload}
            label="Import from Excel / CSV"
            name="csvFile"
          />

          <Space />
        </FormSectionContainer>
        <FormButtonsContainer>
          <CancelButton onClick={onClose} />

          <SubmitButton
            label="Finish"
            onClick={handleCsvDataSubmit}
            disabled={csvData.length === 0}
          />
        </FormButtonsContainer>
      </Box>
    </>
  );
};
