interface Props {
  children?: React.ReactNode;
}

export const BreadcrumbsContainer: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className="lg:flex md:flex grid lg:justify-between md:justify-between items-center w-full">
        {children}
      </div>
    </>
  );
};
