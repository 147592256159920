import { registerLicense } from '@syncfusion/ej2-base';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { AppRouter } from './AppRouter';
import './index.css';

import { oidcConfig } from '@config/authConfig';
import { loadConfig } from '@utils/configLoader';
import store from 'src/store';

const config = await loadConfig();

const updatedOidcConfig = { ...oidcConfig, ...config.oidc };

// Registering Syncfusion license key
registerLicense(config.syncFussionLicense);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <AuthProvider {...updatedOidcConfig}>
      <RouterProvider router={AppRouter} />
    </AuthProvider>
  </Provider>
);
