import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import Drawer from '@components/Drawer';
import {
  CustomSelect,
  CustomSelectOption,
} from '@components/Form/CustomSelect';
import { TuosBackground } from '@components/TuosBackground';
import { MyProfileService } from '@services/MyProfile';
import { useAppSelector } from 'src/store/hooks';

interface MyProfileDrawerProps {
  setShowUserProfile: (value: boolean) => void;
}

export const MyProfileDrawer: React.FC<MyProfileDrawerProps> = ({
  setShowUserProfile,
}) => {
  //const [userCompanies, setUserCompanies] = useState<MyProfileCompany[]>([]);
  const [userCompanySelectOptions, setUserCompanySelectOptions] = useState<
    CustomSelectOption[]
  >([]);
  const auth = useAuth();
  const user = useAppSelector((state) => state.user);
  useEffect(() => {
    MyProfileService.getMyCompanies().then((data) => {
      //setUserCompanies(data.data);
      setUserCompanySelectOptions(
        data.data.map((company) => ({
          value: company.companyId,
          text: `${company.companyName} (${company.tenantName})`,
        }))
      );
    });
  }, []);

  return (
    <Drawer
      isOpen={true}
      title="My Profile"
      onClose={() => setShowUserProfile(false)}
      background={TuosBackground}
    >
      <div>
        <div className="pb-1 sm:pb-6">
          <div>
            <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
              <div className="sm:flex-1">
                <div>
                  <div className="flex items-center">
                    <h3 className="text-xl font-bold text-black sm:text-2xl">
                      {user.given_name} {user.family_name}
                    </h3>
                  </div>
                  <p className="text-sm text-black">@{user.username}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 pb-5 pt-5 sm:px-0 sm:pt-0">
          <dl className="space-y-8 px-4 sm:space-y-6 sm:px-6">
            <div>
              <dt className="text-sm font-semibold text-black sm:w-40 sm:shrink-0">
                Email
              </dt>
              <dd className="mt-1 text-sm text-black sm:col-span-2">
                {user.email}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-semibold text-black sm:w-40 sm:shrink-0">
                Tenant
              </dt>
              <dd className="mt-1 text-sm text-black sm:col-span-2">
                {user.currentCompany.tenantName}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-semibold text-black sm:w-40 sm:shrink-0">
                Company
              </dt>
              <dd className="mt-1 text-sm text-black sm:col-span-2">
                {user.currentCompany.name}
              </dd>
            </div>
            <hr className="border-gray-200" />
            <div>
              <dt className="text-md font-semibold text-black sm:text-2xl">
                Switch Company
              </dt>
              <dd className="mt-1 text-sm text-black sm:col-span-2">
                <CustomSelect
                  label="Companies"
                  value={user.currentCompany.id}
                  selection={userCompanySelectOptions}
                  onChange={(e: any) => {
                    MyProfileService.switchCompany(e.target.value).finally(
                      () => {
                        auth.signinSilent().then(() => {
                          console.log('Silent renew completed');
                          window.location.reload();
                        });
                      }
                    );
                  }}
                />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </Drawer>
  );
};
